import Footer from '@common/components/Footer/Footer';
import Table from '@common/components/Table/Table';
import UIField from '@common/components/UIField/UIField';
import { AppRoutes } from '@common/constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDeepCompareEffect } from '@layout/hooks';
import { InputNumber } from 'antd';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
// const dummy = [
//   {
//     content: 'Sự kiện định kỳ',
//     point: [
//       {
//         studentType: 'NORMAL',
//         testType: 'NORMAL',
//         point: 20
//       },
//       { studentType: 'NORMAL', testType: 'VIP', point: 40 },
//       { studentType: 'VIP', testType: 'NORMAL', point: 40 },
//       { studentType: 'VIP', testType: 'VIP', point: 80 }
//     ]
//   },
//   {
//     content: 'Sự kiện hệ thống',
//     point: [
//       {
//         studentType: 'NORMAL',
//         testType: 'NORMAL',
//         point: 20
//       },
//       { studentType: 'NORMAL', testType: 'VIP', point: 40 },
//       { studentType: 'VIP', testType: 'NORMAL', point: 40 },
//       { studentType: 'VIP', testType: 'VIP', point: 80 }
//     ]
//   },
//   {
//     content: 'Bài thực hành',
//     point: [
//       {
//         studentType: 'NORMAL',
//         testType: 'NORMAL',
//         point: 1
//       },
//       { studentType: 'NORMAL', testType: 'VIP', point: 2 },
//       { studentType: 'VIP', testType: 'NORMAL', point: 4 },
//       { studentType: 'VIP', testType: 'VIP', point: 8 }
//     ]
//   },
//   {
//     content: 'Ngân hàng đề thi',
//     point: [
//       {
//         studentType: 'NORMAL',
//         testType: 'NORMAL',
//         point: 20
//       },
//       { studentType: 'NORMAL', testType: 'VIP', point: 40 },
//       { studentType: 'VIP', testType: 'NORMAL', point: 40 },
//       { studentType: 'VIP', testType: 'VIP', point: 80 }
//     ]
//   }
// ];
const dummy = [
  {
    content: 'Sự kiện định kỳ',
    value: 'SCHEDULE_TEST',
    point: {
      normal: {
        normal: 20,
        VIP: 40
      },
      VIP: {
        normal: 40,
        VIP: 80
      }
    }
  },
  {
    content: 'Sự kiện hệ thống',
    value: 'SYSTEM_EVENT',
    point: {
      normal: {
        normal: 20,
        VIP: 40
      },
      VIP: {
        normal: 40,
        VIP: 80
      }
    }
  },
  {
    content: 'Bài thực hành',
    value: 'PRACTICE',
    point: {
      normal: {
        normal: 1,
        VIP: 1
      },
      VIP: {
        normal: 2,
        VIP: 2
      }
    }
  },
  {
    content: 'Ngân hàng đề thi',
    value: 'TEST',
    point: {
      normal: {
        normal: 5,
        VIP: 10
      },
      VIP: {
        normal: 10,
        VIP: 15
      }
    }
  }
];

const ConfigPointScreen = memo(() => {
  const navigate = useNavigate();
  const schema = yup
    .object({
      'SCHEDULE_TEST-normal-normal': yup.number().nullable().required('Bắt buộc nhập điểm'),
      'SCHEDULE_TEST-normal-VIP': yup.number().nullable().required('Bắt buộc nhập điểm'),
      'SCHEDULE_TEST-VIP-normal': yup.number().nullable().required('Bắt buộc nhập điểm'),
      'SCHEDULE_TEST-VIP-VIP': yup.number().nullable().required('Bắt buộc nhập điểm'),
      'SYSTEM_EVENT-normal-normal': yup.number().nullable().required('Bắt buộc nhập điểm'),
      'SYSTEM_EVENT-normal-VIP': yup.number().nullable().required('Bắt buộc nhập điểm'),
      'SYSTEM_EVENT-VIP-normal': yup.number().nullable().required('Bắt buộc nhập điểm'),
      'SYSTEM_EVENT-VIP-VIP': yup.number().nullable().required('Bắt buộc nhập điểm'),
      'PRACTICE-normal-normal': yup.number().nullable().required('Bắt buộc nhập điểm'),
      'PRACTICE-normal-VIP': yup.number().nullable().required('Bắt buộc nhập điểm'),
      'PRACTICE-VIP-normal': yup.number().nullable().required('Bắt buộc nhập điểm'),
      'PRACTICE-VIP-VIP': yup.number().nullable().required('Bắt buộc nhập điểm'),
      'TEST-normal-normal': yup.number().nullable().required('Bắt buộc nhập điểm'),
      'TEST-normal-VIP': yup.number().nullable().required('Bắt buộc nhập điểm'),
      'TEST-VIP-normal': yup.number().nullable().required('Bắt buộc nhập điểm'),
      'TEST-VIP-VIP': yup.number().nullable().required('Bắt buộc nhập điểm')
    })
    .required();
  const formProps = useForm({
    resolver: yupResolver(schema)
  });
  const { watch, trigger, getValues, reset, setValue } = formProps;

  useDeepCompareEffect(() => {
    reset({
      'SCHEDULE_TEST-normal-normal': dummy[0].point.normal.normal,
      'SCHEDULE_TEST-normal-VIP': dummy[0].point.normal.VIP,
      'SCHEDULE_TEST-VIP-normal': dummy[0].point.VIP.normal,
      'SCHEDULE_TEST-VIP-VIP': dummy[0].point.VIP.VIP,
      'SYSTEM_EVENT-normal-normal': dummy[1].point.normal.normal,
      'SYSTEM_EVENT-normal-VIP': dummy[1].point.normal.VIP,
      'SYSTEM_EVENT-VIP-normal': dummy[1].point.VIP.normal,
      'SYSTEM_EVENT-VIP-VIP': dummy[1].point.VIP.VIP,
      'PRACTICE-normal-normal': dummy[2].point.normal.normal,
      'PRACTICE-normal-VIP': dummy[2].point.normal.VIP,
      'PRACTICE-VIP-normal': dummy[2].point.VIP.normal,
      'PRACTICE-VIP-VIP': dummy[2].point.VIP.VIP,
      'TEST-normal-normal': dummy[3].point.normal.normal,
      'TEST-normal-VIP': dummy[3].point.normal.VIP,
      'TEST-VIP-normal': dummy[3].point.VIP.normal,
      'TEST-VIP-VIP': dummy[3].point.VIP.VIP
    });
  }, []);

  const columns = [
    {
      title: 'STT',
      render: (_, record, index: number) => String(1 + index)
    },
    {
      title: 'Nội Dung',
      dataIndex: 'content',
      key: 'content',
      editable: true
    },
    {
      title: 'Điểm Tích Luỹ/Câu Hỏi',
      editable: true,
      children: [
        {
          title: 'Học Viên Thường',
          editable: true,
          children: [
            {
              title: 'Phổ Thông',
              editable: true,
              render: (point) => (
                <UIField
                  {...formProps}
                  name={`${point.value}-normal-normal`}
                  fieldComponent={InputNumber}
                />
              )
            },
            {
              title: 'Nâng Cao',
              editable: true,
              render: (point) => (
                <UIField
                  {...formProps}
                  name={`${point.value}-normal-VIP`}
                  fieldComponent={InputNumber}
                />
              )
            }
          ]
        },
        {
          title: 'Học Viên VIP',
          editable: true,
          children: [
            {
              title: 'Phổ Thông',
              editable: true,
              render: (point) => (
                <UIField
                  {...formProps}
                  name={`${point.value}-VIP-normal`}
                  fieldComponent={InputNumber}
                />
              )
            },

            {
              title: 'Nâng Cao',
              editable: true,
              render: (point) => (
                <UIField
                  {...formProps}
                  name={`${point.value}-VIP-VIP`}
                  fieldComponent={InputNumber}
                />
              )
            }
          ]
        }
      ]
    }
  ];

  const handleCancel = () => navigate(AppRoutes.LOYALTY_LIST);

  // const components = {
  //   body: {
  //     row: EditableRow,
  //     cell: EditableCell
  //   }
  // };

  // const handleSave = (row) => {
  //   console.log('🚀 ~ file: ConfigPointScreen.tsx:133 ~ handleSave ~ row:', row);
  //   setTableData((prev) => {
  //     const newData = [...prev];
  //     console.log('🚀 ~ file: ConfigPointScreen.tsx:136 ~ setTableData ~ newData:', newData);
  //     const index = newData.findIndex((item: any) => (row.key || row.id) === (item.key || item.id));
  //     const item = newData[index];
  //     newData.splice(index, 1, {
  //       ...item,
  //       ...row
  //     });
  //     return newData;
  //   });
  // };
  // const mapColumns = (col) => {
  //   if (!col.editable) {
  //     return col;
  //   }

  //   const newCol = {
  //     ...col,
  //     onCell: (record) => ({
  //       record,
  //       editable: col.editable,
  //       dataIndex: col.dataIndex,
  //       title: col.title,
  //       handleSave
  //     })
  //   };
  //   if (col.children) {
  //     newCol.children = col.children?.map(mapColumns);
  //   }
  //   return newCol;
  // };

  // const editableColumns = columns.map(mapColumns);

  return (
    <div style={{ backgroundColor: '#fff', padding: 16 }}>
      <div style={{ fontSize: 26, fontWeight: 'bold' }}>CẤU HÌNH TÍCH ĐIỂM</div>
      <Table
        data={dummy}
        // components={components}
        // rowClassName={() => 'editable-row'}
        bordered
        columns={columns}
        hasPagination={false}
      />
      <Footer onCancel={handleCancel} />
    </div>
  );
});

ConfigPointScreen.propTypes = {};

export default ConfigPointScreen;
