import React, { memo, useEffect, useRef, useState } from 'react';
import Modal from '../Modal/Modal';
import { CloseCircleOutlined, UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import './styles.scss';
import { Form } from 'antd';
import ImageWithPreview from '../ImageWithPreview/ImageWithPreview';

const UIUpload = memo(
  ({
     setFileForm,
     label,
     defaultFile,
     setDuration,
     errMsg,
     setErrMsg,
     loading,
     type = 'image'
   }) => {
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();
    const [previewOpen, setPreviewOpen] = useState(false);
    const hiddenFileInput = React.useRef(null);
    const [isImageOrVideo,setIsImageOrVideo] = useState(false);
    const videoRef = useRef(null);

    useEffect(() => {
      if (defaultFile === 'false'){
        // @ts-ignore
          setFile(null);
          return
      }
      if (typeof defaultFile === 'string'){
      if (defaultFile.match(/\.(jpeg|png|jpg|JPEG|PNG|JPG|svg(\+xml))$/)){
        setIsImageOrVideo(true);
      }
      }
      if (!!defaultFile && defaultFile !== file) {
        setFile(
          typeof defaultFile === 'string' ?
            defaultFile :
            defaultFile?.file ?
              defaultFile?.file :
              URL.createObjectURL(defaultFile)
        );
      }
    }, [defaultFile]);

    const handleChangeFile = async (event) => {
      const fileUploaded = event.target.files[0];
      if (type === 'image') {
        if (!fileUploaded.type.match(/\/(jpeg|png|jpg|JPEG|PNG|JPG|svg(\+xml))$/)) {
          return setErrMsg('Xin hãy chọn file là hình ảnh');
        }
      }
      if (type === 'excel') {
        if (
          fileUploaded.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          return setErrMsg('Xin hãy chọn file là excel');
        }
      }
      if (type === 'imageOrVideo') {
        const maxSizeInBytes = 50 * 1024 * 1024;
        if (!fileUploaded.type.match(/\/(jpeg|png|jpg|JPEG|PNG|JPG|svg(\+xml)|mp4|avi|mkv|mov|wmv)$/)) {
          return setErrMsg('Xin hãy chọn file là video hoặc hình ảnh');
        }else if (fileUploaded.type.match(/\/(jpeg|png|jpg|JPEG|PNG|JPG|svg(\+xml))$/)) {
            setIsImageOrVideo(true);
        }else {
          const videoElement = document.createElement('video');
          await videoElement.canPlayType('') === 'probably';
          const videoURL = URL.createObjectURL(fileUploaded);
          videoElement.src = videoURL;
          videoElement.muted = true;
          await videoElement.play();
          const videoDuration = videoElement.duration
          if (videoDuration > 5.9) {
            return setErrMsg('Xin hãy chọn file video dưới 5 giây');
          }
          setIsImageOrVideo(false);
        }
        if (fileUploaded.size > maxSizeInBytes) {
          return setErrMsg('Xin hãy chọn file video dưới 50MB');
        }
      }
      setFileForm(fileUploaded);
      setFileName(fileUploaded.name);
      const fileUpload = URL.createObjectURL(event.target.files[0]);
      setFile(fileUpload);
      setErrMsg('');
    };

    const handleOpenFile = () => {
      hiddenFileInput.current.click();
    };

    const handleCancel = () => {
      setPreviewOpen(false);
    };

    const handleRemove = () => {
      setFile('');
      setFileForm('');
    };

    const handlePreview = () => {
      setPreviewOpen(true);
    };

    const handleLoadVideo = () => {
      setDuration(videoRef.current.duration);
    };

    const uploadInput = (
      <div className="upload-input-container" aria-hidden="true" onClick={handleOpenFile}>
        <UploadOutlined />
        <span>Tải lên tập tin</span>
        {type === 'image' ? (
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChangeFile}
            style={{ display: 'none' }}
          />
        ) : type === 'video' ? (
          <input
            type="file"
            id="media-URL"
            ref={hiddenFileInput}
            accept="video/*"
            onChange={handleChangeFile}
          />
        ) : (
          <input type="file" id="media-URL" ref={hiddenFileInput} onChange={handleChangeFile} />
        )}
      </div>
    );
    return (
      <Form.Item label={label} help={errMsg} validateStatus="error">
        {file ? (
          <div className="image-container">
            {type === 'image' || isImageOrVideo?  (
              <ImageWithPreview src={file} title="Hình Minh Hoạ" width="100%" />
            ) : type === 'video' || !isImageOrVideo? (
              <video
                width="100%"
                controls
                ref={videoRef}
                onLoadedMetadata={handleLoadVideo}
                key={file}
              >
                <source src={file} />
                Your browser does not support the video tag.
              </video>
            ) : (
              <div className="upload-input-container" aria-hidden="true">
                {loading && <LoadingOutlined />}
                <span>{fileName}</span>
              </div>
            )}
            <CloseCircleOutlined
              className="close-icon"
              onClick={handleRemove}
              style={{ color: 'red', fontSize: 16 }}
            />
          </div>
        ) : (
          uploadInput
        )}
      </Form.Item>
    );
  }
);

UIUpload.propTypes = {};

export default UIUpload;
