import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Select as AntSelect } from 'antd';
import { isObject } from '@common/utils';

const { Option } = AntSelect;

const notFoundContent = <p style={{ textAlign: 'center' }}>Không có dữ liệu</p>;

const Select = memo(
  ({ options = [], textField = 'label', valueField = 'value', value, ...props }) => {
    return (
      <AntSelect
        notFoundContent={notFoundContent}
        value={isObject(value) ? value?.name : value}
        {...props}
      >
        {options.map((item) => (
          <Option key={item.id} value={String(item[valueField])}>
            {item[textField]}
          </Option>
        ))}
      </AntSelect>
    );
  }
);

Select.propTypes = {
  options: PropTypes.array
};

export default Select;
