import { ApiEndpoint } from '@api/constants';
import { apiGet } from '@api/services/services';

export enum AncestorConstant {
  ADD_ANCESTOR_REQUEST = 'ADD_ANCESTOR_REQUEST',
  ADD_ANCESTOR_SUCCESS = 'ADD_ANCESTOR_SUCCESS',
  GET_LIST_ANCESTOR_REQUEST = 'GET_LIST_ANCESTOR_REQUEST',
  GET_LIST_ANCESTOR_SUCCESS = 'GET_LIST_ANCESTOR_SUCCESS'
}

export const getAncestorRequest = (params) => async (dispatch) => {
  try {
    dispatch({ type: AncestorConstant.GET_LIST_ANCESTOR_REQUEST });

    const { data } = await apiGet({
      path: ApiEndpoint.ANCESTOR,
      body: params
    });
    dispatch(getAncestorSuccess(data));
  } catch (error) {
    ErrorNoti(error?.response?.data?.message);
  }
};

export const getAncestorSuccess = (payload) => ({
  type: AncestorConstant.GET_LIST_ANCESTOR_SUCCESS,
  payload
});
