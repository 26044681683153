import axios from 'axios';
import { API_DOMAIN } from '@common/constants/Environment';
import { AppRoutes } from '@common/constants';
import history from '@common/utils/history';

// constants
const defOpts = { 'Content-Type': 'application/json; charset=utf-8' };

const formDataContentType = { 'Content-Type': 'multipart/form-data' };

export const setStorage = ({ key, val }) => window.localStorage.setItem(key, val);

export const getStorage = (key) => window.localStorage.getItem(key) || '';

export const removeStorage = (key) => {
  window.localStorage.removeItem(key);
};

export const setHeader = (isAuth, contentType = defOpts) => {
  const { token } = JSON.parse(getStorage('accessToken'));

  let tmpOpts = {};

  if (isAuth) {
    tmpOpts = {
      Authorization: `Bearer ${token}`
    };
  }

  return {
    ...contentType,
    ...tmpOpts
  };
};

export const apiGet = (params) => {
  const { path, body = {}, isAuth = true, responseType, url: customURL } = params;
  const url = customURL || API_DOMAIN + path;

  const response = axios({
    method: 'get',
    headers: setHeader(isAuth),
    params: body,
    responseType,
    url
  });

  return response;
};

export const apiPut = (params) => {
  const { path, body = {}, isAuth = true } = params;
  const url = API_DOMAIN + path;

  const response = axios({
    method: 'put',
    headers: setHeader(isAuth),
    data: body,
    url
  });

  return response;
};

export const apiPost = (params) => {
  const { path, body = {}, isAuth = true, url: customURL } = params;
  const url = customURL || API_DOMAIN + path;

  const response = axios({
    method: 'post',
    headers: setHeader(isAuth),
    data: body,
    url
  });

  return response;
};

export const apiPatch = (params) => {
  const { path, body = {}, isAuth = true } = params;
  const url = API_DOMAIN + path;

  const response = axios({
    method: 'patch',
    headers: setHeader(isAuth),
    data: body,
    url
  });

  return response;
};

export const apiDel = (params) => {
  const { path, isAuth = true } = params;
  const response = axios({
    method: 'delete',
    headers: setHeader(isAuth),
    url: API_DOMAIN + path
  });

  return response;
};

export const apiFormData = (params) => {
  const { path, body = {}, isAuth = true } = params;
  const url = API_DOMAIN + path;
  const response = axios({
    method: 'post',
    headers: setHeader(isAuth, formDataContentType),
    data: body,
    url
  });

  return response;
};

export const apiPutFormData = (params) => {
  const { path, body = {}, isAuth = true } = params;
  const url = API_DOMAIN + path;
  const response = axios({
    method: 'put',
    headers: setHeader(isAuth, formDataContentType),
    data: body,
    url
  });

  return response;
};

export const messageCode = {
  success: 'success',
  created: 'created',
  accepted: 'accepted',
  no_content: 'no_content',
  bad_request: 'bad_request',
  unauthorized: 'unauthorized',
  forbidden: 'forbidden',
  not_found: 'not_found',
  internal_server_error: 'internal_server_error'
};
