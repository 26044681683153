import { ApiEndpoint } from '@api/constants';
import { apiGet } from '@api/services/services';
import { ErrorNoti } from '@common/components/Notification/Notification';

export enum StudyPlanConstant {
  GET_LIST_STUDY_PLAN_REQUEST = 'GET_LIST_STUDY_PLAN_REQUEST',
  GET_LIST_STUDY_PLAN_SUCCESS = 'GET_LIST_STUDY_PLAN_SUCCESS'
}

export const getStudyPlanRequest = (params: any) => async (dispatch: any) => {
  try {
    dispatch({ type: StudyPlanConstant.GET_LIST_STUDY_PLAN_REQUEST });

    const { data } = await apiGet({
      path: ApiEndpoint.STUDY_PLAN,
      body: params
    });
    dispatch(getStudyPlanSuccess(data));
  } catch (error) {
    ErrorNoti(error?.response?.data?.message);
  }
};

export const getStudyPlanSuccess = (payload: any) => ({
  type: StudyPlanConstant.GET_LIST_STUDY_PLAN_SUCCESS,
  payload
});
