import { ApiEndpoint } from '@api/constants';
import { apiGet } from '@api/services/services';
import { ErrorNoti } from '@common/components/Notification/Notification';
import { createSelector } from 'reselect';

export enum NotificationConstant {
  GET_LIST_NOTIFICATION_REQUEST = 'GET_LIST_NOTIFICATION_REQUEST',
  GET_LIST_NOTIFICATION_SUCCESS = 'GET_LIST_NOTIFICATION_SUCCESS',
  GET_LIST_MANUAL_NOTIFICATION_REQUEST = 'GET_LIST_MANUAL_NOTIFICATION_REQUEST',
  GET_LIST_MANUAL_NOTIFICATION_SUCCESS = 'GET_LIST_MANUAL_NOTIFICATION_SUCCESS'
}

export const getNotificationRequest = (params) => async (dispatch) => {
  try {
    dispatch({ type: NotificationConstant.GET_LIST_NOTIFICATION_REQUEST });

    const { data } = await apiGet({
      path: ApiEndpoint.NOTIFICATION,
      body: params
    });

    dispatch(getNotificationSuccess(data));
  } catch (error) {
    ErrorNoti(error?.response.data.message);
  }
};

export const getNotificationSuccess = (payload) => ({
  type: NotificationConstant.GET_LIST_NOTIFICATION_SUCCESS,
  payload
});

export const getManualNotificationRequest = (params) => async (dispatch) => {
  try {
    dispatch({ type: NotificationConstant.GET_LIST_MANUAL_NOTIFICATION_REQUEST });

    const { data } = await apiGet({
      path: ApiEndpoint.MANUAL_NOTIFICATION,
      body: params
    });

    dispatch(getManualNotificationSuccess(data));
  } catch (error) {
    ErrorNoti(error.response.data.message);
  }
};

export const getManualNotificationSuccess = (payload) => ({
  type: NotificationConstant.GET_LIST_MANUAL_NOTIFICATION_SUCCESS,
  payload
});

export const selectNotificationPage = (state: any) => state.notification;

export const selectNotificationList = () =>
  createSelector(selectNotificationPage, (subState) => subState);

export const selectManualNotificationPage = (state: any) => state.manualNotification;

export const selectManualNotificationList = () =>
  createSelector(selectManualNotificationPage, (subState) => subState);
