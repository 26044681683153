import { range } from 'lodash';
import { uid } from 'uid';

export const CHOICE = 'CHOICE';
export const FILL_IN_BLANK = 'FILL_IN_BLANK';
export const SORT = 'SORT';
export const IMAGE_ERR_MSG = 'Bắt buộc tải tài liệu';
export const VIDEO_ERR_MSG = 'Bắt buộc tải tài liệu';
export const TIME_ERR_MSG = 'Bắt buộc chọn thời gian';
export const TOPIC_TIME_ERR_MSG = 'Bắt buộc chọn thời gian chủ điểm bắt đầu';

const parseSchoolYear = (value) => `${value.toString()}-${parseInt(value) + 1}`;

export const getYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const defaultMinYear = 2012;

  return range(defaultMinYear, currentYear + 3)
    .map((year) => ({
      label: parseSchoolYear(year),
      value: parseSchoolYear(year)
    }))
    .reverse();
};

export const DIFFICULT_LEVELS = [
  { id: uid(), label: 'Phổ thông', value: 'NORMAL' },
  { id: uid(), label: 'Nâng cao', value: 'ADVANCED' }
];

export const STUDENT_TYPES = [
  { id: uid(), label: 'Thường', value: 'NORMAL' },
  { id: uid(), label: 'VIP', value: 'VIP' }
];

export const EXAM_TYPES = [
  { id: uid(), label: 'Chính thức', value: 'OFFICIAL' },
  { id: uid(), label: 'Rèn luyện', value: 'PRACTICE' },
  { id: uid(), label: 'Sự kiện hệ thống', value: 'EVENT' }
];

export const QUESTION_TYPES = [
  { id: uid(), label: 'Trắc nghiệm', value: CHOICE },
  { id: uid(), label: 'Điền khuyết', value: FILL_IN_BLANK },
  { id: uid(), label: 'Sắp xếp', value: SORT }
];

export const STATUS = [
  { id: uid(), label: 'Hoạt động', color: 'success', value: 'ACTIVE' },
  { id: uid(), label: 'Khóa', color: 'error', value: 'LOCK' }
];

export const STUDY_PACK_TYPES = [
  { id: 1, label: 'Gói học kỳ', value: 'SEMESTER' },
  { id: 2, label: 'Gói chuyên đề', value: 'THEMATIC' }
];

export const PAYMENT_METHOD = [
  { id: uid(), label: 'Chuyển khoản', value: 'tranfer' },
  { id: uid(), label: 'Momo', value: 'MOMO' },
  { id: uid(), label: 'Napas', value: 'NAPAS' },
  { id: uid(), label: 'Alepay', value: 'ALEPAY' }
];

export const PAYMENT_STATUS = [
  { id: uid(), label: 'Đang chờ duyệt', value: 'PENDING', color: 'processing' },
  {
    id: uid(),
    label: 'Thành công',
    value: 'SUCCESS',
    color: 'success'
  },
  { id: uid(), label: 'Bị hủy', value: 'FAILED', color: 'error' }
];

export const SYSTEM_EVENT_STATUS = [
  { id: uid(), label: 'Sắp diễn ra', value: 'GOING_TO_HAPPEN', color: 'processing' },
  {
    id: uid(),
    label: 'Đang diễn ra',
    value: 'HAPPENING',
    color: 'success'
  },
  { id: uid(), label: 'Kết thúc', value: 'END', color: 'error' }
];

export const NOTIFICATION_TYPE = [
  { id: uid(), label: 'Thông Báo Đẩy', value: 'PUSH' },
  {
    id: uid(),
    label: 'Thông Báo Qua Email',
    value: 'EMAIL'
  },
  // { id: uid(), label: 'Thông Báo SMS', value: 'SMS' },
  { id: uid(), label: 'Thông Báo Lên Màn Hình Website', value: 'POPUP' }
];

export const MANUAL_NOTIFICATION_TYPE = [
  { id: 1, label: 'Thông Báo Đẩy', value: 'PUSH' },
  {
    id: 2,
    label: 'Thông Báo Qua Email',
    value: 'EMAIL'
  }
];

export const MANUAL_NOTIFICATION_STATUS = [
  { id: 1, label: 'Thành Công', value: 'SUCCESS', color: 'success' },
  {
    id: 2,
    label: 'Thất Bại',
    value: 'FAIL',
    color: 'error'
  }
];

export const EVENT_TYPE = [
  { id: uid(), label: 'Sự Kiện Hệ Thống', value: 'SYSTEM_EVENT' },
  { id: uid(), label: 'Sự Kiện Định Kỳ', value: 'SCHEDULE_EVENT' },
  { id: uid(), label: 'Tạo Tài Khoản', value: 'ACCOUNT_CREATE' },
  { id: uid(), label: 'Trạng Thái Đơn Hàng Thành Công (Chuyển Khoản)', value: 'ORDER_SUCCESS' },
  { id: uid(), label: 'Trạng Thái Đơn Hàng Thất Bại (Chuyển Khoản)', value: 'ORDER_FAIL' },
  { id: uid(), label: 'Thông Báo Đăng Ký Cho Phụ Huynh', value: 'ANCESTOR_REGISTER' },
  { id: uid(), label: 'Chúc Mừng Sinh Nhật', value: 'HAPPY_BIRTHDAY' }
];

export const TIME_UNIT = [
  { id: uid(), label: 'Ngày', value: 'DAY' },
  { id: uid(), label: 'Giờ', value: 'HOUR' },
  { id: uid(), label: 'Phút', value: 'MINUTE' }
  // { id: uid(), label: 'Giây', value: 'SECOND' }
];

export const RECEIVER = [
  // { id: 1, label: 'Khách Hàng Vãng Lai', value: 'GUEST' },
  { id: 2, label: 'Học Viên', value: 'STUDENT' },
  { id: 3, label: 'Phụ Huynh', value: 'ANCESTOR' }
  // { id: 4, label: 'Giáo Viên', value: 'TEACHER' },
  // { id: 5, label: 'Học Thuật', value: 'ACADEMIC' },
  // { id: 6, label: 'Admin', value: 'ADMIN' },
  // { id: 7, label: 'Kinh Doanh', value: 'SALE' }
];

export const LOYALTY_STATUS = [
  { id: 1, label: 'Hiệu Lực', value: 'ACTIVE', color: 'success' },
  { id: 2, label: 'Hết Hiệu Lực', value: 'EXPIRED', color: 'error' },
  { id: 3, label: 'Khóa', value: 'LOCKED', color: 'warning' }
];

export const PROMO_TYPE = [
  { id: 1, label: 'Voucher', value: 'VOUCHER' },
  { id: 2, label: 'Coupon', value: 'COUPON' }
];

export const ADMIN_TYPE = [
  { id: 4, label: 'Giáo Viên', value: 'TEACHER' },
  { id: 5, label: 'Học Thuật', value: 'ACADEMIC' },
  { id: 6, label: 'Admin', value: 'SUPPER_ADMIN' },
  { id: 7, label: 'Kinh Doanh', value: 'MARKETER' }
];

export const CHANGE_REQUEST_STATUS = [
  { id: 1, label: 'Yêu Cầu Mới', value: 'NEW', color: 'default' },
  { id: 2, label: 'Đang Vận Chuyển', value: 'SHIPPING', color: 'processing' },
  { id: 3, label: 'Đã Nhận', value: 'DELIVERED', color: 'success' },
  { id: 4, label: 'Bị Huỷ', value: 'CANCEL', color: 'error' }
];

export const USERNAME_AVAILABLE = 'Username is available';
