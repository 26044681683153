import React from 'react';

import { LoadingOutlined } from '@ant-design/icons';

const Loading: React.FunctionComponent = () => (
  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
    <LoadingOutlined style={{ fontSize: 64 }} />
  </div>
);

export default Loading;
