import React, { memo, ReactNode, FC, useEffect } from 'react';
import { Modal as AntModal, ModalProps } from 'antd';

import './styles.scss';

interface IModalProps extends ModalProps {
  children?: ReactNode;
  [key: string]: any;
}

const Modal: FC<IModalProps> = ({ onClick, children, ...props }) => {
  useEffect(() => {
    if (props.open) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [props.open]);

  return (
    <div className="bittus-modal" id="bittus-modal">
      <AntModal
        {...props}
        getContainer={document.getElementById('bittus-modal')}
        destroyOnClose
        maskClosable={false}
      >
        {children}
      </AntModal>
    </div>
  );
};

export default memo(Modal);
