import PropTypes from 'prop-types';
import React, { memo } from 'react';
import ProtectedRoute from '@common/containers/ProtectedRoute';
import { AppRoutes } from '@common/constants';
import useAxiosInterceptor from '@common/hooks/useAxiosInterceptor';
import axios from 'axios';
import { Route, Routes as RouterRoutes, useNavigate } from 'react-router-dom';

const Layout = React.lazy(() => import('@layout/components/Layout'));
const LoginPage = React.lazy(() => import('@pages/login/Login.Container'));
// const Register = React.lazy(() => import('./register/components/Register'));
const Page404 = React.lazy(() => import('@pages/page-404/Page404'));
const Page500 = React.lazy(() => import('@pages/page-500/Page500'));

const Routes = memo(() => {
  const navigate = useNavigate();
  useAxiosInterceptor(axios, navigate);
  return (
    <RouterRoutes>
      <Route path={AppRoutes.LOGIN} element={<LoginPage />} />
      <Route path={AppRoutes.ERROR_404} element={<Page404 />} />
      <Route path={AppRoutes.ERROR_500} element={<Page500 />} />
      <Route
        path="*"
        element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        }
      />
    </RouterRoutes>
  );
});

Routes.propTypes = {};

export default Routes;
