/* eslint-disable new-cap */
import { ApiEndpoint } from '@api/constants';
import { apiDel, apiGet, apiPatch, apiPost, apiPut } from '@api/services/services';
import { ErrorNoti, SuccessNoti } from '@common/components/Notification/Notification';
import { buildFormData } from '@common/utils';
import { pickBy } from 'lodash';

export enum CommonOptionConstant {
  GET_COMMON_OPTION_REQUEST = 'GET_COMMON_OPTION_REQUEST',
  GET_UNIT_SUCCESS = 'GET_UNIT_SUCCESS',
  GET_THEMATIC_SUCCESS = 'GET_THEMATIC_SUCCESS',
  GET_SUBJECT_SUCCESS = 'GET_SUBJECT_SUCCESS',
  GET_SEMESTER_SUCCESS = 'GET_SEMESTER_SUCCESS',
  GET_COMMON_SELECT_SUCCESS = 'GET_COMMON_SELECT_SUCCESS'
}

export const getCommonSelectSuccess = (payload) => ({
  type: CommonOptionConstant.GET_COMMON_SELECT_SUCCESS,
  payload
});

export const getUnitRequest = (params) => async (dispatch) => {
  try {
    dispatch({ type: CommonOptionConstant.GET_COMMON_OPTION_REQUEST });

    const { data } = await apiGet({
      path: ApiEndpoint.UNIT,
      body: params
    });

    dispatch(getUnitSuccess(data));
  } catch (error) {
    ErrorNoti(error?.response?.data?.message);
  }
};

export const getUnitSuccess = (payload) => ({
  type: CommonOptionConstant.GET_UNIT_SUCCESS,
  payload
});

export const getSubjectRequest = (params) => async (dispatch) => {
  try {
    dispatch({ type: CommonOptionConstant.GET_COMMON_OPTION_REQUEST });

    const { data } = await apiGet({
      path: ApiEndpoint.SUBJECT,
      body: params
    });

    dispatch(getSubjectSuccess(data));
  } catch (error) {
    ErrorNoti(error?.response?.data?.message);
  }
};

export const getSubjectSuccess = (payload) => ({
  type: CommonOptionConstant.GET_SUBJECT_SUCCESS,
  payload
});

export const getSemesterRequest = (params) => async (dispatch) => {
  try {
    dispatch({ type: CommonOptionConstant.GET_COMMON_OPTION_REQUEST });

    const { data } = await apiGet({
      path: ApiEndpoint.SEMESTER,
      body: params
    });

    dispatch(getSemesterSuccess(data));
  } catch (error) {
    ErrorNoti(error?.response?.data?.message);
  }
};

export const getSemesterSuccess = (payload) => ({
  type: CommonOptionConstant.GET_SEMESTER_SUCCESS,
  payload
});

export const getThematicSuccess = (payload) => ({
  type: CommonOptionConstant.GET_THEMATIC_SUCCESS,
  payload
});

export const getThematicRequest = (params) => async (dispatch) => {
  try {
    dispatch({ type: CommonOptionConstant.GET_COMMON_OPTION_REQUEST });

    const { data } = await apiGet({
      path: ApiEndpoint.THEMATIC,
      body: params
    });

    dispatch(getThematicSuccess(data));
  } catch (error) {
    ErrorNoti(error?.response?.data?.message);
  }
};

export const addCommonRequest = async (payload, callback: () => void, isFormData = false) => {
  let formData;
  const { entity, message, ...request } = payload;
  if (isFormData) {
    formData = new FormData();
    buildFormData(formData, pickBy(request, Boolean));
  }
  try {
    const {
      data: { data }
    } = await apiPost({
      path: `/api/${entity}`,
      body: isFormData ? formData : request
    });

    if (data) {
      SuccessNoti(`Thêm ${payload.message}`);
      if (typeof callback === 'function') {
        callback();
      }
      return data;
    }
  } catch (error) {
    if (typeof callback === 'function') {
      callback();
    }
    ErrorNoti(error?.response?.data?.error || error?.response?.data?.message || 'Có lỗi xảy ra');
  }
};

export const updateCommonRequest = async (payload, callback, isFormData = false) => {
  let formData;
  if (isFormData) {
    formData = new FormData();
    buildFormData(formData, pickBy(payload, Boolean));
  }

  try {
    const { data } = await apiPut({
      path: `/api/${payload.entity}/${payload.id}`,
      body: isFormData ? formData : payload
    });
    if (data) {
      SuccessNoti(`Cập nhật ${payload.message}`);
      if (typeof callback === 'function') {
        callback();
      }
    }
  } catch (error) {
    if (typeof callback === 'function') {
      callback();
    }
    ErrorNoti(error?.response?.data?.message);
    throw new Error(error?.response?.data?.message);
  }
};

export const updateCommonPostRequest = async (payload, callback, isFormData = false) => {
  let formData;
  if (isFormData) {
    formData = new FormData();
    buildFormData(formData, pickBy(payload, (value) => {
        return value !== null && value !== undefined && !Number.isNaN(value) && value !== false;
    }));
  }
  try {
    const {
      data: { data }
    } = await apiPost({
      path: `/api/${payload.entity}/${payload.id}`,
      body: isFormData ? formData : payload
    });
    if (data) {
      SuccessNoti(`Cập nhật ${payload.message}`);
      if (typeof callback === 'function') {
        callback();
      }
    }
  } catch (error) {
    if (typeof callback === 'function') {
      callback();
    }
    ErrorNoti(error?.response?.data?.message);
  }
};

export const deleteCommonRequest = async (payload, callback) => {
  try {
    await apiDel({
      path: `/api/${payload.entity}/${payload.id}`
    });

    SuccessNoti(`Xóa ${payload.message}`);
    callback();
  } catch (error) {
    if (typeof callback === 'function') {
      callback();
    }
    ErrorNoti(error?.response?.data?.message);
  }
};

export const lockCommonRequest = async (payload, callback) => {
  try {
    await apiPut({
      path: `/api/${payload.entity}/${payload.id}/lock`
    });

    SuccessNoti(`Khóa ${payload.message}`);
    callback();
  } catch (error) {
    if (typeof callback === 'function') {
      callback();
    }
    ErrorNoti(error?.response?.data?.message);
  }
};

export const unlockCommonRequest = async (payload, callback) => {
  try {
    await apiPut({
      path: `/api/${payload.entity}/${payload.id}/active`
    });

    SuccessNoti(`Mở Khóa ${payload.message}`);
    callback();
  } catch (error) {
    if (typeof callback === 'function') {
      callback();
    }
    ErrorNoti(error?.response?.data?.message);
  }
};

export const _lockCommonRequest = async (payload, callback) => {
  try {
    await apiPatch({
      path: `/api/${payload.entity}/${payload.id}/lock`,
      body: { is_locked: payload.is_locked }
    });

    SuccessNoti(`${payload.is_locked ? 'Khóa' : 'Mở Khoá'} ${payload.message}`);
    callback();
  } catch (error) {
    if (typeof callback === 'function') {
      callback();
    }
    ErrorNoti(error?.response?.data?.message);
  }
};
