import { ApiEndpoint } from '@api/constants';
import { apiDel, apiGet, apiPost } from '@api/services/services';
import { ErrorNoti, SuccessNoti } from '@common/components/Notification/Notification';
import { CATEGORY } from '@common/constants/constantType';
import { buildFormData, isObject, mapReduce } from '@common/utils';
import { ExamQuestionType, QuestionType } from '@pages/exam-question/types';
import { PracticeType } from '@pages/practices/types';

export interface DeleteQuestionPayload {
  examId: string;
  questionId: string;
}

export enum PracticeThunk {
  GET_LIST_PRACTICE_REQUEST = 'GET_LIST_PRACTICE_REQUEST',
  GET_LIST_PRACTICE_SUCCESS = 'GET_LIST_PRACTICE_SUCCESS',
  ADD_PRACTICE_REQUEST = 'ADD_PRACTICE_REQUEST',
  ADD_PRACTICE_SUCCESS = 'ADD_PRACTICE_SUCCESS',
  DELETE_PRACTICE_REQUEST = 'DELETE_PRACTICE_REQUEST',
  DELETE_PRACTICE_SUCCESS = 'DELETE_PRA CTICE_SUCCESS',
  UPDATE_PRACTICE = 'UPDATE_PRACTICE'
}

export const getPracticesRequest = (params) => async (dispatch) => {
  try {
    dispatch({ type: PracticeThunk.GET_LIST_PRACTICE_REQUEST });

    const { data } = await apiGet({
      path: ApiEndpoint.PRACTICE,
      body: params
    });

    const mapData = data.data.map((item) => ({
      ...item,
      subject: { ...item.subject, label: item.subject.name, value: item.subject.id },
      semester: { ...item.semester, label: item.semester.name, value: item.semester.id },
      category: { ...item.category, label: item.category?.name, value: item.category?.id },
      examId: item.code,
      examType: item.type
    }));
    dispatch(getPracticesSuccess({ ...data, data: mapData }));
  } catch (error) {
    ErrorNoti(error?.response?.data?.message);
  }
};

export const getPracticesSuccess = (payload: ExamQuestionType) => ({
  type: PracticeThunk.GET_LIST_PRACTICE_SUCCESS,
  payload
});

export const updatePracticeRequest = (payload, callback) => async (dispatch) => {
  const {
    name,
    code,
    examType,
    subject,
    semester,
    difficulty,
    questions,
    thematic,
    grade,
    image,
    category,
    year,
    student_type
  } = payload;

  const mapPayload = {
    name,
    code,
    type: examType,
    subject_id: subject.id,
    semester_id: semester.id,
    difficulty,
    questions: questions.map((q) => ({
      ...q,
      answers: q.answers?.map((a) => ({ ...a, correct: a.correct ? 1 : 0 }))
    })),
    thematic_id: thematic,
    unit_id: grade,
    image,
    practice_category_id: isObject(category) ? category.id : category,
    year,
    student_type,
    _method: 'PATCH'
  };

  const formData = new FormData();
  buildFormData(formData, mapPayload);
  try {
    dispatch({ type: PracticeThunk.ADD_PRACTICE_REQUEST });

    const {
      data: { data }
    } = await apiPost({
      path: `${ApiEndpoint.PRACTICE}/${payload.id}`,
      body: formData
    });
    if (data) {
      SuccessNoti('Cập nhật bài thực hành');
      callback();
    }
  } catch (error) {
    callback();
    ErrorNoti(error?.response?.data?.message);
  }
};

export const addPracticeRequest = (payload, callback) => async (dispatch) => {
  const {
    name,
    subject,
    semester,
    difficulty,
    category,
    questions,
    grade,
    image,
    year,
    student_type
  } = payload;

  const mapPayload = {
    code: name,
    name,
    subject_id: subject,
    semester_id: semester,
    difficulty,
    questions,
    unit_id: grade,
    practice_category_id: isObject(category) ? category.id : category,
    image,
    student_type,
    year
  };
  const formData = new FormData();
  buildFormData(formData, mapPayload);

  try {
    dispatch({ type: PracticeThunk.ADD_PRACTICE_REQUEST });

    const {
      data: { data }
    } = await apiPost({
      path: ApiEndpoint.PRACTICE,
      body: formData
    });
    if (data) {
      SuccessNoti('Thêm bài thực hành');
      callback();
    }
  } catch (error) {
    callback();
    ErrorNoti(error?.response?.data?.message);
  }
};

export const deletePracticeRequest = (payload, callback) => async (dispatch) => {
  try {
    dispatch({ type: PracticeThunk.DELETE_PRACTICE_REQUEST });
    await apiDel({
      path: `${ApiEndpoint.PRACTICE}/${payload.id}`
    });

    SuccessNoti('Xóa bài thực hành');

    callback();
  } catch (error) {
    callback();
    ErrorNoti(error?.response?.data?.message);
  }
};

export const setPracticeSuccess = (payload: PracticeType) => ({
  type: PracticeThunk.ADD_PRACTICE_SUCCESS,
  payload
});

export const deleteQuestion = (payload: DeleteQuestionPayload) => ({
  type: PracticeThunk.DELETE_PRACTICE,
  payload
});

export const updateQuestion = (payload: QuestionType) => ({
  type: PracticeThunk.UPDATE_PRACTICE,
  payload
});
