
export enum IAccountType {
  TEACHER = 'TEACHER',
  ACADEMIC ='ACADEMIC',
  SUPPER_ADMIN = 'SUPPER_ADMIN',
  MARKETER='MARKETER'
}

export interface IUser {
  id: number | string;
  name: string;
  email: string;
  email_verified_at: string;
  created_at: string;
  updated_at: string;
  phone: string;
  birthday: string;
  affiliate_code: string;
  address: string;
  account_type?: IAccountType
}
