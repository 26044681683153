import { ApiEndpoint } from '@api/constants';
import { apiDel, apiGet, apiPost } from '@api/services/services';
import { ErrorNoti, SuccessNoti } from '@common/components/Notification/Notification';
import { buildFormData } from '@common/utils';
import { ExamQuestionType, QuestionType } from '@pages/exam-question/types';
import { pickBy } from 'lodash';

export interface DeleteQuestionPayload {
  examId: string;
  questionId: string;
}

export enum ExamQuestionThunk {
  ADD_EXAM_QUESTION_REQUEST = 'ADD_EXAM_QUESTION_REQUEST',
  ADD_EXAM_QUESTION_SUCCESS = 'ADD_EXAM_QUESTION_SUCCESS',
  DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS',
  DELETE_QUESTION_REQUEST = 'DELETE_QUESTION_REQUEST',
  UPDATE_QUESTION = 'UPDATE_QUESTION',
  GET_LIST_EXAM_QUESTION_REQUEST = 'GET_LIST_EXAM_QUESTION_REQUEST',
  GET_LIST_EXAM_QUESTION_SUCCESS = 'GET_LIST_EXAM_QUESTION_SUCCESS'
}

export const getExamQuestionRequest = (params) => async (dispatch) => {
  try {
    dispatch({ type: ExamQuestionThunk.GET_LIST_EXAM_QUESTION_REQUEST });

    const { data } = await apiGet({
      path: ApiEndpoint.EXAM_QUESTION,
      body: params
    });

    const mapData = data.data.map((item) => ({
      ...item,
      subject: { ...item.subject, label: item.subject.name, value: item.subject.id },
      semester: { ...item.semester, label: item.semester.name, value: item.semester.id },
      duration: Math.floor(item.duration / 60)
    }));
    dispatch(getExamQuestionSuccess({ ...data, data: mapData }));
  } catch (error) {
    ErrorNoti(error.message);
  }
};
export const getExamQuestionSuccess = (payload: ExamQuestionType) => ({
  type: ExamQuestionThunk.GET_LIST_EXAM_QUESTION_SUCCESS,
  payload
});

export const deleteExamQuestionRequest = (payload, callback) => async (dispatch) => {
  try {
    await apiDel({
      path: `${ApiEndpoint.EXAM_QUESTION}/${payload.id}`
    });
    SuccessNoti('Xóa đề thi');
    callback();
  } catch (error) {
    callback();
    ErrorNoti(error.message);
  }
};

export const addExamQuestionRequest = (payload, callback) => async (dispatch) => {
  const {
    name,
    code,
    type,
    subject,
    semester,
    duration,
    difficulty,
    questions,
    unit,
    image,
    year,
    student_type
  } = payload;
  const mapPayload = {
    name,
    code,
    type,
    subject_id: subject,
    semester_id: semester,
    duration: duration * 60,
    difficulty,
    questions: questions.map((q) => pickBy(q, Boolean)),
    unit_id: unit,
    image,
    year,
    student_type
  };

  const formData = new FormData();
  buildFormData(formData, pickBy(mapPayload, Boolean));

  try {
    dispatch({ type: ExamQuestionThunk.ADD_EXAM_QUESTION_REQUEST });

    const {
      data: { data }
    } = await apiPost({
      path: ApiEndpoint.EXAM_QUESTION,
      body: formData
    });
    if (data) {
      SuccessNoti('Thêm đề thi');
      callback();
    }
  } catch (error) {
    callback();
    ErrorNoti(error.message);
  }
};

export const updateExamQuestionRequest = (payload, callback) => async (dispatch) => {
  const {
    name,
    code,
    type,
    subject,
    semester,
    duration,
    difficulty,
    questions,
    unit,
    image,
    year,
    student_type
  } = payload;
  const mapPayload = {
    name,
    code,
    type,
    subject_id: subject.id,
    semester_id: semester.id,
    duration: Math.floor(duration * 60),
    difficulty,
    questions: questions.map((q) => ({
      ...q,
      answers: q.answers?.map((a) => ({ ...a, correct: a.correct ? 1 : 0 }))
    })),
    unit_id: unit.id,
    image,
    year,
    student_type,
    _method: 'PATCH'
  };

  const formData = new FormData();
  buildFormData(formData, mapPayload);

  try {
    dispatch({ type: ExamQuestionThunk.ADD_EXAM_QUESTION_REQUEST });

    const {
      data: { data }
    } = await apiPost({
      path: `${ApiEndpoint.EXAM_QUESTION}/${payload.id}`,
      body: formData
    });
    if (data) {
      SuccessNoti('Cập nhật đề thi');
      callback();
    }
  } catch (error) {
    callback();
    ErrorNoti(error.message);
  }
};

export const setExamQuestionSuccess = (payload: ExamQuestionType) => ({
  type: ExamQuestionThunk.ADD_EXAM_QUESTION_SUCCESS,
  payload
});

export const deleteQuestion = (payload: DeleteQuestionPayload) => ({
  type: ExamQuestionThunk.DELETE_QUESTION,
  payload
});

export const updateQuestion = (payload: QuestionType) => ({
  type: ExamQuestionThunk.UPDATE_QUESTION,
  payload
});
