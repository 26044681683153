import { ApiEndpoint } from '@api/constants';
import {
  TUnitCreateRequest,
  TUnitDeleteResponse,
  TUnitOptionsResponse,
  TUnitResponse,
  TUnitsRequest,
  TUnitsResponse,
  TUnitUpdateRequest
} from '@api/interfaces';
import axiosInstance from '@api/services/axios';
import { API_DOMAIN } from '@common/constants';
import { AxiosResponse } from 'axios';

class UnitApi {
  public static options = (
    request: TUnitsRequest
  ): Promise<AxiosResponse<TUnitOptionsResponse>> => {
    const url = new URL(ApiEndpoint.OPTIONS_UNIT, API_DOMAIN);
    url.searchParams.set('page', `${request.pageIndex}`);
    url.searchParams.set('size', `${request.pageSize}`);
    url.searchParams.set('filters', JSON.stringify(request.filters ?? []));
    url.searchParams.set('globalFilter', request.globalFilter ?? '');
    url.searchParams.set('sorting', JSON.stringify(request.sorting ?? []));
    return (
      axiosInstance
        // .get(`${API_DOMAIN}/${ApiEndpoint.OPTIONS_UNIT}`, {
        .get(url.toString(), {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          maxRedirects: 0
        })
        .catch((error) => error.handleGlobally && error.handleGlobally())
    );
  };

  public static list = (request: TUnitsRequest): Promise<AxiosResponse<TUnitsResponse>> => {
    const url = new URL(ApiEndpoint.UNIT, API_DOMAIN);
    url.searchParams.set('page', `${request.pageIndex}`);
    url.searchParams.set('size', `${request.pageSize}`);
    url.searchParams.set('filters', JSON.stringify(request.filters ?? []));
    url.searchParams.set('globalFilter', request.globalFilter ?? '');
    url.searchParams.set('sorting', JSON.stringify(request.sorting ?? []));

    // @ts-ignore
    return axiosInstance
      .get(url.toString(), {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
      .catch((error) => error.handleGlobally && error.handleGlobally());
  };

  public static delete = (id: number | string): Promise<AxiosResponse<TUnitDeleteResponse>> =>
    axiosInstance
      .delete(`${API_DOMAIN}/${ApiEndpoint.UNIT}/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        maxRedirects: 0
      })
      .catch((error) => error.handleGlobally && error.handleGlobally());

  public static get = (id: number): Promise<AxiosResponse<TUnitResponse>> =>
    axiosInstance
      .get(`${API_DOMAIN}/${ApiEndpoint.UNIT}/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        maxRedirects: 0
      })
      .catch((error) => error.handleGlobally && error.handleGlobally());

  public static update = (
    id: number,
    request: TUnitUpdateRequest
  ): Promise<AxiosResponse<TUnitResponse>> =>
    axiosInstance
      .patch(`${API_DOMAIN}/${ApiEndpoint.UNIT}/${id}`, request, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        maxRedirects: 0
      })
      .catch((error) => error.handleGlobally && error.handleGlobally());

  public static create = (request: TUnitCreateRequest): Promise<AxiosResponse<TUnitResponse>> =>
    axiosInstance
      .post(`${API_DOMAIN}/${ApiEndpoint.UNIT}`, request, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        maxRedirects: 0
      })
      .catch((error) => error.handleGlobally && error.handleGlobally());
}

export default UnitApi;
