import { ApiEndpoint } from "@api/constants";
import {
    TThematicCreateRequest,
    TThematicDeleteResponse,
    TThematicOptionsResponse,
    TThematicResponse,
    TThematicsRequest,
    TThematicsResponse,
    TThematicUpdateRequest,
} from "@api/interfaces";
import axiosInstance from "@api/services/axios";
import { API_DOMAIN } from "@common/constants/Environment";
import { AxiosResponse } from "axios";

class ThematicApi {
    public static options = (): Promise<AxiosResponse<TThematicOptionsResponse>> => axiosInstance.get(
        `${API_DOMAIN}/${ApiEndpoint.OPTIONS_THEMATIC}`,
        {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            maxRedirects: 0,
        },
    ).catch(error => error.handleGlobally && error.handleGlobally());

    public static list = (request: TThematicsRequest): Promise<AxiosResponse<TThematicsResponse>> => {
        const url = new URL(ApiEndpoint.THEMATIC, API_DOMAIN);
        url.searchParams.set("page", `${request.pageIndex}`);
        url.searchParams.set("size", `${request.pageSize}`);
        url.searchParams.set("filters", JSON.stringify(request.filters ?? []));
        url.searchParams.set("globalFilter", request.globalFilter ?? "");
        url.searchParams.set("sorting", JSON.stringify(request.sorting ?? []));

        // @ts-ignore
        return axiosInstance.get(
            url.toString(),
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        ).catch(error => error.handleGlobally && error.handleGlobally());
    };

    public static create = (request: TThematicCreateRequest): Promise<AxiosResponse<TThematicResponse>> =>
        axiosInstance.post(
            `${API_DOMAIN}/${ApiEndpoint.THEMATIC}`,
            request,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                maxRedirects: 0,
            },
        ).catch(error => error.handleGlobally && error.handleGlobally());

    public static update = (id: number, request: TThematicUpdateRequest): Promise<AxiosResponse<TThematicResponse>> =>
        axiosInstance.patch(
            `${API_DOMAIN}/${ApiEndpoint.THEMATIC}/${id}`,
            request,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                maxRedirects: 0,
            },
        ).catch(error => error.handleGlobally && error.handleGlobally());

    public static get = (id: number): Promise<AxiosResponse<TThematicResponse>> => axiosInstance.get(
        `${API_DOMAIN}/${ApiEndpoint.THEMATIC}/${id}`,
        {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            maxRedirects: 0,
        },
    ).catch(error => error.handleGlobally && error.handleGlobally());

    public static delete = (id: number | string): Promise<AxiosResponse<TThematicDeleteResponse>> =>
        axiosInstance.delete(
            `${API_DOMAIN}/${ApiEndpoint.THEMATIC}/${id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                maxRedirects: 0,
            },
        ).catch(error => error.handleGlobally && error.handleGlobally());
}

export default ThematicApi;
