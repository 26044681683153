import { ApiEndpoint } from '@api/constants';
import { apiGet } from '@api/services/services';
import { ErrorNoti } from '@common/components/Notification/Notification';
import { createSelector } from 'reselect';

export enum ReportReducerConstant {
  GET_LIST_REPORT_RANKING_REQUEST = 'GET_LIST_REPORT_RANKING_REQUEST',
  GET_LIST_REPORT_RANKING_SUCCESS = 'GET_LIST_REPORT_RANKING_SUCCESS'
}

export const getReportReducerRequest = (params) => async (dispatch) => {
  const { id, ...restParams } = params;
  try {
    dispatch({ type: ReportReducerConstant.GET_LIST_REPORT_RANKING_REQUEST });

    const { data } = await apiGet({
      path: ApiEndpoint.REPORT_RANKING,
      body: restParams
    });

    dispatch(getReportReducerSuccess(data));
  } catch (error) {
    ErrorNoti(error.response.data.message);
  }
};

export const getReportReducerSuccess = (payload) => ({
  type: ReportReducerConstant.GET_LIST_REPORT_RANKING_SUCCESS,
  payload
});

export const selectReportReducerPage = (state: any) => state.reportRanking;

export const selectReportReducerList = () =>
  createSelector(selectReportReducerPage, (subState) => subState);
