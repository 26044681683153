export const ALL_PERMISSIONS = [
  'VIEW_USER',
  'UPDATE_USER',
  'SHARE_AFFILIATE_CODE',
  'VIEW_AFFILIATE_STATISTICS',
  'LIST_USER',
  'LOCK_USER',
  'VIEW_STUDENT_DETAIL',
  'VIEW_STUDENT_STUDY_GOALS',
  'VIEW_STUDENT_REPORT',
  'CREATE_USER',
  'DELETE_USERS',
  'LIST_KNOWLEDGE',
  'VIEW_KNOWLEDGE',
  'CREATE_KNOWLEDGE',
  'UPDATE_KNOWLEDGE',
  'LOCK_KNOWLEDGE',
  'DELETE_KNOWLEDGE',
  'LIST_THEMATIC',
  'CREATE_THEMATIC',
  'UPDATE_THEMATIC',
  'LOCK_THEMATIC',
  'DELETE_THEMATIC',
  'LIST_STUDY_PLAN',
  'CREATE_STUDY_PLAN',
  'UPDATE_STUDY_PLAN',
  'LOCK_STUDY_PLAN',
  'DELETE_STUDY_PLAN',
  'LIST_PRACTICE',
  'VIEW_PRACTICE',
  'CREATE_PRACTICE',
  'UPDATE_PRACTICE',
  'LOCK_PRACTICE',
  'DELETE_PRACTICE',
  'LIST_TEST',
  'VIEW_TEST',
  'CREATE_TEST',
  'UPDATE_TEST',
  'LOCK_TEST',
  'DELETE_TEST',
  'LIST_TEST_SCHEDULE',
  'VIEW_TEST_SCHEDULE',
  'CREATE_TEST_SCHEDULE',
  'UPDATE_TEST_SCHEDULE',
  'LOCK_TEST_SCHEDULE',
  'DELETE_TEST_SCHEDULE',
  'LIST_EVENT',
  'VIEW_EVENT',
  'CREATE_EVENT',
  'UPDATE_EVENT',
  'LOCK_EVENT',
  'DELETE_EVENT',
  'LIST_LEARN',
  'VIEW_LEARN',
  'CREATE_LEARN',
  'UPDATE_LEARN',
  'LOCK_LEARN',
  'DELETE_LEARN',
  'LIST_ORDER',
  'VIEW_ORDER',
  'LIST_PROMOTION',
  'CREATE_PROMOTION',
  'UPDATE_PROMOTION',
  'DELETE_PROMOTION',
  'LOCK_PROMOTION',
  'CONFIG_PROMOTION',
  'LIST_GIFT',
  'CREATE_GIFT',
  'UPDATE_GIFT',
  'DELETE_GIFT',
  'LOCK_GIFT',
  'REQUEST_GIFT',
  'LIST_NOTIFICATION',
  'VIEW_NOTIFICATION',
  'CREATE_NOTIFICATION',
  'UPDATE_NOTIFICATION',
  'DELETE_NOTIFICATION',
  'LOCK_NOTIFICATION',
  'LIST_MANUAL_NOTIFICATION',
  'CREATE_MANUAL_NOTIFICATION',
  'REGULAR_EVENT_REPORT',
  'SYSTEM_EVENT_REPORT',
  'RANKING_REPORT',
  'AFFILIATE_REPORT',
  'AFFILIATE_REFERRAL_REPORT',
  'STUDENT_REPORT',
  'DETAIL_REPORT',
  'LIST_ADMIN',
  'CREATE_ADMIN',
  'UPDATE_ADMIN',
  'DELETE_ADMIN',
  'LOCK_ADMIN',
  'PERMISSION_ADMIN',
  'BANK_ACCOUNT',
  'ICON_SETTINGS',
  'ABOUT_US',
  'FAQ',
  'STATIC_CONTENT'
] as const;

export type PermissionValue = (typeof ALL_PERMISSIONS)[number];

export type ActionValue = PermissionValue | PermissionValue[];

export interface AuthorizationAction {
  read?: ActionValue;
  create?: ActionValue;
  delete?: ActionValue;
  update?: ActionValue;
  lock?: ActionValue;
}

export interface AuthorizationFunction {
  name: string;
  action: AuthorizationAction;
}

export interface Authorization {
  group: string;
  functions: AuthorizationFunction[];
}

export const ADMIN_PERMISSIONS: Authorization[] = [
  {
    group: 'Quản lý người dùng',
    functions: [
      {
        name: 'Thông tin cá nhân',
        action: {
          read: 'VIEW_USER',
          update: 'UPDATE_USER'
        }
      },
      {
        name: 'Chia sẻ mã giới thiệu',
        action: {
          read: 'SHARE_AFFILIATE_CODE'
        }
      },
      {
        name: 'Thống kê người được chia sẻ mã giới thiệu',
        action: {
          read: 'VIEW_AFFILIATE_STATISTICS'
        }
      },
      {
        name: 'Danh sách học viên',
        action: {
          read: 'LIST_USER',
          create: 'CREATE_USER',
          delete: 'DELETE_USERS'
        }
      },
      {
        name: 'Chi tiết học viên',
        action: {
          read: 'VIEW_STUDENT_DETAIL',
          lock: 'LOCK_USER'
        }
      },
      {
        name: 'Mục tiêu học tập của HV',
        action: {
          read: 'VIEW_STUDENT_STUDY_GOALS'
        }
      },
      {
        name: 'Báo cáo kết quả học tập của HV',
        action: {
          read: 'VIEW_STUDENT_REPORT'
        }
      },
      {
        name: 'Danh sách phụ huynh',
        action: {
          read: 'LIST_USER',
          create: 'CREATE_USER',
          delete: 'DELETE_USERS'
        }
      },
      {
        name: 'Chi tiết phụ huynh',
        action: {
          read: 'LIST_USER'
        }
      }
    ]
  },
  {
    group: 'Quản lý kiến thức',
    functions: [
      {
        name: 'Danh sách kiến thức',
        action: {
          read: 'LIST_KNOWLEDGE'
        }
      },
      {
        name: 'Chi tiết kiến thức',
        action: {
          read: 'VIEW_KNOWLEDGE',
          create: 'CREATE_KNOWLEDGE',
          delete: 'DELETE_KNOWLEDGE',
          lock: 'LOCK_KNOWLEDGE',
          update: 'UPDATE_KNOWLEDGE'
        }
      },
      {
        name: 'Chuyên đề',
        action: {
          read: 'LIST_THEMATIC',
          create: 'CREATE_THEMATIC',
          delete: 'DELETE_THEMATIC',
          lock: 'LOCK_THEMATIC',
          update: 'UPDATE_THEMATIC'
        }
      },
      {
        name: 'Lộ trình phù hợp',
        action: {
          read: 'LIST_STUDY_PLAN',
          create: 'CREATE_STUDY_PLAN',
          delete: 'DELETE_STUDY_PLAN',
          lock: 'LOCK_STUDY_PLAN',
          update: 'UPDATE_STUDY_PLAN'
        }
      }
    ]
  },
  {
    group: 'Quản lý nội dung thực hành',
    functions: [
      {
        name: 'Danh sách bài thực hành',
        action: {
          read: 'LIST_PRACTICE'
        }
      },
      {
        name: 'Chi tiết bài thực hành',
        action: {
          read: 'VIEW_PRACTICE',
          create: 'CREATE_PRACTICE',
          delete: 'DELETE_PRACTICE',
          lock: 'LOCK_PRACTICE',
          update: 'UPDATE_PRACTICE'
        }
      }
    ]
  },
  {
    group: 'Quản lý ngân hàng đề thi',
    functions: [
      {
        name: 'Danh sách đề thi',
        action: {
          read: 'LIST_TEST'
        }
      },
      {
        name: 'Chi tiết đề thi',
        action: {
          read: 'VIEW_TEST',
          create: 'CREATE_TEST',
          delete: 'DELETE_TEST',
          lock: 'LOCK_TEST',
          update: 'UPDATE_TEST'
        }
      }
    ]
  },
  {
    group: 'Quản lý sự kiện định kỳ',
    functions: [
      {
        name: 'Danh sách lịch thi',
        action: {
          read: 'LIST_TEST_SCHEDULE'
        }
      },
      {
        name: 'Chi tiết lịch thi',
        action: {
          read: 'VIEW_TEST_SCHEDULE',
          create: 'CREATE_TEST_SCHEDULE',
          delete: 'DELETE_TEST_SCHEDULE',
          lock: 'LOCK_TEST_SCHEDULE',
          update: 'UPDATE_TEST_SCHEDULE'
        }
      }
    ]
  },
  {
    group: 'Quản lý sự kiện hệ thống',
    functions: [
      {
        name: 'Danh sách sự kiện hệ thống',
        action: {
          read: 'LIST_EVENT'
        }
      },
      {
        name: 'Chi tiết sự kiện hệ thống',
        action: {
          read: 'VIEW_EVENT',
          create: 'CREATE_EVENT',
          delete: 'DELETE_EVENT',
          lock: 'LOCK_EVENT',
          update: 'UPDATE_EVENT'
        }
      }
    ]
  },
  {
    group: 'Quản lý gói học tập',
    functions: [
      {
        name: 'Danh sách gói học tập',
        action: {
          read: 'LIST_LEARN'
        }
      },
      {
        name: 'Chi tiết gói học tập',
        action: {
          read: 'VIEW_LEARN',
          create: 'CREATE_LEARN',
          delete: 'DELETE_LEARN',
          lock: 'LOCK_LEARN',
          update: 'UPDATE_LEARN'
        }
      }
    ]
  },
  {
    group: 'Quản lý đơn hàng',
    functions: [
      {
        name: 'Danh sách đơn hàng',
        action: {
          read: 'LIST_ORDER'
        }
      },
      {
        name: 'Chi tiết đơn hàng',
        action: {
          read: 'VIEW_ORDER'
        }
      }
    ]
  },
  {
    group: 'Quản lý Loyalty',
    functions: [
      {
        name: 'Danh sách mã khuyến mãi',
        action: {
          read: 'LIST_PROMOTION'
        }
      },
      {
        name: 'Mã khuyến mãi',
        action: {
          create: 'CREATE_PROMOTION',
          delete: 'DELETE_PROMOTION',
          lock: 'LOCK_PROMOTION',
          update: 'UPDATE_PROMOTION'
        }
      },
      {
        name: 'Cấu hình tích điểm',
        action: {
          read: 'CONFIG_PROMOTION',
          create: 'CONFIG_PROMOTION'
        }
      }
    ]
  },
  {
    group: 'Quản lý quà tặng',
    functions: [
      {
        name: 'Danh sách quà tặng',
        action: {
          read: 'LIST_GIFT'
        }
      },
      {
        name: 'Chi tiết quà tặng',
        action: {
          create: 'CREATE_GIFT',
          delete: 'DELETE_GIFT',
          lock: 'LOCK_GIFT',
          update: 'UPDATE_GIFT'
        }
      },
      {
        name: 'Danh sách yêu cầu quà tặng',
        action: {
          read: 'REQUEST_GIFT'
        }
      }
    ]
  },
  {
    group: 'Quản lý thông báo',
    functions: [
      {
        name: 'Thông báo tự động',
        action: {
          read: ['VIEW_NOTIFICATION', 'LIST_NOTIFICATION'],
          create: 'CREATE_NOTIFICATION',
          update: 'UPDATE_NOTIFICATION',
          delete: 'DELETE_NOTIFICATION',
          lock: 'LOCK_NOTIFICATION'
        }
      },
      {
        name: 'Thông báo thủ công',
        action: {
          read: 'LIST_MANUAL_NOTIFICATION',
          create: 'CREATE_MANUAL_NOTIFICATION'
        }
      }
    ]
  },
  {
    group: 'Báo cáo - Thống kê',
    functions: [
      {
        name: 'Kết quả sự kiện định kỳ',
        action: {
          read: 'REGULAR_EVENT_REPORT'
        }
      },
      {
        name: 'Kết quả sự kiện hệ thống',
        action: {
          read: 'SYSTEM_EVENT_REPORT'
        }
      },
      {
        name: 'Bảng xếp hạng',
        action: {
          read: 'RANKING_REPORT'
        }
      },
      {
        name: 'Affiliate (Danh sách người được chia sẻ)',
        action: {
          read: 'AFFILIATE_REPORT'
        }
      },
      {
        name: 'Danh sách học viên',
        action: {
          read: 'STUDENT_REPORT'
        }
      },
      {
        name: 'Chi tiết thống kê học viên',
        action: {
          read: 'DETAIL_REPORT'
        }
      },
      {
        name: 'Affiliate Referral (Nguồn giới thiệu)',
        action: {
          read: 'AFFILIATE_REFERRAL_REPORT'
        }
      }
    ]
  },
  {
    group: 'Quản lý người dùng quản trị viên',
    functions: [
      {
        name: 'Danh sách tài khoản',
        action: {
          read: 'LIST_ADMIN'
        }
      },
      {
        name: 'Chi tiết tài khoản',
        action: {
          create: 'CREATE_ADMIN',
          update: 'UPDATE_ADMIN',
          delete: 'DELETE_ADMIN',
          lock: 'LOCK_ADMIN'
        }
      },
      {
        name: 'Phân quyền',
        action: {
          update: 'PERMISSION_ADMIN'
        }
      }
    ]
  },
  {
    group: 'Cấu hình khác',
    functions: [
      {
        name: 'Tài khoản ngân hàng',
        action: {
          read: 'BANK_ACCOUNT',
          create: 'BANK_ACCOUNT',
          delete: 'BANK_ACCOUNT',
          lock: 'BANK_ACCOUNT',
          update: 'BANK_ACCOUNT'
        }
      },
      {
        name: 'Cài đặt biểu tượng mực',
        action: {
          read: 'ICON_SETTINGS',
          update: 'ICON_SETTINGS'
        }
      },
      {
        name: 'Về chúng tôi',
        action: {
          read: 'ABOUT_US',
          update: 'ABOUT_US'
        }
      },
      {
        name: 'Câu hỏi thường gặp',
        action: {
          read: 'FAQ',
          create: 'FAQ',
          delete: 'FAQ',
          update: 'FAQ'
        }
      },
      {
        name: 'Bài viết tĩnh',
        action: {
          read: 'STATIC_CONTENT',
          update: 'STATIC_CONTENT'
        }
      }
    ]
  }
];

export const VIEW_USER = 'VIEW_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const SHARE_AFFILIATE_CODE = 'SHARE_AFFILIATE_CODE';
export const VIEW_AFFILIATE_STATISTICS = 'VIEW_AFFILIATE_STATISTICS';
export const DELETE_USERS = 'DELETE_USERS';
export const CREATE_USER = 'CREATE_USER';
export const VIEW_STUDENT_REPORT = 'VIEW_STUDENT_REPORT';
export const VIEW_STUDENT_STUDY_GOALS = 'VIEW_STUDENT_STUDY_GOALS';
export const VIEW_STUDENT_DETAIL = 'VIEW_STUDENT_DETAIL';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const LOCK_USER = 'LOCK_USER';
export const LIST_USER = 'LIST_USER';
export const DELETE_STUDY_PLAN = 'DELETE_STUDY_PLAN';
export const LOCK_STUDY_PLAN = 'LOCK_STUDY_PLAN';
export const UPDATE_STUDY_PLAN = 'UPDATE_STUDY_PLAN';
export const CREATE_STUDY_PLAN = 'CREATE_STUDY_PLAN';
export const LIST_STUDY_PLAN = 'LIST_STUDY_PLAN';
export const DELETE_THEMATIC = 'DELETE_THEMATIC';
export const LOCK_THEMATIC = 'LOCK_THEMATIC';
export const UPDATE_THEMATIC = 'UPDATE_THEMATIC';
export const CREATE_THEMATIC = 'CREATE_THEMATIC';
export const LIST_THEMATIC = 'LIST_THEMATIC';
export const DELETE_KNOWLEDGE = 'DELETE_KNOWLEDGE';
export const LOCK_KNOWLEDGE = 'LOCK_KNOWLEDGE';
export const UPDATE_KNOWLEDGE = 'UPDATE_KNOWLEDGE';
export const CREATE_KNOWLEDGE = 'CREATE_KNOWLEDGE';
export const VIEW_KNOWLEDGE = 'VIEW_KNOWLEDGE';
export const LIST_KNOWLEDGE = 'LIST_KNOWLEDGE';
export const DELETE_PRACTICE = 'DELETE_PRACTICE';
export const LOCK_PRACTICE = 'LOCK_PRACTICE';
export const UPDATE_PRACTICE = 'UPDATE_PRACTICE';
export const CREATE_PRACTICE = 'CREATE_PRACTICE';
export const VIEW_PRACTICE = 'VIEW_PRACTICE';
export const LIST_PRACTICE = 'LIST_PRACTICE';
export const DELETE_TEST = 'DELETE_TEST';
export const LOCK_TEST = 'LOCK_TEST';
export const UPDATE_TEST = 'UPDATE_TEST';
export const CREATE_TEST = 'CREATE_TEST';
export const VIEW_TEST = 'VIEW_TEST';
export const LIST_TEST = 'LIST_TEST';
export const DELETE_TEST_SCHEDULE = 'DELETE_TEST_SCHEDULE';
export const LOCK_TEST_SCHEDULE = 'LOCK_TEST_SCHEDULE';
export const UPDATE_TEST_SCHEDULE = 'UPDATE_TEST_SCHEDULE';
export const CREATE_TEST_SCHEDULE = 'CREATE_TEST_SCHEDULE';
export const VIEW_TEST_SCHEDULE = 'VIEW_TEST_SCHEDULE';
export const LIST_TEST_SCHEDULE = 'LIST_TEST_SCHEDULE';
export const DELETE_EVENT = 'DELETE_EVENT';
export const LOCK_EVENT = 'LOCK_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const CREATE_EVENT = 'CREATE_EVENT';
export const VIEW_EVENT = 'VIEW_EVENT';
export const LIST_EVENT = 'LIST_EVENT';
export const DELETE_LEARN = 'DELETE_LEARN';
export const LOCK_LEARN = 'LOCK_LEARN';
export const UPDATE_LEARN = 'UPDATE_LEARN';
export const CREATE_LEARN = 'CREATE_LEARN';
export const VIEW_LEARN = 'VIEW_LEARN';
export const LIST_LEARN = 'LIST_LEARN';
export const VIEW_ORDER = 'VIEW_ORDER';
export const LIST_ORDER = 'LIST_ORDER';
export const CONFIG_PROMOTION = 'CONFIG_PROMOTION';
export const LOCK_PROMOTION = 'LOCK_PROMOTION';
export const DELETE_PROMOTION = 'DELETE_PROMOTION';
export const UPDATE_PROMOTION = 'UPDATE_PROMOTION';
export const CREATE_PROMOTION = 'CREATE_PROMOTION';
export const LIST_PROMOTION = 'LIST_PROMOTION';
export const REQUEST_GIFT = 'REQUEST_GIFT';
export const LOCK_GIFT = 'LOCK_GIFT';
export const DELETE_GIFT = 'DELETE_GIFT';
export const UPDATE_GIFT = 'UPDATE_GIFT';
export const CREATE_GIFT = 'CREATE_GIFT';
export const LIST_GIFT = 'LIST_GIFT';
export const LOCK_NOTIFICATION = 'LOCK_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const VIEW_NOTIFICATION = 'VIEW_NOTIFICATION';
export const LIST_NOTIFICATION = 'LIST_NOTIFICATION';
export const CREATE_MANUAL_NOTIFICATION = 'CREATE_MANUAL_NOTIFICATION';
export const LIST_MANUAL_NOTIFICATION = 'LIST_MANUAL_NOTIFICATION';
export const DETAIL_REPORT = 'DETAIL_REPORT';
export const STUDENT_REPORT = 'STUDENT_REPORT';
export const  AFFILIATE_REPORT= 'AFFILIATE_REPORT';
export const AFFILIATE_REFERRAL_REPORT = 'AFFILIATE_REFERRAL_REPORT'
export const RANKING_REPORT = 'RANKING_REPORT';
export const SYSTEM_EVENT_REPORT = 'SYSTEM_EVENT_REPORT';
export const REGULAR_EVENT_REPORT = 'REGULAR_EVENT_REPORT';
export const PERMISSION_ADMIN = 'PERMISSION_ADMIN';
export const LOCK_ADMIN = 'LOCK_ADMIN';
export const DELETE_ADMIN = 'DELETE_ADMIN';
export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const CREATE_ADMIN = 'CREATE_ADMIN';
export const LIST_ADMIN = 'LIST_ADMIN';
export const BANK_ACCOUNT = 'BANK_ACCOUNT';
export const STATIC_CONTENT = 'STATIC_CONTENT';
export const FAQ = 'FAQ';
export const ABOUT_US = 'ABOUT_US';
export const ICON_SETTINGS = 'ICON_SETTINGS';
