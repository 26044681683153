import * as CSS from 'csstype';
import { Form } from 'antd';
import { get, isEmpty } from 'lodash';
import React, { ReactNode } from 'react';
import { Controller } from 'react-hook-form';
import { filterEmoji } from '@common/utils';

export interface FieldHandler {
  onChange?: (value: any) => void;
  onBlur?: () => any;
  children?: ReactNode[];
}

export interface UIFieldProps<T> {
  label?: any;
  fieldComponent: new (props: T) => React.Component;
  fieldProps?: T;
  name: string;
  defaultValue?: any;
  checkbox?: boolean;
  style?: CSS.Properties<string | number>;
}

const UIField = <T extends unknown>(props: FormContextValues & UIFieldProps<T & FieldHandler>) => {
  const {
    name,
    formState: { errors },
    fieldComponent: FieldComponent,
    label,
    style,
    clearErrors,
    control,
    options,
    placeholder = 'Nhập Nội Dung',
    trigger,
    checkbox,
    fieldProps,
    onChange: onChangeProps,
    disabled
  } = props;

  const errorStatus = !isEmpty(get(errors, name)) ? 'error' : 'success';
  const errorMessage = !isEmpty(errors) && get(errors, `[${name}].message`, '');

  const onChange = async (e: any, field: any) => {
    const value = checkbox ? get(e, 'target.checked', e) : get(e, 'target.value', e);
    // setValue(name, fieldProps?.value);
    await field.onChange(typeof value === 'string' ? filterEmoji(value) : value);
    if (typeof onChangeProps === 'function') {
      onChangeProps(value);
    }
    clearErrors(name);
  };

  return (
    <Form.Item label={label} validateStatus={errorStatus} help={errorMessage} style={style}>
      {checkbox ? (
        <Controller
          render={({ field }) => (
            <FieldComponent
              {...field}
              onChange={(e: any) => onChange(e, field)}
              onBlur={async () => {
                await trigger(name);
                field.onBlur();
              }}
              checked={field.value}
              placeholder={placeholder}
              type="checkbox"
              disabled={disabled}
            />
          )}
          name={name}
          control={control}
        />
      ) : (
        <Controller
          render={({ field }) => (
            <FieldComponent
              {...field}
              onChange={(e: any) => onChange(e, field)}
              onBlur={async () => {
                await trigger(name);
                field.onBlur();
              }}
              placeholder={placeholder}
              options={options}
              disabled={disabled}
              name={name}
              {...fieldProps}
            />
          )}
          name={name}
          control={control}
        />
      )}
    </Form.Item>
  );
};

export default UIField;
