/* eslint-disable camelcase */
import Table from '@common/components/Table/Table';
import { useDeepCompareEffect } from '@layout/hooks';
import { Typography } from 'antd';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import ActionRenderer from '../CellRenderer/ActionRenderer';
import { _lockCommonRequest, deleteCommonRequest } from '@reducers/actions/CommonOptionThunk';
import { useNavigate } from 'react-router-dom';
import { dateFormat, formatDate, hasPermission } from '@common/utils';

const { Title } = Typography;

const CommonTable = memo(
  ({
    title,
    filterComponent: FilterComponent,
    columns,
    tableData,
    loading,
    getListRequest,
    apiParamInfos,
    btnComponent,
    viewRole,
    editRole,
    deleteRole,
    lockRole,
    viewListRole,
    actionRenderer,
    hasViewDetail,
    hasEdit,
    hasDelete,
    hasLock,
    hasAction,
    reqParams
  }) => {
    const navigate = useNavigate();
    const { data, meta } = tableData;
    const dispatch = useDispatch();

    const [search, setSearch] = useState('');
    const [filters, setFilters] = useState({});
    const [currPage, setCurrPage] = useState(1);
    const [sortedInfo, setSortedInfo] = useState({
      columnKey: 'created_at',
      order: 'desc'
    });

    const handleDelete = (id) => {
      deleteCommonRequest({ id, ...apiParamInfos }, getLists);
    };

    const handleCreateUpdateItem = (id: string) => {
      if (id) {
        navigate(`/${apiParamInfos.entity}/:id/update`.replace(':id', id));
        return;
      }
      navigate(`/${apiParamInfos.entity}/new`);
    };

    const handleLock = (id: string, is_locked: boolean) => {
      _lockCommonRequest({ id, is_locked, ...apiParamInfos }, getLists);
    };

    const handleViewDetail = (id: string) => navigate(id);

    let commonColumns = [...columns];

    if (hasAction) {
      if (!actionRenderer) {
        commonColumns.push({
          title: 'Thao Tác',
          render: (record) => (
            <ActionRenderer
              record={record}
              handleDelete={handleDelete}
              handleEdit={handleCreateUpdateItem}
              handleViewDetail={handleViewDetail}
              handleLock={handleLock}
              viewRole={viewRole}
              editRole={editRole}
              deleteRole={deleteRole}
              lockRole={lockRole}
              hasViewDetail={hasViewDetail}
              hasEdit={hasEdit}
              hasDelete={hasDelete}
              hasLock={hasLock}
            />
          )
        });
      } else {
        commonColumns.push({
          title: 'Thao Tác',
          render: (record: any) => <actionRenderer getLists={getLists} record={record} />
        });
      }
    }

    const handleSearch = debounce((e) => {
      const val = e.target.value;
      setSearch(val);
    }, 1000);

    const handleChangeFilter = (val: string, filterType: string) => {
      setFilters((prev) => ({ ...prev, [filterType]: val }));
    };

    const handleChangeDate = (date) => {
      setFilters((prev) => ({
        ...prev,
        created_from: date?.[0] ? formatDate(date[0], dateFormat) : '',
        created_to: date?.[1] ? formatDate(date[1], dateFormat) : ''
      }));
    };

    const getLists = () => {
      const params = {
        ...reqParams,
        ...filters,
        page: currPage - 1,
        search,
        size: 10,
        sort: sortedInfo.columnKey,
        direction: sortedInfo?.order && sortedInfo.order
      };

      if (typeof getListRequest === 'function') {
        dispatch(getListRequest(params) as any);
      }
    };

    useDeepCompareEffect(() => {
      getLists();
    }, [filters, currPage, sortedInfo, search]);

    return (
      <div style={{ backgroundColor: '#fff', padding: 16 }}>
        {btnComponent && btnComponent}
        {hasPermission(viewListRole) && (
          <>
            {FilterComponent && (
              <>
                <Title level={4}>ĐIỀU KIỆN TÌM KIẾM</Title>
                <FilterComponent
                  handleChangeDate={handleChangeDate}
                  handleChangeFilter={handleChangeFilter}
                  handleSearch={handleSearch}
                  filters={filters}
                />

                <Title level={4}>KẾT QUẢ TÌM KIẾM</Title>
              </>
            )}
            <Table
              data={data}
              bordered
              columns={commonColumns}
              loading={loading}
              meta={meta}
              currentPage={currPage}
              setCurrPage={setCurrPage}
              sortedInfo={sortedInfo}
              setSortedInfo={setSortedInfo}
            />
          </>
        )}
      </div>
    );
  }
);

CommonTable.propTypes = {};

export default CommonTable;
