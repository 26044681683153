import { ApiEndpoint } from "@api/constants";
import {
    TKnowledgeCreateRequest,
    TKnowledgeDeleteResponse,
    TKnowledgeListRequest,
    TKnowledgeListResponse,
    TKnowledgeResponse,
    TKnowledgeUpdateRequest,
} from "@api/interfaces";
import axiosInstance from "@api/services/axios";
import { API_DOMAIN } from "@common/constants/Environment";
import { AxiosResponse } from "axios";

class KnowledgeApi {
    public static list = (request: TKnowledgeListRequest): Promise<AxiosResponse<TKnowledgeListResponse>> => {
        const url = new URL(ApiEndpoint.KNOWLEDGE, API_DOMAIN);
        url.searchParams.set("page", `${request.pageIndex}`);
        url.searchParams.set("size", `${request.pageSize}`);
        url.searchParams.set("filters", JSON.stringify(request.filters ?? []));
        url.searchParams.set("globalFilter", request.globalFilter ?? "");
        url.searchParams.set("sorting", JSON.stringify(request.sorting ?? []));

        // @ts-ignore
        return axiosInstance.get(
            url.toString(),
        ).catch(error => error.handleGlobally && error.handleGlobally());
    };

    public static create = (request: FormData | TKnowledgeCreateRequest): Promise<AxiosResponse<TKnowledgeResponse>> => {
        return axiosInstance.post(
            ApiEndpoint.KNOWLEDGE,
            request,
            {
                headers: {
                    Accept: "application/json",
                },
                maxRedirects: 0,
            },
        ).catch(error => error.handleGlobally && error.handleGlobally());
    };

    public static update = (id: number, request: FormData | TKnowledgeUpdateRequest): Promise<AxiosResponse<TKnowledgeResponse>> =>
        axiosInstance.patch(
            `${API_DOMAIN}/${ApiEndpoint.KNOWLEDGE}/${id}`,
            request,
            {
                headers: {
                    Accept: "application/json",
                },
                maxRedirects: 0,
            },
        ).catch(error => error.handleGlobally && error.handleGlobally());

    public static get = (id: number): Promise<AxiosResponse<TKnowledgeResponse>> => axiosInstance.get(
        `${API_DOMAIN}/${ApiEndpoint.KNOWLEDGE}/${id}`,
        {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            maxRedirects: 0,
        },
    ).catch(error => error.handleGlobally && error.handleGlobally());

    public static delete = (id: number | string): Promise<AxiosResponse<TKnowledgeDeleteResponse>> =>
        axiosInstance.delete(
            `${API_DOMAIN}/${ApiEndpoint.KNOWLEDGE}/${id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                maxRedirects: 0,
            },
        ).catch(error => error.handleGlobally && error.handleGlobally());
}

export default KnowledgeApi;
