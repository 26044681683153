import { Col, Row, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Button from '../Button/Button';

interface FooterProps {
  onCancel: () => void;
  onSubmit: () => void;
  disableSubmit?: boolean;
}

const Footer = memo(({ onCancel, onSubmit, disableSubmit }: FooterProps) => (
  <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
    <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end', gap: 16 }}>
      <Button danger onClick={onCancel}>
        Hủy
      </Button>
      <Button type="primary" onClick={onSubmit} disabled={disableSubmit}>
        Lưu
      </Button>
    </Col>
  </Row>
));

export default Footer;
