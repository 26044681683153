import { getStorage } from '@api/services/services';
import { AppRoutes } from '@common/constants';
import useAxiosInterceptor from '@common/hooks/useAxiosInterceptor';
import { isTokenExpired } from '@common/utils';
import axios from 'axios';
import { Location } from 'history';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface IPrivateRouteProps {
  children: React.FunctionComponent;
}

const ProtectedRoute: React.FunctionComponent<IPrivateRouteProps> = (props) => {
  // useAxiosInterceptor(axios, navigate);
  const { expired } = getStorage('accessToken') && JSON.parse(getStorage('accessToken'));
  const location: Location = useLocation();

  return (
    <>
      {isTokenExpired(expired) ? (
        <Navigate to={AppRoutes.LOGIN} state={{ from: location }} replace={true} />
      ) : (
        props.children
      )}
    </>
  );
};

export default ProtectedRoute;
