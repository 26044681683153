import { ApiEndpoint } from '@api/constants';
import { apiGet } from '@api/services/services';
import { ErrorNoti } from '@common/components/Notification/Notification';

export enum OrderConstant {
  ADD_ORDER_REQUEST = 'ADD_ORDER_REQUEST',
  ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS',
  GET_LIST_ORDER_REQUEST = 'GET_LIST_ORDER_REQUEST',
  GET_LIST_ORDER_SUCCESS = 'GET_LIST_ORDER_SUCCESS'
}

export const getOrderRequest = (params) => async (dispatch) => {
  try {
    dispatch({ type: OrderConstant.GET_LIST_ORDER_REQUEST });

    const { data } = await apiGet({
      path: ApiEndpoint.ORDERS,
      body: params
    });

    dispatch(getOrderSuccess(data));
  } catch (error) {
    ErrorNoti(error.response.data.message);
  }
};

export const getOrderSuccess = (payload) => ({
  type: OrderConstant.GET_LIST_ORDER_SUCCESS,
  payload
});
