import { ApiEndpoint } from '@api/constants';
import {
  ApiParentCreateRequest,
  ApiParentListRequest,
  ApiParentRequest,
  ApiParentUpdateRequest,
  TAncestorDeleteResponse,
  TAncestorOptionsResponse,
  TAncestorResponse,
  TAncestorsResponse
} from '@api/interfaces';
import axiosInstance from '@api/services/axios';
import { API_DOMAIN } from '@common/constants/Environment';
import { AxiosResponse } from 'axios';

class AncestorApi {
  public static list = (
    request: ApiParentListRequest
  ): Promise<AxiosResponse<TAncestorsResponse>> => {
    const url = new URL(ApiEndpoint.ANCESTOR_LIST, API_DOMAIN);
    url.searchParams.set('page', `${request.pageIndex}`);
    url.searchParams.set('size', `${request.pageSize}`);
    url.searchParams.set('filters', JSON.stringify(request.filters ?? []));
    url.searchParams.set('globalFilter', request.globalFilter ?? '');
    url.searchParams.set('sorting', JSON.stringify(request.sorting ?? []));

    // @ts-ignore
    return axiosInstance
      .get(url.toString(), {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
      .catch((error) => error.handleGlobally && error.handleGlobally());
  };

  public static create = (
    request: ApiParentCreateRequest
  ): Promise<AxiosResponse<TAncestorResponse>> =>
    axiosInstance
      .post(`${API_DOMAIN}/${ApiEndpoint.ANCESTOR_CREATE}`, request, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        maxRedirects: 0
      })
      .catch((error) => error.handleGlobally && error.handleGlobally());

  public static update = (
    id: number,
    request: ApiParentUpdateRequest
  ): Promise<AxiosResponse<TAncestorResponse>> =>
    axiosInstance
      .patch(`${API_DOMAIN}/${ApiEndpoint.ANCESTOR_UPDATE}/${id}`, request, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        maxRedirects: 0
      })
      .catch((error) => error.handleGlobally && error.handleGlobally());

  public static get = (request: ApiParentRequest): Promise<AxiosResponse<TAncestorResponse>> =>
    axiosInstance
      .get(`${API_DOMAIN}/${ApiEndpoint.ANCESTOR_DETAIL}/${request.id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        maxRedirects: 0
      })
      .catch((error) => error.handleGlobally && error.handleGlobally());

  public static delete = (id: number | string): Promise<AxiosResponse<TAncestorDeleteResponse>> =>
    axiosInstance
      .delete(`${API_DOMAIN}/${ApiEndpoint.ANCESTOR_DELETE}/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        maxRedirects: 0
      })
      .catch((error) => error.handleGlobally && error.handleGlobally());

  public static options = (request): Promise<AxiosResponse<TAncestorOptionsResponse>> => {
    const url = new URL(ApiEndpoint.OPTIONS_ANCESTOR, API_DOMAIN);
    url.searchParams.set('page', `${request.pageIndex}`);
    url.searchParams.set('size', `${request.pageSize}`);
    url.searchParams.set('filters', JSON.stringify(request.filters ?? []));
    url.searchParams.set('globalFilter', request.globalFilter ?? '');
    url.searchParams.set('sorting', JSON.stringify(request.sorting ?? []));

    return axiosInstance
      .get(url.toString(), {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        maxRedirects: 0
      })
      .catch((error) => error.handleGlobally && error.handleGlobally());
  };
}

export default AncestorApi;
