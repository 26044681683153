import { ApiEndpoint } from '@api/constants';
import { TSubjectOptionsResponse } from '@api/interfaces';
import {
  TSubjectCreateRequest,
  TSubjectDeleteResponse,
  TSubjectResponse,
  TSubjectsRequest,
  TSubjectsResponse,
  TSubjectUpdateRequest
} from '@api/interfaces/subject';
import axiosInstance from '@api/services/axios';
import { API_DOMAIN } from '@common/constants';
import { AxiosResponse } from 'axios';

class SubjectApi {
  public static options = (request): Promise<AxiosResponse<TSubjectOptionsResponse>> => {
    const url = new URL(ApiEndpoint.OPTIONS_SUBJECT, API_DOMAIN);
    url.searchParams.set('page', `${request.pageIndex}`);
    url.searchParams.set('size', `${request.pageSize}`);
    url.searchParams.set('filters', JSON.stringify(request.filters ?? []));
    url.searchParams.set('globalFilter', request.globalFilter ?? '');
    url.searchParams.set('sorting', JSON.stringify(request.sorting ?? []));

    return axiosInstance
      .get(url.toString(), {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        maxRedirects: 0
      })
      .catch((error) => error.handleGlobally && error.handleGlobally());
  };

  public static list = (request: TSubjectsRequest): Promise<AxiosResponse<TSubjectsResponse>> => {
    const url = new URL(ApiEndpoint.SUBJECT, API_DOMAIN);
    url.searchParams.set('page', `${request.pageIndex}`);
    url.searchParams.set('size', `${request.pageSize}`);
    url.searchParams.set('filters', JSON.stringify(request.filters ?? []));
    url.searchParams.set('globalFilter', request.globalFilter ?? '');
    url.searchParams.set('sorting', JSON.stringify(request.sorting ?? []));

    // @ts-ignore
    return axiosInstance
      .get(url.toString(), {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
      .catch((error) => error.handleGlobally && error.handleGlobally());
  };

  public static delete = (id: number | string): Promise<AxiosResponse<TSubjectDeleteResponse>> =>
    axiosInstance
      .delete(`${API_DOMAIN}/${ApiEndpoint.SUBJECT}/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        maxRedirects: 0
      })
      .catch((error) => error.handleGlobally && error.handleGlobally());

  public static get = (id: number): Promise<AxiosResponse<TSubjectResponse>> =>
    axiosInstance
      .get(`${API_DOMAIN}/${ApiEndpoint.SUBJECT}/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        maxRedirects: 0
      })
      .catch((error) => error.handleGlobally && error.handleGlobally());

  public static update = (
    id: number,
    request: TSubjectUpdateRequest
  ): Promise<AxiosResponse<TSubjectResponse>> =>
    axiosInstance
      .patch(`${API_DOMAIN}/${ApiEndpoint.SUBJECT}/${id}`, request, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        maxRedirects: 0
      })
      .catch((error) => error.handleGlobally && error.handleGlobally());

  public static create = (
    request: TSubjectCreateRequest
  ): Promise<AxiosResponse<TSubjectResponse>> =>
    axiosInstance
      .post(`${API_DOMAIN}/${ApiEndpoint.SUBJECT}`, request, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        maxRedirects: 0
      })
      .catch((error) => error.handleGlobally && error.handleGlobally());
}

export default SubjectApi;
