import Loading from '@common/components/loading/Loading';
import Routes from '@layout/components/Routes/Routes';
import React, { Suspense } from 'react';
import { HashRouter } from 'react-router-dom';
import 'simplebar-react/dist/simplebar.min.css';

import './scss/style.scss';

const App: React.FunctionComponent = () => (
  <HashRouter>
    <Routes />
  </HashRouter>
);

export default App;
