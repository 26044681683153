/* eslint-disable camelcase */
import Table from '@common/components/Table/Table';
import PropTypes from 'prop-types';
import { DeleteOutlined, EditOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import React, { memo, useMemo, useState } from 'react';
import Button from '@common/components/Button/Button';
import { Col, DatePicker, Form, Input, Popconfirm, Radio, Row, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Select from '@common/components/Select/Select';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, pickBy } from 'lodash';
import { deleteCommonRequest, _lockCommonRequest } from '@reducers/actions/CommonOptionThunk';
import { dateFormat, formatDate, hasPermission, mapReduce } from '@common/utils';
import { LOYALTY_STATUS } from '@common/constants/constantType';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '@common/constants';
import { selectGiftList } from '@reducers/selectors/gift';
import { useDeepCompareEffect } from '@layout/hooks';
import { getGiftRequest } from '@reducers/actions/GiftReducerThunk';
import ImageWithPreview from '@common/components/ImageWithPreview/ImageWithPreview';
import {
  CREATE_GIFT,
  DELETE_GIFT,
  LIST_GIFT,
  LOCK_GIFT,
  UPDATE_GIFT
} from '@common/constants/permission';
import ListChangeGift from './ListChangeGift';

const { Title } = Typography;

const apiParamInfos = { entity: 'gifts', message: 'Quà Tặng' };

const mapStatus = mapReduce(LOYALTY_STATUS, 'value', (item) => item);
const { RangePicker } = DatePicker;

const ListGiftScreen = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    data: { data, meta },
    loading
  } = useSelector(selectGiftList());

  const [activeTab, setActiveTab] = useState('gift');
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: 'created_at',
    order: 'desc'
  });

  const getLists = () => {
    const params = {
      ...filters,
      page: currPage - 1,
      search,
      size: 10,
      sort: sortedInfo.columnKey,
      direction: sortedInfo?.order && sortedInfo.order
    };

    dispatch(getGiftRequest(params) as any);
  };

  useDeepCompareEffect(() => {
    getLists();
  }, [filters, currPage, sortedInfo, search]);

  const columns: ColumnsType = useMemo(
    () => [
      {
        title: 'STT',
        render: (_, record, index: number) => String(meta?.from + index)
      },
      {
        title: 'Ngày Tạo',
        dataIndex: 'created_at',
        key: 'created_at',
        sortable: true
      },
      {
        title: 'Tên Món Quà',
        dataIndex: 'name',
        key: 'name',
        sortable: true
      },
      {
        title: 'Hình Ảnh Minh Họa',
        dataIndex: 'image',
        key: 'image',
        render: (imageUrl) => (
          <ImageWithPreview src={imageUrl} title="Hình Ảnh Minh Họa" width="100px" />
        )
      },
      {
        title: 'Thời Gian Áp Dụng',
        render: (record) => `${record.start_at} - ${record.end_at}`
      },
      {
        title: 'Số Điểm Cần Đổi',
        dataIndex: 'point',
        key: 'point'
      },
      {
        title: 'Số Lượng Tối Đa',
        dataIndex: 'quantity',
        key: 'quantity'
      },
      {
        title: 'Trạng thái',
        dataIndex: 'status',
        key: 'status',
        render: (status) => {
          const { label, color } = pickBy(mapStatus[status], Boolean);
          return status && <Tag color={color}>{label}</Tag>;
        }
      },
      {
        title: 'Thao tác',
        render: (record) => (
          <div style={{ display: 'flex', gap: 4 }}>
            <Button
              disabled={!hasPermission(UPDATE_GIFT)}
              onClick={() => handleCreateUpdateItem(record.id)}
              icon={<EditOutlined />}
            />

            <Popconfirm
              title="Bạn có chắc bạn muốn xóa?"
              onConfirm={() => handleConfirmDelete(record.id)}
              okText="Có"
              cancelText="Không"
            >
              <Button disabled={!hasPermission(DELETE_GIFT)} danger icon={<DeleteOutlined />} />
            </Popconfirm>

            {record.is_locked ? (
              <Popconfirm
                placement="topRight"
                title="Bạn có chắc bạn muốn mở khóa?"
                onConfirm={() => handleConfirmLock(record.id, false)}
                okText="Có"
                cancelText="Không"
              >
                <Button disabled={!hasPermission(LOCK_GIFT)} icon={<UnlockOutlined />} />
              </Popconfirm>
            ) : (
              <Popconfirm
                placement="topRight"
                title="Bạn có chắc bạn muốn khóa?"
                onConfirm={() => handleConfirmLock(record.id, true)}
                okText="Có"
                cancelText="Không"
              >
                <Button disabled={!hasPermission(LOCK_GIFT)} icon={<LockOutlined />} />
              </Popconfirm>
            )}
          </div>
        )
      }
    ],
    [meta?.from]
  );
  const handleCreateUpdateItem = (id: string) => {
    if (id) {
      navigate(AppRoutes.GIFT_UPDATE.replace(':id', id));
      return;
    }
    navigate(AppRoutes.GIFT_NEW);
  };

  const handleConfirmDelete = (id: string) => {
    deleteCommonRequest({ id, ...apiParamInfos }, getLists);
  };

  const handleConfirmLock = (id: string, is_locked: boolean) => {
    _lockCommonRequest({ id, is_locked, ...apiParamInfos }, getLists);
  };
  const handleChangeStatus = (value: string) => {
    setFilters((prev) => ({ ...prev, status: value }));
  };

  const handleChangeDate = (date) => {
    setFilters((prev) => ({
      ...prev,
      created_from: date?.[0] ? formatDate(date[0], dateFormat) : '',
      created_to: date?.[1] ? formatDate(date[1], dateFormat) : ''
    }));
  };

  const handleSearch = debounce((e) => {
    const val = e.target.value;
    setSearch(val);
  }, 1000);

  const handleChangeTab = (e: any) => setActiveTab(e.target.value);

  return (
    <>
      <div style={{ backgroundColor: '#fff', padding: 16 }}>
        <Radio.Group
          value={activeTab}
          onChange={handleChangeTab}
          style={{ marginBottom: 16, display: 'flex', justifyContent: 'center' }}
        >
          <Radio.Button value="gift">
            <p style={{ fontWeight: 'bold' }}>QUÀ TẶNG</p>
          </Radio.Button>
          <Radio.Button value="change-gift">
            <p style={{ fontWeight: 'bold' }}>YÊU CẦU ĐỔI QUÀ</p>
          </Radio.Button>
        </Radio.Group>

        {activeTab === 'gift' ? (
          <>
            <div style={{ display: 'flex', gap: 16 }}>
              <Button
                type="primary"
                disabled={!hasPermission(CREATE_GIFT)}
                onClick={handleCreateUpdateItem}
                style={{ marginBottom: 8 }}
              >
                Thêm Quà Tặng
              </Button>
            </div>
            {hasPermission(LIST_GIFT) && (
              <>
                <Title level={4}>ĐIỀU KIỆN TÌM KIẾM</Title>

                <Form layout="vertical">
                  <Row gutter={[32, 0]}>
                    <Col span={6}>
                      <Form.Item label="Ngày Tạo (Từ ngày - Đến ngày)">
                        <RangePicker
                          format="DD/MM/YYYY"
                          onChange={handleChangeDate}
                          placeholder={['Bắt đầu', 'Kết thúc']}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label="Trạng Thái">
                        <Select
                          options={LOYALTY_STATUS}
                          onChange={handleChangeStatus}
                          placeholder="Tất cả"
                          allowClear
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label="Từ Khóa">
                        <Input onChange={handleSearch} placeholder="Nhập từ khóa" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>

                <Title level={4}>KẾT QUẢ TÌM KIẾM</Title>
                <Table
                  data={data}
                  bordered
                  columns={columns}
                  loading={loading}
                  meta={meta}
                  currentPage={currPage}
                  setCurrPage={setCurrPage}
                  sortedInfo={sortedInfo}
                  setSortedInfo={setSortedInfo}
                />
              </>
            )}
          </>
        ) : (
          <ListChangeGift />
        )}
      </div>
    </>
  );
});

ListGiftScreen.propTypes = {};

export default ListGiftScreen;
