import { ApiEndpoint } from '@api/constants';
import { apiDel, apiGet, apiPost } from '@api/services/services';
import { ErrorNoti, SuccessNoti } from '@common/components/Notification/Notification';
import { buildFormData, convertToSecond } from '@common/utils';
import { QuestionType } from '@pages/exam-question/types';
import { PracticeType } from '@pages/practices/types';

export interface DeleteQuestionPayload {
  examId: string;
  questionId: string;
}

export enum KnowledgeThunk {
  ADD_KNOWLEDGE_REQUEST = 'ADD_KNOWLEDGE_REQUEST',
  DELETE_KNOWLEDGE_REQUEST = 'DELETE_KNOWLEDGE_REQUEST',
  UPDATE_KNOWLEDGE = 'UPDATE_KNOWLEDGE',
  GET_LIST_KNOWLEDGE_REQUEST = 'GET_LIST_KNOWLEDGE_REQUEST',
  GET_LIST_KNOWLEDGE_SUCCESS = 'GET_LIST_KNOWLEDGE_SUCCESS'
}

export const getKnowledgesRequest = (params) => async (dispatch) => {
  try {
    dispatch({ type: KnowledgeThunk.GET_LIST_KNOWLEDGE_REQUEST });

    const { data } = await apiGet({
      path: `/${ApiEndpoint.KNOWLEDGE}`,
      body: params
    });

    const mapData = data.data.map((item) => ({
      ...item,
      thematic: { ...item.thematic, label: item.thematic.name, value: item.thematic.id }
      // grade: item.unit
    }));
    dispatch(getKnowledgeSuccess({ ...data, data: mapData }));
  } catch (error) {
    ErrorNoti(error.response.data.message);
  }
};

export const deleteKnowledgeRequest = (payload, callback) => async (dispatch) => {
  try {
    dispatch({ type: KnowledgeThunk.DELETE_KNOWLEDGE_REQUEST });
    await apiDel({
      path: `/${ApiEndpoint.KNOWLEDGE}/${payload.id}`
    });

    SuccessNoti('Xóa kiến thức');
    callback();
  } catch (error) {
    callback();
    ErrorNoti(error.response.data.message);
  }
};

export const addKnowledgeRequest = (payload, callback) => async (dispatch) => {
  const {
    name,
    type,
    subject,
    semester,
    topics,
    grade,
    files: { video, image },
    thematic,
    year,
    description,
    learn_type,
    sync_video_id
  } = payload;
  const formData = new FormData();

  const newTopic = topics.map((topic) => ({
    ...topic,
    question_start: convertToSecond(topic.question.question_start),
    question_end: convertToSecond(topic.question.question_end),
    topic_start: convertToSecond(topic.question.topic_start),
    question: { ...topic.question, thematic_id: thematic }
  }));

  const mapData = {
    video,
    image,
    name,
    type,
    subject_id: subject,
    semester_id: semester,
    unit_id: grade,
    thematic_id: thematic,
    year,
    description,
    learn_type,
    sync_video_id,
    topics: [...newTopic]
  };

  buildFormData(formData, mapData);

  try {
    dispatch({ type: KnowledgeThunk.ADD_KNOWLEDGE_REQUEST });

    const {
      data: { data }
    } = await apiPost({
      path: `/${ApiEndpoint.KNOWLEDGE}`,
      body: formData
    });
    if (data) {
      SuccessNoti('Tạo kiến thức');
      callback();
    }
  } catch (error) {
    callback();
    ErrorNoti(error.response.data.message);
  }
};

export const updateKnowledgeRequest = (payload, callback) => async (dispatch) => {
  const {
    name,
    type,
    subject,
    semester,
    topics,
    grade,
    files: { video, image },
    thematic,
    id,
    description,
    year,
    learn_type,
    sync_video_id
  } = payload;

  const newTopic = topics.map((topic) => ({
    ...topic,
    question_start: convertToSecond(topic.question.question_start),
    question_end: convertToSecond(topic.question.question_end),
    topic_start: convertToSecond(topic.question.topic_start),
    question: {
      ...topic.question,
      thematic_id: thematic.id,
      answers: topic.question.answers.map((i) => ({
        ...i,
        correct: i.correct ? 1 : 0
      }))
    }
  }));

  const mapData = {
    video,
    image,
    name,
    type,
    subject_id: subject,
    semester_id: semester,
    unit_id: grade,
    thematic_id: thematic.id,
    topics: [...newTopic],
    description,
    year,
    learn_type,
    sync_video_id,
    _method: 'PATCH'
  };

  const formData = new FormData();
  buildFormData(formData, mapData);

  try {
    dispatch({ type: KnowledgeThunk.ADD_KNOWLEDGE_REQUEST });

    const {
      data: { data }
    } = await apiPost({
      path: `/${ApiEndpoint.KNOWLEDGE}/${id}`,
      body: formData
    });
    if (data) {
      SuccessNoti('Cập nhật kiến thức');
      callback();
    }
  } catch (error) {
    callback();
    ErrorNoti(error.response.data.message);
  }
};

export const getKnowledgeSuccess = (payload) => ({
  type: KnowledgeThunk.GET_LIST_KNOWLEDGE_SUCCESS,
  payload
});

export const setKnowledge = (payload: PracticeType) => ({
  type: KnowledgeThunk.ADD_KNOWLEDGE,
  payload
});

export const deleteKnowledge = (payload: DeleteQuestionPayload) => ({
  type: KnowledgeThunk.DELETE_KNOWLEDGE,
  payload
});

export const updateKnowledge = (payload: QuestionType) => ({
  type: KnowledgeThunk.UPDATE_KNOWLEDGE,
  payload
});
