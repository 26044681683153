export enum AppRoutes {
  HOME = '/',
  DASHBOARD = '/dashboard',
  ANCESTORS = '/ancestor',
  ANCESTOR_NEW = '/ancestor/new',
  ANCESTOR_DETAIL = '/ancestor/detail/:id',
  ANCESTOR_DETAIL_NO_ID = '/ancestor/detail',
  STUDENTS = '/student',
  STUDENT_NEW = '/student/new',
  STUDENT_DETAIL = '/student/detail/:id',
  STUDENT_DETAIL_NO_ID = '/student/detail',
  LOGIN = '/login',
  ERROR_404 = '/404',
  ERROR_500 = '/500',
  EXAM = '/exam-question',
  EXAM_DETAIL = '/exam-question/:id',
  UNITS = '/unit',
  UNIT_NEW = '/unit/new',
  UNIT_DETAIL = '/unit/detail/:id',
  UNIT_DETAIL_NO_ID = '/unit/detail',
  SUBJECT = '/subject',
  SUBJECT_NEW = '/subject/new',
  SUBJECT_DETAIL = '/subject/detail/:id',
  SUBJECT_DETAIL_NO_ID = '/subject/detail',
  THEMATIC = '/thematic',
  THEMATIC_NEW = '/thematic/new',
  THEMATIC_DETAIL = '/thematic/detail/:id',
  THEMATIC_DETAIL_NO_ID = '/thematic/detail',
  SEMESTER = '/semester',
  SEMESTER_NEW = '/semester/new',
  SEMESTER_DETAIL = '/semester/detail/:id',
  SEMESTER_DETAIL_NO_ID = '/semester/detail',
  KNOWLEDGE = '/knowledge',
  // KNOWLEDGE_NEW = '/knowledge/new',
  KNOWLEDGE_DETAIL = '/knowledge/:id',
  // KNOWLEDGE_DETAIL_NO_ID = '/knowledge/detail',
  // LESSON = '/lesson',
  // LESSON_NEW = '/lesson/new',
  // LESSON_DETAIL = '/lesson/detail/:id',
  // LESSON_DETAIL_NO_ID = '/lesson/detail',
  PRACTICES = '/practices',
  PRACTICE_DETAIL = '/practices/:id',
  // KNOWLEDGE1 = '/knowledge1',
  // KNOWLEDGE1_DETAIL = '/knowledge1/:id'
  QUESTIONS = '/questions',
  QUESTION_NEW = '/questions/new',
  QUESTION_EDIT = '/questions/new',
  VIDEO = '/video',
  LEARNING_FILE = '/learning-file',
  LEARNING_FILE_DETAIL = '/learning-file/:id',
  ORDERS = '/orders',
  ORDERS_DETAIL = '/orders/:id',
  EVENT_PERIODIC = '/event-periodic',
  EVENT_PERIODIC_DETAIL = '/event-periodic/:id',
  EVENT_PERIODIC_NEW = '/event-periodic/new',
  EVENT_PERIODIC_UPDATE = '/event-periodic/:id/update',
  SYSTEM_EVENTS = '/system-events',
  SYSTEM_EVENTS_NEW = '/system-events/new',
  SYSTEM_EVENTS_DETAIL = '/system-events/:id',
  SYSTEM_EVENTS_UPDATE = '/system-events/:id/update',
  NOTIFICATION = '/notification',
  NOTIFICATION_NEW = '/notification/new',
  NOTIFICATION_DETAIL = '/notification/:id',
  NOTIFICATION_UPDATE = '/notification/:id/update',
  LOYALTY_LIST = '/loyaltys',
  LOYALTY_NEW = '/loyaltys/new',
  LOYALTY_UPDATE = '/loyaltys/:id/update',
  CONFIG_POINT = '/loyaltys/config-point',
  GIFT_LIST = '/gifts',
  GIFT_NEW = '/gifts/new',
  GIFT_UPDATE = '/gifts/:id/update',
  ADMIN_LIST = '/admins',
  ADMIN_NEW = '/admins/new',
  ADMIN_UPDATE = '/admins/:id/update',
  ADMIN_AUTHORIZATION = '/admins/:id/authorization',
  STUDY_PLAN = '/study-plans',
  STUDY_PLAN_NEW = '/study-plans/new',
  STUDY_PLAN_UPDATE = '/study-plans/:id/update',
  SCHOOL_REFERRAL = '/school-referral',
  SCHOOL_REFERRAL_NEW = '/school-referral/new',
  SCHOOL_REFERRAL_UPDATE = '/school-referral/:id/update',
  LEARNING_GOALS = '/student/detail/:id/learning-goals',
  REPORT_LEARNING = '/student/detail/:id/report-learning',
  MANUAL_NOTIFICATION_NEW = '/manual-notification/new',
  REPORT_MAIN_LIST = '/reports',
  REPORT_EVENT_RESULT = '/reports/event-result',
  REPORT_EVENT_RESULT_DETAIL = '/reports/event-result/:id',
  REPORT_SYSTEM_EVENT_RESULT = '/reports/system-event-result',
  REPORT_SYSTEM_EVENT_RESULT_DETAIL = '/reports/system-event-result/:id',
  REPORT_STUDENT = '/reports/student',
  REPORT_STUDENT_DETAIL = '/reports/student/:id',
  REPORT_SHARED_PERSON = '/reports/shared-person',
  RANKING_REPORT = '/reports/ranking',
  COMBO_LEARNING_PACKAGE = '/package-combo',
  COMBO_LEARNING_PACKAGE_NEW = '/package-combo/new',
  COMBO_LEARNING_PACKAGE_UPDATE = '/package-combo/:id/update',
  COMBO_LEARNING_PACKAGE_DETAIL = '/package-combo/:id',
  CHATBOT_CONFIG = '/chatbot-config',
  UPDATE_PROFILE = '/update-profile',
  AFFILIATE_REPORT = '/reports/affiliate-report',
  BANNER_SLIDE = '/banner-slide',
  AFFILIATE_REFERRAL_REPORT = '/reports/affiliate-referral-report'
}
