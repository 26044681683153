import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Pagination, Table as AntTable } from 'antd';

import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

import './styles.scss';

const locale = {
  emptyText: 'Không có dữ liệu'
};

const Table = memo(
  ({
    data,
    columns = [],
    loading,
    meta,
    currentPage,
    setCurrPage,
    sortedInfo,
    setSortedInfo,
    hasPagination = true,
    ...props
  }) => {
    const handleSort = (key: string) => {
      if (!sortedInfo?.order) {
        setSortedInfo({ columnKey: key, order: 'asc' });
      } else {
        setSortedInfo({
          columnKey: key,
          order:
            key === sortedInfo.columnKey ? (sortedInfo.order === 'desc' ? 'asc' : 'desc') : 'asc'
        });
      }
    };

    const newColumns = columns.map((c) => ({
      ...c,
      title: () =>
        c.sortable ? (
          <div onClick={() => handleSort(c.key)} className="title-container">
            {c.title}
            <span className="sort-icon-container">
              <span>
                {sortedInfo?.columnKey === c.key && sortedInfo.order === 'asc' ? (
                  <CaretUpOutlined style={{ color: '#1677ff' }} />
                ) : (
                  <CaretUpOutlined />
                )}
              </span>
              <span>
                {sortedInfo?.columnKey === c.key && sortedInfo.order === 'desc' ? (
                  <CaretDownOutlined style={{ color: '#1677ff' }} />
                ) : (
                  <CaretDownOutlined />
                )}
              </span>
            </span>
          </div>
        ) : (
          c.title
        )
    }));

    const handleChangePage = (value: number) => {
      setCurrPage(value);
    };
    return (
      <div className="bittus-admin-table">
        <AntTable
          dataSource={data}
          bordered
          columns={newColumns}
          pagination={false}
          loading={loading}
          locale={locale}
          {...props}
        />
        {hasPagination && (
          <Pagination
            pageSize={10}
            current={currentPage}
            total={meta?.total}
            onChange={handleChangePage}
            style={{ marginTop: 16 }}
            showSizeChanger={false}
          />
        )}
      </div>
    );
  }
);

Table.propTypes = {};

export default Table;
