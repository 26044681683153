import { getStorage } from '@api/services/services';
import { range } from 'lodash';
import moment from 'moment';
moment.locale('vi');

export const ValidationRegex = {
  Phone: /(0[3|5|7|8|9])+([0-9]{8})\b/g,
  Password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/g,
  Email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
};

export const dateFormat = 'DD/MM/YYYY';
export const dateSecondFormat = 'DD/MM/YYYY HH:mm:ss';

export const formatDate = (date: Date, format = dateFormat) => moment(date).format(format);

export type ValueGetterFunc<T, R> = (item: T) => R;

const defaultValueGetter = (): any => true;

export const mapReduce = <T extends { [key in F]?: any }, F extends string, R = boolean>(
  data: T[],
  field: F,
  valueGetter: ValueGetterFunc<T, R> = defaultValueGetter
): { [key: string]: R } =>
  data?.reduce<{ [key: string]: R }>((all, item) => {
    const key = item[field]?.toString();
    if (key) all[key] = valueGetter(item);
    return all;
  }, {});

export function buildFormData(formData, data, parentKey?) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}

export function convertToSecond(timeString: string) {
  if (typeof timeString === 'number') {
    return timeString;
  }

  const [hours = 0, minutes = 0, seconds = 0] = timeString.split(':').map(Number);
  return hours * 60 * 60 + minutes * 60 + seconds;
}

export const convertSecToTime = (second: number) => {
  if (second !== 0 && !second) return '00:00:00';
  return new Date(second * 1000).toISOString().substr(11, 8);
};

export const isObject = (value) =>
  typeof value === 'object' && !Array.isArray(value) && value !== null;

export const filterEmoji = (string: string) => {
  const ranges = [
    '\ud83c[\udf00-\udfff]', // U+1F300 to U+1F3FF
    '\ud83d[\udc00-\ude4f]', // U+1F400 to U+1F64F
    '\ud83d[\ude80-\udeff]' // U+1F680 to U+1F6FF
  ];

  return string.replace(new RegExp(ranges.join('|'), 'g'), '');
  // const regex =
  //   /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
  // return string.replace(regex, '');
};

export const calculateDisable = (hour: number, minute: number, second: number) => {
  const disabledHours = () => range(24).splice(+hour + 1, 24);

  const disabledMinutes = (selectedHour: number) => {
    if (selectedHour === +hour || +hour === 0) {
      return range(60).splice(+minute + 1, 60);
    }
    return [];
  };

  const disabledSeconds = (_, selectedMinute: number) => {
    if (selectedMinute === +minute) {
      return range(60).splice(+second + 1, 60);
    }
    return [];
  };

  return {
    disabledHours,
    disabledMinutes,
    disabledSeconds
  };
};

export const isTokenExpired = (expired) => {
  if (!expired) {
    return true;
  }

  const utx = Date.now();
  return utx > expired * 1000;
};

export const formatCurrency = (number: number, currency: string) => {
  if (number !== null && number !== undefined) {
    return `${number} ${currency}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return '';
};

export function titleCase(str: string) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

export const normalizeVietnameseLetter = (str: string) =>
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const generatePassword = (): string => {
  let result = '';
  const upperLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowerLetters = 'abcdefghijklmnopqrstuvwxyz';
  const numberLetters = '0123456789';

  for (let i = 0; i < 4; i++) {
    result += upperLetters.charAt(Math.floor(Math.random() * upperLetters.length));
  }

  result += '@';

  for (let i = 0; i < 4; i++) {
    result += lowerLetters.charAt(Math.floor(Math.random() * lowerLetters.length));
  }

  for (let i = 0; i < 4; i++) {
    result += numberLetters.charAt(Math.floor(Math.random() * numberLetters.length));
  }

  return result;
};

export const hasPermission = (role: string | undefined) => {
  const { permissions } = getStorage('user') && JSON.parse(getStorage('user'));
  if (permissions && permissions.includes(role)) return true;
  return false;
};

export const tryParseJSON = (jsonString: string) => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return null;
  }
}

export const getLabelAddress = (address: string) => {
  const addressDetail = tryParseJSON(address)
  if (!addressDetail) {
    return address
  }
  return `${addressDetail.address_detail}${addressDetail.ward ? ', ' + addressDetail.ward : ''}${addressDetail.district ? ', ' + addressDetail.district : ''}${addressDetail.city ? ', ' + addressDetail.city : ''}`
};
