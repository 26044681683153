import React from 'react';

import { AppRoutes } from '@common/constants';
import { cilSpeedometer, cilUser, cilLightbulb, cilBell, cilGift } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CNavGroup, CNavItem } from '@coreui/react';
import { NodeIndexOutlined, ContainerOutlined } from '@ant-design/icons';

export interface ISidebarItem {
  name: string;
  to?: string;
  icon?: any;
  badge?: {
    color: string;
    text: string;
  };
  component?: any;
  items?: ISidebarItem[];
}

function useAppNavigation(): ISidebarItem[] {
  return [
    {
      component: CNavItem,
      name: 'Trung tâm',
      to: AppRoutes.DASHBOARD,
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />
    },
    {
      component: CNavGroup,
      name: 'Người dùng',
      icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Phụ huynh',
          to: AppRoutes.ANCESTORS
        },
        {
          component: CNavItem,
          name: 'Học viên',
          to: AppRoutes.STUDENTS
        }
      ]
    },
    {
      component: CNavItem,
      name: 'Quản Lý Thành Viên Quản Trị',
      to: AppRoutes.ADMIN_LIST,
      icon: <CIcon icon={cilUser} customClassName="nav-icon" />
    },
    {
      component: CNavItem,
      name: 'Quản Lý Đề Thi',
      to: AppRoutes.EXAM,
      icon: <CIcon icon={cilUser} customClassName="nav-icon" />
    },
    {
      component: CNavItem,
      name: 'Quản Lý Bài Thực Hành',
      to: AppRoutes.PRACTICES,
      icon: <CIcon icon={cilUser} customClassName="nav-icon" />
    },
    {
      component: CNavItem,
      name: 'Quản Lý Kiến Thức',
      to: AppRoutes.KNOWLEDGE,
      icon: <CIcon icon={cilUser} customClassName="nav-icon" />
    },
    {
      component: CNavItem,
      name: 'Quản Lý Câu Hỏi',
      to: AppRoutes.QUESTIONS,
      icon: <CIcon icon={cilUser} customClassName="nav-icon" />
    },
    {
      component: CNavItem,
      name: 'Quản Lý Video Bài Giảng',
      to: AppRoutes.VIDEO,
      icon: <CIcon icon={cilUser} customClassName="nav-icon" />
    },
    {
      component: CNavItem,
      name: 'Quản Lý Gói Học Tập',
      to: AppRoutes.LEARNING_FILE,
      icon: <CIcon icon={cilUser} customClassName="nav-icon" />
    },
    {
      component: CNavItem,
      name: 'Quản lý Gói Combo',
      to: AppRoutes.COMBO_LEARNING_PACKAGE,
      icon: <CIcon icon={cilUser} customClassName="nav-icon" />
    },
    {
      component: CNavItem,
      name: 'Quản lý slide',
      to: AppRoutes.BANNER_SLIDE,
      icon: <CIcon icon={cilUser} customClassName="nav-icon" />
    },
    {
      component: CNavItem,
      name: 'Quản Lý Đơn Hàng',
      to: AppRoutes.ORDERS,
      icon: <CIcon icon={cilUser} customClassName="nav-icon" />
    },
    {
      component: CNavItem,
      name: 'Quản Lý Sự Kiện Định Kỳ',
      to: AppRoutes.EVENT_PERIODIC,
      icon: <CIcon icon={cilUser} customClassName="nav-icon" />
    },
    {
      component: CNavItem,
      name: 'Quản Lý Sự Kiện Hệ Thống',
      to: AppRoutes.SYSTEM_EVENTS,
      icon: <CIcon icon={cilLightbulb} customClassName="nav-icon" />
    },
    {
      component: CNavItem,
      name: 'Quản Lý Thông Báo',
      to: AppRoutes.NOTIFICATION,
      icon: <CIcon icon={cilBell} customClassName="nav-icon" />
    },
    {
      component: CNavItem,
      name: 'Quản Lý Loyalty',
      to: AppRoutes.LOYALTY_LIST,
      icon: <CIcon icon={cilBell} customClassName="nav-icon" />
    },
    {
      component: CNavItem,
      name: 'Quản Lý Quà Tặng',
      to: AppRoutes.GIFT_LIST,
      icon: <CIcon icon={cilGift} customClassName="nav-icon" />
    },
    {
      component: CNavItem,
      name: 'Lộ Trình Phù Hợp',
      to: AppRoutes.STUDY_PLAN,
      icon: <NodeIndexOutlined className="nav-icon" />
    },
    {
      component: CNavItem,
      name: 'Nguồn giới thiệu',
      to: AppRoutes.SCHOOL_REFERRAL,
      icon: <NodeIndexOutlined className="nav-icon" />
    },
    {
      component: CNavItem,
      name: 'Báo cáo - Thống kê',
      to: AppRoutes.REPORT_MAIN_LIST,
      icon: <ContainerOutlined className="nav-icon" />
    },
    {
      component: CNavGroup,
      name: 'Thông Tin',
      icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Cấu hình chatbot',
          to: AppRoutes.CHATBOT_CONFIG
        },
        {
          component: CNavItem,
          name: 'Khối / Lớp',
          to: AppRoutes.UNITS
        },
        {
          component: CNavItem,
          name: 'Môn Học',
          to: AppRoutes.SUBJECT
        },
        {
          component: CNavItem,
          name: 'Chuyên Đề',
          to: AppRoutes.THEMATIC
        }
      ]
    }
  ];
}

export default useAppNavigation;
