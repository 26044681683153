export enum ApiEndpoint {
  LOGIN = 'api/auth/login',
  LOGOUT = 'api/auth/logout',
  CSRF_TOKEN = 'sanctum/csrf-cookie',
  USER_ME = 'api/user/me',
  USER_LIST = 'api/user',
  USER_DETAIL = 'api/user',
  USER_CREATE = 'api/user',
  USER_UPDATE = 'api/user',
  USER_DELETE = 'api/user',
  ANCESTOR = '/api/ancestor',
  // ANCESTOR_DETAIL = 'api/ancestor',
  // ANCESTOR_CREATE = 'api/ancestor',
  // ANCESTOR_UPDATE = 'api/ancestor',
  // ANCESTOR_DELETE = 'api/ancestor',
  OPTIONS_ANCESTOR = 'api/options/ancestor',
  OPTIONS_UNIT = 'api/options/unit',
  OPTIONS_SUBJECT = 'api/options/subject',
  OPTIONS_SEMESTER = 'api/options/semester',
  OPTIONS_THEMATIC = 'api/options/thematic',
  OPTIONS_LESSON = 'api/options/lesson',
  OPTIONS_TEST = '/api/tests',
  OPTIONS_COMBO = '/api/options/learn',
  STUDENT = '/api/student',
  UNIT = '/api/unit',
  SUBJECT = '/api/subject',
  SEMESTER = '/api/semester',
  THEMATIC = '/api/thematic',
  LESSON = 'api/lesson',
  KNOWLEDGE = 'api/knowledge',
  EXAM_QUESTION = '/api/test',
  QUESTION = '/api/question',
  PRACTICE = '/api/practice',
  VIDEO = '/api/video',
  LEARNING_FILE = '/api/learn',
  ORDERS = '/api/orders',
  COMBO_ORDERS = '/api/orders/by-combo',
  ORDER_DETAIL = '/api/orders',
  EVENT_PERIODIC = '/api/test-schedule',
  SYSTEM_EVENT_DETAIL = '/api/events',
  SYSTEM_EVENT = '/api/events',
  NOTIFICATION = '/api/notification',
  ADMIN = '/api/admins',
  ADMIN_PERMISSIONS = '/api/admins/{id}/permissions',
  UPDATE_ADMIN_PERMISSIONS = '/api/admins/{admin}/permissions',
  GIFT = '/api/gifts',
  STUDY_PLAN = '/api/study-plans',
  SCHOOL_REFERRAL = '/api/school-referral',
  LOYALTY = '/api/coupons',
  CHANGE_GIFT = '/api/gift-exchanges',
  MANUAL_NOTIFICATION = '/api/manual-notification',
  LEARNING_GOAL = '/api/student/:id/learning-goals',
  REPORT_RANKING = '/api/rank-chart',
  COMBOS = '/api/combos',
  COMBO = '/api/combos/:id',
  CHATBOT_CONFIG = '/api/milu-config/show/:id',
  CHATBOT_CONFIG_UPDATE = '/api/milu-config/store-or-update',
  AFFILIATE_REPORT_URL = '/api/admins/affiliate-report',
  STUDENT_REPORT_URL = '/api/student/report/:id',
  AFFILIATE_REFERRAL_REPORT_URL = '/api/school-referral/affiliate-referral-report',
  AFFILIATE_REFERRAL_EXPORT_URL = '/api/school-referral/affiliate-referral-report-export'
}

export enum HttpResultCode {
  SUCCESS = 200,
  NO_CONTENT = 204,
  ERROR = 500
}

export enum HttpStatusCode {
  NOT_AUTHORIZED = 401,
  NOT_FOUND = 404,
  FOUND = 302
}
