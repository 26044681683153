import { ApiEndpoint } from '@api/constants';
import { apiGet } from '@api/services/services';
import { ErrorNoti } from '@common/components/Notification/Notification';

export enum SchoolReferralConstant {
  GET_LIST_SCHOOL_REFERRAL_REQUEST = 'GET_LIST_SCHOOL_REFERRAL_REQUEST',
  GET_LIST_SCHOOL_REFERRAL_SUCCESS = 'GET_LIST_SCHOOL_REFERRAL_SUCCESS'
}

export const getSchoolReferralRequest = (params: any) => async (dispatch: any) => {
  try {
    dispatch({ type: SchoolReferralConstant.GET_LIST_SCHOOL_REFERRAL_REQUEST });

    const { data } = await apiGet({
      path: ApiEndpoint.SCHOOL_REFERRAL,
      body: params
    });
    dispatch(getSchoolReferralSuccess(data));
  } catch (error) {
    ErrorNoti(error?.response?.data?.message);
  }
};

export const getSchoolReferralSuccess = (payload: any) => ({
  type: SchoolReferralConstant.GET_LIST_SCHOOL_REFERRAL_SUCCESS,
  payload
});
