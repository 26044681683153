import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Button from '../Button/Button';
import {
  DeleteOutlined,
  EditOutlined,
  LockOutlined,
  EyeOutlined,
  UnlockOutlined
} from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { hasPermission } from '@common/utils';

const ActionRenderer = memo(
  ({
    record,
    hasViewDetail,
    hasEdit,
    hasDelete,
    hasLock,
    handleDelete,
    handleViewDetail,
    handleEdit,
    handleLock,
    viewRole,
    editRole,
    deleteRole,
    lockRole
  }) => (
    <div style={{ display: 'flex', gap: 4 }}>
      {hasViewDetail && (
        <Button
          disabled={!hasPermission(viewRole)}
          type="primary"
          onClick={() => handleViewDetail(record.id)}
          icon={<EyeOutlined />}
        />
      )}

      {hasEdit && (
        <Button
          disabled={!hasPermission(editRole)}
          onClick={() => handleEdit(record.id)}
          icon={<EditOutlined />}
        />
      )}

      {hasDelete && (
        <Popconfirm
          title="Bạn có chắc bạn muốn xóa?"
          disabled={!hasPermission(deleteRole)}
          onConfirm={() => handleDelete(record.id)}
          okText="Có"
          cancelText="Không"
        >
          <Button danger icon={<DeleteOutlined />} />
        </Popconfirm>
      )}

      {hasLock &&
        (record.status === 'LOCK' ? (
          <Popconfirm
            placement="topRight"
            title="Bạn có chắc bạn muốn mở khóa?"
            onConfirm={() => handleLock(record.id)}
            okText="Có"
            cancelText="Không"
          >
            <Button disabled={!hasPermission(lockRole)} icon={<UnlockOutlined />} />
          </Popconfirm>
        ) : (
          <Popconfirm
            placement="topRight"
            title="Bạn có chắc bạn muốn khóa?"
            onConfirm={() => handleLock(record.id)}
            okText="Có"
            cancelText="Không"
          >
            <Button disabled={!hasPermission(lockRole)} icon={<LockOutlined />} />
          </Popconfirm>
        ))}
    </div>
  )
);

export default ActionRenderer;
