import { ApiEndpoint } from '@api/constants';
import { apiGet } from '@api/services/services';
import { ErrorNoti } from '@common/components/Notification/Notification';

export enum AdminConstant {
  GET_LIST_ADMIN_REQUEST = 'GET_LIST_ADMIN_REQUEST',
  GET_LIST_ADMIN_SUCCESS = 'GET_LIST_ADMIN_SUCCESS'
}

export const getAdminRequest = (params) => async (dispatch) => {
  try {
    dispatch({ type: AdminConstant.GET_LIST_ADMIN_REQUEST });

    const { data } = await apiGet({
      path: ApiEndpoint.ADMIN,
      body: params
    });
    dispatch(getAdminSuccess(data));
  } catch (error) {
    ErrorNoti(error?.response?.data?.message);
  }
};

export const getAdminSuccess = (payload) => ({
  type: AdminConstant.GET_LIST_ADMIN_SUCCESS,
  payload
});
