import { ApiEndpoint } from "@api/constants";
import {
    TLessonCreateRequest,
    TLessonDeleteResponse,
    TLessonOptionsResponse,
    TLessonResponse,
    TLessonsRequest,
    TLessonsResponse,
    TLessonUpdateRequest,
} from "@api/interfaces";
import axiosInstance from "@api/services/axios";
import { API_DOMAIN } from "@common/constants/Environment";
import { AxiosResponse } from "axios";

class LessonApi {
    public static options = (): Promise<AxiosResponse<TLessonOptionsResponse>> => axiosInstance.get(
        `${API_DOMAIN}/${ApiEndpoint.OPTIONS_LESSON}`,
        {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            maxRedirects: 0,
        },
    ).catch(error => error.handleGlobally && error.handleGlobally());

    public static list = (request: TLessonsRequest): Promise<AxiosResponse<TLessonsResponse>> => {
        const url = new URL(ApiEndpoint.LESSON, API_DOMAIN);
        url.searchParams.set("page", `${request.pageIndex}`);
        url.searchParams.set("size", `${request.pageSize}`);
        url.searchParams.set("filters", JSON.stringify(request.filters ?? []));
        url.searchParams.set("globalFilter", request.globalFilter ?? "");
        url.searchParams.set("sorting", JSON.stringify(request.sorting ?? []));

        // @ts-ignore
        return axiosInstance.get(
            url.toString(),
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        ).catch(error => error.handleGlobally && error.handleGlobally());
    };

    public static create = (request: TLessonCreateRequest): Promise<AxiosResponse<TLessonResponse>> =>
        axiosInstance.post(
            `${API_DOMAIN}/${ApiEndpoint.LESSON}`,
            request,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                maxRedirects: 0,
            },
        ).catch(error => error.handleGlobally && error.handleGlobally());

    public static update = (id: number, request: TLessonUpdateRequest): Promise<AxiosResponse<TLessonResponse>> =>
        axiosInstance.patch(
            `${API_DOMAIN}/${ApiEndpoint.LESSON}/${id}`,
            request,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                maxRedirects: 0,
            },
        ).catch(error => error.handleGlobally && error.handleGlobally());

    public static get = (id: number): Promise<AxiosResponse<TLessonResponse>> => axiosInstance.get(
        `${API_DOMAIN}/${ApiEndpoint.LESSON}/${id}`,
        {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            maxRedirects: 0,
        },
    ).catch(error => error.handleGlobally && error.handleGlobally());

    public static delete = (id: number | string): Promise<AxiosResponse<TLessonDeleteResponse>> =>
        axiosInstance.delete(
            `${API_DOMAIN}/${ApiEndpoint.LESSON}/${id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                maxRedirects: 0,
            },
        ).catch(error => error.handleGlobally && error.handleGlobally());
}

export default LessonApi;
