import { ApiEndpoint } from '@api/constants';
import { apiGet } from '@api/services/services';
import { ErrorNoti } from '@common/components/Notification/Notification';

export enum LearningFileConstant {
  ADD_LEARNING_FILE_REQUEST = 'ADD_LEARNING_FILE_REQUEST',
  ADD_LEARNING_FILE_SUCCESS = 'ADD_LEARNING_FILE_SUCCESS',
  GET_LIST_LEARNING_FILE_REQUEST = 'GET_LIST_LEARNING_FILE_REQUEST',
  GET_LIST_LEARNING_FILE_SUCCESS = 'GET_LIST_LEARNING_FILE_SUCCESS'
}

export const getLearningRequest = (params) => async (dispatch) => {
  try {
    dispatch({ type: LearningFileConstant.GET_LIST_LEARNING_FILE_REQUEST });

    const { data } = await apiGet({
      path: ApiEndpoint.LEARNING_FILE,
      body: params
    });

    dispatch(getLearningFileSuccess(data));
  } catch (error) {
    ErrorNoti(error.response.data.message);
  }
};

export const getLearningFileSuccess = (payload) => ({
  type: LearningFileConstant.GET_LIST_LEARNING_FILE_SUCCESS,
  payload
});
