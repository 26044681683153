import { IUser } from "@api/interfaces";
import * as React from "react";

export interface ContextProps {
    user: null | any;
    authenticated: null | boolean;
    signIn: (
        username: string,
        password: string,
    ) => Promise<{ signedIn: boolean }>;
    signOut: () => Promise<void>;
    setUser: (user: IUser | null, authenticated?: boolean) => void;
    checkAuthentication: () => Promise<boolean>;
}

const SanctumContext = React.createContext<ContextProps | undefined>(undefined);

export default SanctumContext;
