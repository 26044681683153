import { AppRoutes } from '@common/constants';
import {
  VIEW_STUDENT_STUDY_GOALS,
  CREATE_STUDY_PLAN,
  PERMISSION_ADMIN,
  CREATE_MANUAL_NOTIFICATION,
  VIEW_LEARN,
  VIEW_PRACTICE,
  VIEW_TEST,
  VIEW_KNOWLEDGE,
  VIEW_ORDER,
  CONFIG_PROMOTION,
  CREATE_ADMIN,
  CREATE_EVENT,
  CREATE_GIFT,
  CREATE_NOTIFICATION,
  CREATE_PROMOTION,
  CREATE_TEST_SCHEDULE,
  UPDATE_ADMIN,
  UPDATE_EVENT,
  UPDATE_GIFT,
  UPDATE_NOTIFICATION,
  UPDATE_PROMOTION,
  UPDATE_TEST_SCHEDULE,
  VIEW_EVENT,
  VIEW_NOTIFICATION,
  VIEW_TEST_SCHEDULE,
  UPDATE_STUDY_PLAN,
  VIEW_STUDENT_REPORT,
  RANKING_REPORT,
  CREATE_LEARN,
  UPDATE_LEARN,
  VIEW_USER,
  AFFILIATE_REPORT,
  AFFILIATE_REFERRAL_REPORT
} from '@common/constants/permission';
import IRoute from '@common/interfaces/IRoute';
import CreateUpdateGiftScreen from '@pages/gift/CreateUpdateGiftScreen';
import ListGiftScreen from '@pages/gift/ListGiftScreen';
import ConfigPointScreen from '@pages/loyalty/ConfigPointScreen';
import CreateUpdateLoyaltyScreen from '@pages/loyalty/CreateUpdateLoyaltyScreen';
import { lazy } from 'react';

const useRouters = (): IRoute[] => {
  const Ancestors = lazy(() => import('@pages/ancestors/AncestorScreen'));
  // const Ancestor = lazy(() => import('@pages/ancestors/Ancestor'));
  const Students = lazy(() => import('@pages/students/StudentScreen'));
  const UnitList = lazy(() => import('@pages/units/UnitScreen'));
  const SubjectList = lazy(() => import('@pages/subject/SubjectScreen'));
  const ThematicList = lazy(() => import('@pages/thematic/ThematicScreen'));
  const ExamQuestion = lazy(() => import('@pages/exam-question/ExamQuestion'));
  const DetailExamQuestion = lazy(() => import('@pages/detail-exam-question/DetailExamQuestion'));
  const Practices = lazy(() => import('@pages/practices/Practices'));
  const DetailPractice = lazy(() => import('@pages/practices/DetailPractice'));
  const Knowledge1 = lazy(() => import('@pages/knowledge1/Knowledge'));
  const DetailKnowledge = lazy(() => import('@pages/knowledge1/DetailKnowledge'));
  const Questions = lazy(() => import('@pages/questions/Questions'));
  const Videos = lazy(() => import('@pages/manage-videos/VideoScreen'));
  const CreateUpdateQuestion = lazy(() => import('@pages/questions/CreateUpdateQuestion'));
  const LearningFile = lazy(() => import('@pages/learning-pack/LearningPackScreen'));
  const DetailLearningPack = lazy(() => import('@pages/learning-pack/DetailLearningPack'));
  const ListOrderScreen = lazy(() => import('@pages/orders/ListOrderScreen'));
  const OrderDetail = lazy(() => import('@pages/orders/OrderDetail'));
  const EventPeriodicScreen = lazy(() => import('@pages/events/EventPeriodicScreen'));
  const ListSystemEventScreen = lazy(() => import('@pages/system-event/ListSystemEventScreen'));
  const SystemEventScreenUpdate = lazy(() => import('@pages/system-event/SystemEventUpdateScreen'));
  const SystemEventNewScreen = lazy(() => import('@pages/system-event/SystemEventNewScreen'));
  const EventPeriodicDetailScreen = lazy(() => import('@pages/events/EventPeriodicDetail'));
  const CreateUpdatePeriodicEvent = lazy(() => import('@pages/events/CreateUpdatePeriodicEvent'));
  const CreateUpdateNotificationScreen = lazy(
    () => import('@pages/notification/CreateUpdateNotificationScreen')
  );
  const ListNotificationScreen = lazy(() => import('@pages/notification/ListNotificationScreen'));
  const NotificationDetail = lazy(() => import('@pages/notification/NotificationDetail'));
  const ListSystemEventDetail = lazy(() => import('@pages/system-event/ListSystemEventDetail'));
  const ListLoyaltyScreen = lazy(() => import('@pages/loyalty/ListLoyaltyScreen'));
  const AdminListScreen = lazy(() => import('@pages/admins/AdminListScreen'));
  const CreateUpdateAdminScreen = lazy(() => import('@pages/admins/CreateUpdateAdminScreen'));
  const AuthorizationScreen = lazy(() => import('@pages/admins/Authorization'));
  const StudyPlanListScreen = lazy(() => import('@pages/study-plans/StudyPlanListScreen'));
  const CreateUpdateStudyPlanScreen = lazy(
    () => import('@pages/study-plans/CreateUpdateStudyPlanScreen')
  );
  const SchoolReferralListScreen = lazy(
    () => import('@pages/school-referral/SchoolReferralListScreen')
  );
  const CreateUpdateSchoolReferralScreen = lazy(
    () => import('@pages/school-referral/CreateUpdateSchoolReferralScreen')
  );
  const LearningGoalScreen = lazy(() => import('@pages/students/LearningGoalScreen'));
  const LearningReportScreen = lazy(() => import('@pages/students/LearningReportScreen'));
  const CreateUpdateManualNotificationScreen = lazy(
    () => import('@pages/notification/CreateUpdateManualNotificationScreen')
  );
  const ReportMainListScreen = lazy(() => import('@pages/report/MainList'));
  const ReportEventResult = lazy(() => import('@pages/report/ReportEventResult'));
  const ReportEventResultDetail = lazy(() => import('@pages/report/ReportEventResultDetail'));
  const ReportSystemEventResult = lazy(() => import('@pages/report/ReportSystemEventResult'));
  const ReportSystemEventResultDetail = lazy(
    () => import('@pages/report/ReportSystemEventResultDetail')
  );
  const ReportStudent = lazy(() => import('@pages/report/ReportStudent'));
  const ReportStudentDetail = lazy(() => import('@pages/report/ReportStudentDetail'));
  const ReportRanking = lazy(() => import('@pages/report/ReportRanking'));
  const ListLearningComboScreen = lazy(
    () => import('@pages/learning-combo/ListLearningComoScreen')
  );
  const LearningComboNewScreen = lazy(() => import('@pages/learning-combo/LearningComboNewScreen'));
  const LearningComboUpdateScreen = lazy(
    () => import('@pages/learning-combo/LearningComboUpdateScreen')
  );
  const LearningComboDetailScreen = lazy(
    () => import('@pages/learning-combo/LearningComboDetailScreen')
  );
  const BannerSlide = lazy(() => import('@pages/banner-slide/BannerSlide'));
  const ChatbotConfigScreen = lazy(() => import('@pages/chatbot-config/ChatbotConfig'));
  const UpdateProfileScreen = lazy(() => import('@pages/update-profile/UpdateProfile'));
  const AffiliateReportScreen = lazy(() => import('@pages/report/AffiliateReport'));
  const AffiliateReferralReportScreen = lazy(() => import('@pages/report/AffiliateReferralReport'));

  return [
    { path: AppRoutes.HOME, exact: true, name: `Homepage` },
    { path: AppRoutes.DASHBOARD, exact: true, name: `Trung Tâm` },
    { path: AppRoutes.ANCESTORS, name: `Phụ Huynh`, component: Ancestors },
    // { path: AppRoutes.ANCESTOR_DETAIL, name: `Chỉnh sửa thông tin phụ huynh`, component: Ancestor },
    // { path: AppRoutes.ANCESTOR_NEW, name: `Tạo mới tài khoản phụ huynh`, component: Ancestor },
    { path: AppRoutes.STUDENTS, name: `Học Sinh`, component: Students },
    { path: AppRoutes.EXAM, name: `Quản Lý Đề Thi`, component: ExamQuestion },
    { path: AppRoutes.UNITS, name: `Khối / Lớp`, component: UnitList },
    { path: AppRoutes.SUBJECT, name: `Môn Học`, component: SubjectList },
    { path: AppRoutes.THEMATIC, name: `Chuyên Đề`, component: ThematicList },
    {
      path: AppRoutes.EXAM_DETAIL,
      name: `Chi Tiết Đề Thi`,
      component: DetailExamQuestion,
      role: VIEW_TEST
    },
    { path: AppRoutes.PRACTICES, name: `Quản Lý Bài Thực Hành`, component: Practices },
    {
      path: AppRoutes.PRACTICE_DETAIL,
      name: `Chi Tiết Thực Hành`,
      component: DetailPractice,
      role: VIEW_PRACTICE
    },
    { path: AppRoutes.KNOWLEDGE, name: `Quản Lý Kiến Thức`, component: Knowledge1 },
    {
      path: AppRoutes.KNOWLEDGE_DETAIL,
      name: `Chi Tiết Kiến Thức`,
      component: DetailKnowledge,
      role: VIEW_KNOWLEDGE
    },
    { path: AppRoutes.QUESTIONS, name: `Quản Lý Câu Hỏi`, component: Questions },
    { path: AppRoutes.QUESTION_NEW, name: `Thêm Câu Hỏi`, component: CreateUpdateQuestion },
    {
      path: `${AppRoutes.QUESTION_EDIT}/:id`,
      name: `Chỉnh Sửa Câu Hỏi`,
      component: CreateUpdateQuestion
    },
    { path: AppRoutes.VIDEO, name: `Quản Lý Video`, component: Videos },
    { path: AppRoutes.LEARNING_FILE, name: `Quản Lý Gói Học Tập`, component: LearningFile },
    {
      path: AppRoutes.LEARNING_FILE_DETAIL,
      name: `Chi Tiết Gói Học Tập`,
      component: DetailLearningPack,
      role: VIEW_LEARN
    },
    {
      path: AppRoutes.ORDERS,
      name: `Quản Lý Đơn Hàng`,
      component: ListOrderScreen
    },
    {
      path: AppRoutes.ORDERS_DETAIL,
      name: `Chi tiết đơn hàng`,
      component: OrderDetail,
      role: VIEW_ORDER
    },
    {
      path: AppRoutes.EVENT_PERIODIC,
      name: `Quản Lý Sự Kiện Định Kỳ`,
      component: EventPeriodicScreen
    },
    {
      path: AppRoutes.EVENT_PERIODIC_NEW,
      name: `Thêm Lịch Thi`,
      component: CreateUpdatePeriodicEvent,
      role: CREATE_TEST_SCHEDULE
    },
    {
      path: AppRoutes.EVENT_PERIODIC_UPDATE,
      name: `Chỉnh Sửa Lịch Thi`,
      component: CreateUpdatePeriodicEvent,
      role: UPDATE_TEST_SCHEDULE
    },
    {
      path: AppRoutes.EVENT_PERIODIC_DETAIL,
      name: `Chi Tiết Lịch Thi`,
      component: EventPeriodicDetailScreen,
      role: VIEW_TEST_SCHEDULE
    },
    {
      path: AppRoutes.SYSTEM_EVENTS,
      name: `Quản Lý Sự Kiện Hệ Thống`,
      component: ListSystemEventScreen
    },
    {
      path: AppRoutes.SYSTEM_EVENTS_UPDATE,
      name: `Chỉnh Sửa Sự Kiện Hệ Thống`,
      component: SystemEventScreenUpdate,
      role: UPDATE_EVENT
    },
    {
      path: AppRoutes.SYSTEM_EVENTS_NEW,
      name: `Thêm Sự Kiện Hệ Thống`,
      component: SystemEventNewScreen,
      role: CREATE_EVENT
    },
    {
      path: AppRoutes.SYSTEM_EVENTS_DETAIL,
      name: `Chi tiết Sự Kiện Hệ Thống`,
      component: ListSystemEventDetail,
      role: VIEW_EVENT
    },
    {
      path: AppRoutes.NOTIFICATION,
      name: `Quản Lý Thông Báo`,
      component: ListNotificationScreen
    },
    {
      path: AppRoutes.NOTIFICATION_UPDATE,
      name: `Chỉnh Sửa Thông Báo Tự Động`,
      component: CreateUpdateNotificationScreen,
      role: UPDATE_NOTIFICATION
    },
    {
      path: AppRoutes.NOTIFICATION_NEW,
      name: `Thiết Lập Thông Báo Tự Động`,
      component: CreateUpdateNotificationScreen,
      role: CREATE_NOTIFICATION
    },
    {
      path: AppRoutes.NOTIFICATION_DETAIL,
      name: `Chi tiết Thông Báo Tự Động`,
      component: NotificationDetail,
      role: VIEW_NOTIFICATION
    },
    {
      path: AppRoutes.LOYALTY_LIST,
      name: `Quản Lý Loyalty`,
      component: ListLoyaltyScreen
    },
    {
      path: AppRoutes.LOYALTY_NEW,
      name: `Thêm Mã Khuyến Mãi`,
      component: CreateUpdateLoyaltyScreen,
      role: CREATE_PROMOTION
    },
    {
      path: AppRoutes.LOYALTY_UPDATE,
      name: `Quản Lý Loyalty`,
      component: CreateUpdateLoyaltyScreen,
      role: UPDATE_PROMOTION
    },
    {
      path: AppRoutes.CONFIG_POINT,
      name: `Cấu Hình Tích Điểm`,
      component: ConfigPointScreen,
      role: CONFIG_PROMOTION
    },
    {
      path: AppRoutes.GIFT_LIST,
      name: `Quản Lý Quà Tặng`,
      component: ListGiftScreen
    },
    {
      path: AppRoutes.GIFT_NEW,
      name: `Thêm Quà Tặng`,
      component: CreateUpdateGiftScreen,
      role: CREATE_GIFT
    },
    {
      path: AppRoutes.GIFT_UPDATE,
      name: `Chỉnh Sửa Quà Tặng`,
      component: CreateUpdateGiftScreen,
      role: UPDATE_GIFT
    },
    {
      path: AppRoutes.ADMIN_LIST,
      name: `Quản Lý Thành Viên Quản Trị`,
      component: AdminListScreen
    },
    {
      path: AppRoutes.ADMIN_NEW,
      name: `Thêm Thành Viên Quản Trị`,
      component: CreateUpdateAdminScreen,
      role: CREATE_ADMIN
    },
    {
      path: AppRoutes.ADMIN_UPDATE,
      name: `Chỉnh Sửa Thành Viên Quản Trị`,
      component: CreateUpdateAdminScreen,
      role: UPDATE_ADMIN
    },
    {
      path: AppRoutes.ADMIN_AUTHORIZATION,
      name: `Phân Quyền Quản Trị Viên`,
      component: AuthorizationScreen,
      role: PERMISSION_ADMIN
    },
    {
      path: AppRoutes.STUDY_PLAN,
      name: `Lộ Trình Phù Hợp`,
      component: StudyPlanListScreen
    },
    {
      path: AppRoutes.STUDY_PLAN_NEW,
      name: `Thêm Lộ Trình`,
      component: CreateUpdateStudyPlanScreen,
      role: CREATE_STUDY_PLAN
    },
    {
      path: AppRoutes.STUDY_PLAN_UPDATE,
      name: `Sửa Lộ Trình`,
      component: CreateUpdateStudyPlanScreen,
      role: UPDATE_STUDY_PLAN
    },
    {
      path: AppRoutes.SCHOOL_REFERRAL,
      name: `Nguồn giới thiệu`,
      component: SchoolReferralListScreen
    },
    {
      path: AppRoutes.SCHOOL_REFERRAL_NEW,
      name: `Thêm nguồn giới thiệu`,
      component: CreateUpdateSchoolReferralScreen
    },
    {
      path: AppRoutes.SCHOOL_REFERRAL_UPDATE,
      name: `Sửa nguồn giới thiệu`,
      component: CreateUpdateSchoolReferralScreen
    },
    {
      path: AppRoutes.LEARNING_GOALS,
      name: `Mục Tiêu Học Tập`,
      component: LearningGoalScreen,
      role: VIEW_STUDENT_STUDY_GOALS
    },
    {
      path: AppRoutes.MANUAL_NOTIFICATION_NEW,
      name: `Tạo Thông Báo Thủ Công`,
      component: CreateUpdateManualNotificationScreen,
      role: CREATE_MANUAL_NOTIFICATION
    },
    {
      path: AppRoutes.REPORT_LEARNING,
      name: `Báo Cáo Học Tập`,
      component: LearningReportScreen,
      role: VIEW_STUDENT_REPORT
    },
    {
      path: AppRoutes.REPORT_MAIN_LIST,
      name: `Báo cáo - Thống kê`,
      component: ReportMainListScreen,
      role: VIEW_STUDENT_REPORT
    },
    {
      path: AppRoutes.REPORT_EVENT_RESULT,
      name: `Kết quả sự kiện định kỳ`,
      component: ReportEventResult,
      role: VIEW_STUDENT_REPORT
    },
    {
      path: AppRoutes.REPORT_EVENT_RESULT_DETAIL,
      name: `Chi tiết kết quả sự kiện định kỳ`,
      component: ReportEventResultDetail,
      role: VIEW_STUDENT_REPORT
    },
    {
      path: AppRoutes.REPORT_SYSTEM_EVENT_RESULT,
      name: `Kết quả sự kiện hệ thống`,
      component: ReportSystemEventResult,
      role: VIEW_STUDENT_REPORT
    },
    {
      path: AppRoutes.REPORT_SYSTEM_EVENT_RESULT_DETAIL,
      name: `Chi tiết kết quả sự kiện hệ thống`,
      component: ReportSystemEventResultDetail,
      role: VIEW_STUDENT_REPORT
    },
    {
      path: AppRoutes.REPORT_STUDENT,
      name: 'Kết quả học viên',
      component: ReportStudent,
      role: VIEW_STUDENT_REPORT
    },
    {
      path: AppRoutes.REPORT_STUDENT_DETAIL,
      name: 'Kết quả chi tiết học viên',
      component: ReportStudentDetail,
      role: VIEW_STUDENT_REPORT
    },
    // {
    //   path: AppRoutes.REPORT_SHARED_PERSON,
    //   name: 'Người được chia sẻ',
    //   component: ReportSharedPerson,
    //   role: VIEW_STUDENT_REPORT
    // },
    {
      path: AppRoutes.RANKING_REPORT,
      name: `Bảng xếp hạng`,
      component: ReportRanking,
      role: RANKING_REPORT
    },
    {
      path: AppRoutes.COMBO_LEARNING_PACKAGE,
      name: `Quản lý gói combo`,
      component: ListLearningComboScreen,
      role: VIEW_LEARN
    },
    {
      path: AppRoutes.BANNER_SLIDE,
      name: `Quản lý slide`,
      component: BannerSlide,
      role: VIEW_LEARN
    },
    {
      path: AppRoutes.COMBO_LEARNING_PACKAGE_NEW,
      name: `Thêm Combo gói học tập`,
      component: LearningComboNewScreen,
      role: CREATE_LEARN
    },
    {
      path: AppRoutes.COMBO_LEARNING_PACKAGE_UPDATE,
      name: `Chỉnh sửa Combo gói học tập`,
      component: LearningComboUpdateScreen,
      role: UPDATE_LEARN
    },
    {
      path: AppRoutes.COMBO_LEARNING_PACKAGE_DETAIL,
      name: `Chi tiết Combo gói học tập`,
      component: LearningComboDetailScreen,
      role: VIEW_LEARN
    },
    {
      path: AppRoutes.CHATBOT_CONFIG,
      name: `Cấu hình chatbot`,
      component: ChatbotConfigScreen,
      role: VIEW_LEARN // TODO: add role for chatbot
    },
    {
      path: AppRoutes.UPDATE_PROFILE,
      name: `Update Profile`,
      component: UpdateProfileScreen,
      role: VIEW_USER
    },
    {
      path: AppRoutes.AFFILIATE_REPORT,
      name: `thống kê người được chia sẻ`,
      component: AffiliateReportScreen,
      role: AFFILIATE_REPORT
    },
    {
      path: AppRoutes.AFFILIATE_REFERRAL_REPORT,
      name: `Thống kê nguồn giới thiệu`,
      component: AffiliateReferralReportScreen,
      //role: AFFILIATE_REFERRAL_REPORT
    }
  ];
};

export default useRouters;
