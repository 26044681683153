import { ApiEndpoint } from '@api/constants';
import { SemesterApi, SubjectApi, ThematicApi, UnitApi } from '@api/services';
import { apiGet } from '@api/services/services';
import { getCommonSelectSuccess } from '@reducers/actions/CommonOptionThunk';
import { selectCommonSelectList } from '@reducers/selectors/commonOptions';
import { isEmpty, isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useDeepCompareMemoize(value) {
  const ref = useRef();
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDeepCompareEffect(callback, dependencies) {
  return useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

export function useDeepCompareCallback(callback, dependencies) {
  return useCallback(callback, dependencies.map(useDeepCompareMemoize));
}

export function useDeepCompareMemo(callback, dependencies) {
  return useMemo(callback, dependencies.map(useDeepCompareMemoize));
}

const fetchDetailOptions = (setLoading, cb: () => void) => {
  const request = {
    pageIndex: 0,
    pageSize: 999
  };

  const optionPromises = [
    UnitApi.list(request),
    SubjectApi.list(request),
    ThematicApi.list(request),
    SemesterApi.list(request)
  ];
  setLoading(true);
  Promise.allSettled(optionPromises).then((results) => {
    const options = ['unit', 'subject', 'thematic', 'semester'];
    const newData = results.reduce((acc, item, idx) => {
      const newItem = item.value?.data?.data.map((i) => ({
        ...i,
        value: String(i.id),
        label: i.name
      }));
      acc[options[idx]] = newItem;
      return acc;
    }, {});
    setLoading(false);
    cb(newData);
  });
};
export type DetailCommonOptionsResponse = {
  unit: [
    {
      id: number,
      name: string,
      semesters: [
        {
          id: number,
          name: string
        },
        {
          id: number,
          name: string
        }
      ],
      created_at: string,
      updated_at: string,
      value: string,
      label: string
    },
  ],
  subject: [
    {
      id: number,
      name: string,
      created_at: string,
      updated_at: string,
      value: string,
      label: string
    },
  ],
  thematic: [
    {
      id: 108,
      name: string,
      description: null,
      subject: {
        id: number,
        name: string
      },
      semester: {
        id: number,
        name: string
      },
      unit: {
        id: number,
        name: string
      },
      knowledge_count: 0,
      created_at: string,
      updated_at: string,
      value: string,
      label: string
    }
  ],
  semester: [
    {
      id: number,
      name: string,
      unit: {
        id: number,
        name: string
      },
      created_at: string,
      updated_at: string,
      value: string,
      label: string
    }
  ]
}

export function useDetailCommonOptions() {
  const [selectOptions, setSelectOptions] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDetailOptions(setLoading, (data) => setSelectOptions(data));
  }, []);

  return [loading, selectOptions, setSelectOptions];
}

const fetchOptions = (cb: () => void) => {
  const unitPromise = apiGet({ path: '/' + ApiEndpoint.OPTIONS_UNIT });
  const subjectPromise = apiGet({ path: '/' + ApiEndpoint.OPTIONS_SUBJECT });
  const semesterPromise = apiGet({ path: '/' + ApiEndpoint.OPTIONS_SEMESTER });
  const thematicPromise = apiGet({ path: '/' + ApiEndpoint.OPTIONS_THEMATIC });

  const optionPromises = [unitPromise, subjectPromise, semesterPromise, thematicPromise];

  Promise.allSettled(optionPromises).then((results) => {
    const options = ['unit', 'subject', 'semester', 'thematic'];
    const newData = results.reduce((acc, item, idx) => {
      const newItem = item.value.data.data;
      acc[options[idx]] = newItem;

      return acc;
    }, {});
    cb(newData);
  });
};

export function useCommonOptions() {
  const dispatch = useDispatch();
  const selectOptions = useSelector(selectCommonSelectList());

  useEffect(() => {
    if (isEmpty(selectOptions)) {
      fetchOptions((data) => dispatch(getCommonSelectSuccess(data)));
    }
  }, []);

  return [selectOptions];
}

export function useCommonRequest(option, filters = {}) {
  const [opt, setOpt] = useState();
  useEffect(() => {
    async function fetchData() {
      const {
        data: { data }
      } = await apiGet({
        path: `/api/options/${option}`,
        body: { ...filters, page: -1, size: 999 }
      });
      setOpt(data);
    }
    fetchData();
  }, []);
  return opt;
}
