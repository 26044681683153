import React, { memo, ReactNode, FC } from 'react';
import { Button } from 'antd';

import './styles.scss';

interface IButtonProps {
  onClick?: () => void;
  children?: ReactNode;
  [key: string]: any;
}

const UIButton: FC<IButtonProps> = ({ onClick, children, ...props }) => {
  const handleClick = (e: any) => {
    e.preventDefault();
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <Button className="bittus-admin-button" onClick={handleClick} {...props}>
      {children}
    </Button>
  );
};

export default memo(UIButton);
