import { ApiEndpoint } from '@api/constants';
import { apiGet } from '@api/services/services';
import { ErrorNoti } from '@common/components/Notification/Notification';

export enum EventPeriodicConstant {
  GET_EVENT_PERIODIC_REQUEST = 'GET_EVENT_PERIODIC_REQUEST',
  GET_EVENT_PERIODIC_SUCCESS = 'GET_EVENT_PERIODIC_SUCCESS'
}

export const getEventPeriodicRequest = (params) => async (dispatch) => {
  try {
    dispatch({ type: EventPeriodicConstant.GET_EVENT_PERIODIC_REQUEST });

    const { data } = await apiGet({
      path: ApiEndpoint.EVENT_PERIODIC,
      body: params
    });

    dispatch(getEventPeriodicSuccess(data));
  } catch (error) {
    ErrorNoti(error?.response?.data?.message);
  }
};

export const getEventPeriodicSuccess = (payload) => ({
  type: EventPeriodicConstant.GET_EVENT_PERIODIC_SUCCESS,
  payload
});
