import Button from '@common/components/Button/Button';
import ActionRenderer from '@common/components/CellRenderer/ActionRenderer';
import CommonTable from '@common/components/CommonTable/CommonTable';
import Select from '@common/components/Select/Select';
import { CHANGE_REQUEST_STATUS } from '@common/constants/constantType';
import { formatDate, mapReduce } from '@common/utils';
import { getChangeGiftRequest } from '@reducers/actions/GiftReducerThunk';
import { selectChangeGiftList } from '@reducers/selectors/gift';
import { Col, DatePicker, Form, Input, Row, Tag } from 'antd';
import { EditOutlined, CheckOutlined } from '@ant-design/icons';
import { cilBusAlt } from '@coreui/icons';

import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { updateCommonRequest } from '@reducers/actions/CommonOptionThunk';
import CIcon from '@coreui/icons-react';

const { RangePicker } = DatePicker;
const mapStatus = mapReduce(CHANGE_REQUEST_STATUS, 'value', (item) => item);

const filterComponent = ({ handleChangeDate, handleChangeFilter, handleSearch }) => (
  <Form layout="vertical">
    <Row gutter={[32, 0]}>
      <Col span={6}>
        <Form.Item label="Ngày Tạo (Từ ngày - Đến ngày)">
          <RangePicker
            format="DD/MM/YYYY"
            onChange={handleChangeDate}
            placeholder={['Bắt đầu', 'Kết thúc']}
          />
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item label="Trạng Thái">
          <Select
            options={CHANGE_REQUEST_STATUS}
            onChange={(e) => handleChangeFilter(e, 'status')}
            placeholder="Tất cả"
            allowClear
          />
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item label="Từ Khóa">
          <Input onChange={handleSearch} placeholder="Nhập từ khóa" />
        </Form.Item>
      </Col>
    </Row>
  </Form>
);
const apiParamInfos = { entity: 'gift-exchanges', message: 'Yêu Cầu Đổi Quà Tặng' };

const ListChangeGift = memo(() => {
  const {
    data: { data, meta },
    loading
  } = useSelector(selectChangeGiftList());

  const updateStatusGift = (id: string, status: string, getLists: () => void) => {
    updateCommonRequest({ ...apiParamInfos, id, status }, getLists);
  };

  const columns = useMemo(
    () => [
      {
        title: 'STT',
        render: (_, record, index: number) => String(meta?.from + index)
      },
      {
        title: 'Ngày Đổi',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (date: Date) => formatDate(date),
        sortable: true
      },
      {
        title: 'Tên Món Quà',
        dataIndex: 'name',
        key: 'name',
        sortable: true
      },
      {
        title: 'Hình Ảnh Minh Hoạ',
        dataIndex: 'event',
        key: 'event'
        // render: (record: string) => record && mapEvent[record]
      },
      {
        title: 'Số Điểm Cần Đổi',
        dataIndex: 'point',
        key: 'point'
      },
      {
        title: 'ID Học Viên',
        dataIndex: 'user',
        key: 'student_id',
        render: (user) => user.code
      },
      {
        title: 'Họ và Tên',
        dataIndex: 'user',
        key: 'username',
        render: (user) => user.name
      },
      {
        title: 'Số Điện Thoại',
        dataIndex: 'phone',
        key: 'phone'
      },
      {
        title: 'Ngày tháng năm sinh',
        dataIndex: 'birthday',
        key: 'birthday'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email'
      },
      {
        title: 'Trạng Thái',
        dataIndex: 'status',
        key: 'status',
        render: (status: string) => {
          const { label, color } = mapStatus[status];
          return status && <Tag color={color}>{label}</Tag>;
        }
      }
      // {
      //   title: 'Thao tác',
      //   render: (record) => (
      //     <>
      //       <Button onClick={() => handleCreateUpdateItem(record.id)} icon={<EditOutlined />} />
      //       <Button onClick={() => handleCreateUpdateItem(record.id)} icon={<EditOutlined />} />
      //       <Button onClick={() => handleCreateUpdateItem(record.id)} icon={<EditOutlined />} />
      //       <Button onClick={() => handleCreateUpdateItem(record.id)} icon={<EditOutlined />} />
      //     </>
      //   )
      // }
    ],
    [meta?.from]
  );
  const actionRenderer = ({ getLists, record }) => (
    <>
      <Button
        onClick={() => updateStatusGift(record.id, 'SHIPPING', getLists)}
        icon={<EditOutlined />}
      />
      <Button
        onClick={() => updateStatusGift(record.id, 'DELIVERED', getLists)}
        icon={<EditOutlined />}
      />
      <Button
        type="danger"
        onClick={() => updateStatusGift(record.id, 'CANCEL', getLists)}
        icon={<CIcon icon={cilBusAlt} />}
      />
    </>
  );
  return (
    <div>
      <CommonTable
        loading={loading}
        tableData={{ data, meta }}
        filterComponent={filterComponent}
        columns={columns}
        getListRequest={getChangeGiftRequest}
        viewListRole="REQUEST_GIFT"
        actionRenderer={actionRenderer}
      />
    </div>
  );
});

ListChangeGift.propTypes = {};

export default ListChangeGift;
