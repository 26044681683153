/* eslint-disable arrow-body-style */
import { IAccessToken, IUser } from '@api/interfaces';
import AuthenticateApi from '@api/services/AuthenticateApi';
import axiosInstance from '@api/services/axios';
import { getStorage, removeStorage, setStorage } from '@api/services/services';
import Environment from '@common/constants/Environment';
import { UserReducerActions } from '@reducers/actions';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SanctumContext from './SanctumContext';

export interface ConfigProps {
  apiUrl: string;
  csrfCookieRoute: string;
  signInRoute: string;
  signOutRoute: string;
  userObjectRoute: string;
  usernameKey?: string;
}

interface Props {
  children: React.ReactNode;
}

const Sanctum: React.FC<Props> = (props) => {
  const { children } = props;
  const dispatch = useDispatch();

  const accessToken = (getStorage('accessToken') && JSON.parse(getStorage('accessToken'))) || '{}';
  const userStorage = (getStorage('user') && JSON.parse(getStorage('user'))) || '{}';

  const [userToken, setUserToken] = useState<IAccessToken | null>(null);
  const [sanctumState, setSanctumState] = useState<{
    user: IUser | null;
    authenticated: null | boolean;
  }>(() => {
    if (!userStorage) {
      dispatch({
        type: UserReducerActions.SAVE,
        payload: userStorage
      });

      return { user: userStorage, authenticated: true };
    }

    return { user: null, authenticated: false };
  });
  const { user, authenticated } = sanctumState;

  useEffect(() => {
    if (accessToken.token) {
      axiosInstance.defaults.headers.common = {
        ...axios.defaults.headers.common,
        ...{ Authorization: 'Bearer ' + accessToken.token }
      };
      setUserToken(accessToken);
    }

    if (user) {
      const userData: IUser = user;
      const fetchHealthCheck = async () => {
        await healthCheck();
        setUser(userData, true);
      };

      fetchHealthCheck();
    }

    if (accessToken.token && !user) {
      const fetchUserData = async () => {
        const userData = await revalidate();
        dispatch({
          type: UserReducerActions.SAVE,
          payload: userData
        });
        setUser(userData, true);
      };

      fetchUserData();
    }
  }, []);

  useEffect(() => {
    if (userToken) {
      dispatch({
        type: UserReducerActions.TOKEN_SAVE,
        payload: userToken
      });
    }
  }, [userToken]);

  // useRenderingTrace("Sanctum", { userToken, sanctumState, children }, "debug");

  const signIn = (
    username: string,
    password: string,
    remember: boolean = false
  ): Promise<{ signedIn: boolean; user: IUser | null }> => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        // Get CSRF cookie.
        await AuthenticateApi.csrf();

        const loginResponse = await AuthenticateApi.signIn({ email: username, password });

        dispatch({
          type: UserReducerActions.TOKEN_SAVE,
          payload: loginResponse.data.data
        });

        setUserToken(loginResponse.data.data);
        axiosInstance.defaults.headers.common = {
          ...axiosInstance.defaults.headers.common,
          ...{ Authorization: 'Bearer ' + loginResponse.data.data.token }
        };

        // Fetch user.
        const _user = await revalidate();

        dispatch({
          type: UserReducerActions.SAVE,
          payload: _user
        });

        return resolve({ signedIn: true, user: _user });
      } catch (error) {
        return reject(error);
      }
    });
  };

  const signOut = () => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        AuthenticateApi.signOut().then(() => {
          dispatch({ type: UserReducerActions.RESET });

          // Only sign out after the server has successfully responded.
          setSanctumState({ user: null, authenticated: false });

          // Clear localstorage
          removeStorage('accessToken');

          // Set default axios
          axiosInstance.defaults.headers.common = axios.defaults.headers.common;
          AuthenticateApi.hasCsrf = false;

          resolve();
        });
      } catch (error) {
        return reject(error);
      }
    });
  };

  const setUser = (user: IUser | null, authenticated: boolean = true) => {
    setSanctumState({ user, authenticated });
  };

  const revalidate = (): Promise<null | IUser> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await AuthenticateApi.me();
        if (response) {
          setUser(response.data.data);
          setStorage({ key: 'user', val: JSON.stringify(response.data.data) });

          resolve(response.data.data);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.status === 401) {
            // If there's a 401 error the user is not signed in.
            setUser(null, false);
            return resolve(null);
          } else {
            // If there's any other error, something has gone wrong.
            return reject(error);
          }
        } else {
          return reject(error);
        }
      }
    });
  };

  const healthCheck = (): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      try {
        await AuthenticateApi.healthCheck();
        return resolve(true);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.status === 401) {
            // If there's a 401 error the user is not signed in.
            setSanctumState({ user: null, authenticated: false });

            return resolve(false);
          } else {
            // If there's any other error, something has gone wrong.
            return reject(error);
          }
        } else {
          return reject(error);
        }
      }
    });
  };

  const checkAuthentication = (): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      if (authenticated === null) {
        // The status is null if we haven't checked it, so we have to make a request.
        try {
          await revalidate();

          return resolve(true);
        } catch (error) {
          if (axios.isAxiosError(error)) {
            if (error.response && error.response.status === 401) {
              // If there's a 401 error the user is not signed in.
              setSanctumState({ user: null, authenticated: false });

              return resolve(false);
            } else {
              // If there's any other error, something has gone wrong.
              return reject(error);
            }
          } else {
            return reject(error);
          }
        }
      } else {
        try {
          await AuthenticateApi.healthCheck();
          return resolve(true);
        } catch (error) {
          if (axios.isAxiosError(error)) {
            if (error.response && error.response.status === 401) {
              // If there's a 401 error the user is not signed in.
              setSanctumState({ user: null, authenticated: false });

              return resolve(false);
            } else {
              // If there's any other error, something has gone wrong.
              return reject(error);
            }
          } else {
            return reject(error);
          }
        }
      }
    });
  };

  return (
    <SanctumContext.Provider
      children={children}
      value={{
        user,
        authenticated,
        signIn,
        signOut,
        setUser,
        checkAuthentication
      }}
    />
  );
};

export default Sanctum;
