import { ApiEndpoint } from '@api/constants';
import { apiGet } from '@api/services/services';
import Footer from '@common/components/Footer/Footer';
import UIField from '@common/components/UIField/UIField';
import UIUpload from '@common/components/UIUpload/UIUpload';
import { AppRoutes } from '@common/constants';
import { IMAGE_ERR_MSG, PROMO_TYPE } from '@common/constants/constantType';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDeepCompareEffect } from '@layout/hooks';
import {
  addCommonRequest,
  updateCommonPostRequest,
  updateCommonRequest
} from '@reducers/actions/CommonOptionThunk';
import { Col, DatePicker, Form, Input, InputNumber, Row } from 'antd';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import locale from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import { dateFormat, formatDate } from '@common/utils';

const { TextArea } = Input;

const apiParamInfos = { entity: 'gifts', message: 'Quà Tặng' };
const disabledStartDate = (current) => current && current < moment().endOf('day');

const schema = yup
  .object({
    name: yup.string().required('Bắt buộc nhập tên món quà'),
    point: yup.number().nullable().required('Bắt buộc nhập số điểm cần đổi'),
    quantity: yup.number().nullable().required('Bắt buộc nhập số lượng tối đa'),
    start_at: yup.string().nullable().required('Bắt buộc chọn ngày bắt đầu hiệu lực'),
    end_at: yup.string().nullable().required('Bắt buộc chọn ngày kết thúc hiệu lực')
  })
  .required();

const CreateUpdateGiftScreen = memo(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [item, setItem] = useState();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [imgErrMsg, setImgErrMsg] = useState('');
  const [imgSrc, setImgSrc] = useState();

  const formProps = useForm({
    resolver: yupResolver(schema)
  });

  const { watch, trigger, getValues, reset } = formProps;

  const [watchType, watchStartTime] = watch(['type', 'start_at']);
  const disabledEndDate = (current) => current && current < moment(watchStartTime).endOf('day');

  const handleCancel = () => navigate(AppRoutes.GIFT_LIST);

  const getItem = async () => {
    if (id) {
      const {
        data: { data }
      } = await apiGet({
        path: `${ApiEndpoint.GIFT}/${id}`
      });
      setItem(data);
    }
  };

  useEffect(() => {
    getItem();
  }, []);

  useDeepCompareEffect(() => {
    setImgSrc(item?.image);
  }, [item]);

  const onSubmit = async () => {
    const isValid = await trigger();
    if (!imgSrc) {
      setImgErrMsg(IMAGE_ERR_MSG);
      return;
    }

    if (isValid) {
      setDisableSubmit(true);
      const formValues = getValues();
      const payload = {
        ...formValues,
        ...apiParamInfos,
        image: imgSrc,
        start_at: moment(formValues.start_at).format(dateFormat),
        end_at: moment(formValues.end_at).format(dateFormat)
      };
      if (item) {
        updateCommonPostRequest(
          { ...payload, id, _method: 'PUT', image: typeof imgSrc === 'string' ? null : imgSrc },
          null,
          true
        ).then(() => handleCancel());
      } else {
        addCommonRequest(payload, null, true).then(() => handleCancel());
      }
    }
  };

  useDeepCompareEffect(() => {
    if (imgSrc) setImgErrMsg('');
  }, [imgSrc]);

  useDeepCompareEffect(() => {
    if (id) {
      reset({
        name: get(item, 'name'),
        point: get(item, 'point'),
        quantity: get(item, 'quantity'),
        start_at: moment(get(item, 'start_at'), dateFormat),
        end_at: moment(get(item, 'end_at'), dateFormat)
      });
    }
  }, [item]);

  return (
    <div style={{ backgroundColor: '#fff', padding: 16 }}>
      <Form layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={24} style={{ fontSize: 26, fontWeight: 'bold' }}>
            {id ? 'CHỈNH SỬA QUÀ TẶNG' : 'THÊM QUÀ TẶNG'}
          </Col>

          <Col span={8}>
            <UIField
              {...formProps}
              label="Tên Món Quà"
              name="name"
              placeholder="Nhập nội dung"
              fieldComponent={Input}
            />
          </Col>
          <Col span={8}>
            <UIField
              {...formProps}
              label="Số Điểm Cần Đổi"
              name="point"
              placeholder="Nhập nội dung"
              fieldComponent={InputNumber}
              fieldProps={{ min: 1, style: { width: '100%' } }}
            />
          </Col>
          <Col span={8}>
            <UIField
              {...formProps}
              label="Số Lượng Tối Đa"
              name="quantity"
              placeholder="Nhập nội dung"
              fieldComponent={InputNumber}
              fieldProps={{ min: 1, style: { width: '100%' } }}
            />
          </Col>
          <Col span={8}>
            <UIField
              {...formProps}
              label="Ngày bắt đầu hiệu lực"
              name="start_at"
              placeholder="dd/mm/yyyy"
              fieldComponent={DatePicker}
              fieldProps={{
                width: '100%',
                style: { width: '100%' },
                format: 'dddd, DD/MM/YYYY',
                disabledDate: disabledStartDate,
                locale
              }}
            />
          </Col>
          <Col span={8}>
            <UIField
              {...formProps}
              label="Ngày kết thúc hiệu lực"
              name="end_at"
              placeholder="dd/mm/yyyy"
              fieldComponent={DatePicker}
              fieldProps={{
                width: '100%',
                style: { width: '100%' },
                format: 'dddd, DD/MM/YYYY',
                disabledDate: disabledEndDate,
                disabled: !watchStartTime,
                locale
              }}
            />
          </Col>

          <Col span={8}>
            <UIUpload
              {...formProps}
              label="Hình minh họa"
              name="image"
              setFileForm={setImgSrc}
              defaultFile={item?.image}
              type="image"
              errMsg={imgErrMsg}
              setErrMsg={setImgErrMsg}
            />
          </Col>
        </Row>
      </Form>
      <Footer onCancel={handleCancel} onSubmit={onSubmit} disableSubmit={disableSubmit} />
    </div>
  );
});

CreateUpdateGiftScreen.propTypes = {};

export default CreateUpdateGiftScreen;
