import { ApiEndpoint } from "@api/constants";
import {
    TSemesterCreateRequest,
    TSemesterDeleteResponse,
    TSemesterOptionsResponse,
    TSemesterResponse,
    TSemestersRequest,
    TSemestersResponse,
    TSemesterUpdateRequest,
} from "@api/interfaces";
import axiosInstance from "@api/services/axios";
import { API_DOMAIN } from "@common/constants";
import { AxiosResponse } from "axios";

class SemesterApi {
    public static options = (): Promise<AxiosResponse<TSemesterOptionsResponse>> => axiosInstance.get(
        `${API_DOMAIN}/${ApiEndpoint.OPTIONS_SEMESTER}`,
        {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            maxRedirects: 0,
        },
    ).catch(error => error.handleGlobally && error.handleGlobally());

    public static list = (request: TSemestersRequest): Promise<AxiosResponse<TSemestersResponse>> => {
        const url = new URL(ApiEndpoint.SEMESTER, API_DOMAIN);
        url.searchParams.set("page", `${request.pageIndex}`);
        url.searchParams.set("size", `${request.pageSize}`);
        url.searchParams.set("filters", JSON.stringify(request.filters ?? []));
        url.searchParams.set("globalFilter", request.globalFilter ?? "");
        url.searchParams.set("sorting", JSON.stringify(request.sorting ?? []));

        // @ts-ignore
        return axiosInstance.get(
            url.toString(),
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        ).catch(error => error.handleGlobally && error.handleGlobally());
    };

    public static delete = (id: number | string): Promise<AxiosResponse<TSemesterDeleteResponse>> =>
        axiosInstance.delete(
            `${API_DOMAIN}/${ApiEndpoint.SEMESTER}/${id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                maxRedirects: 0,
            },
        ).catch(error => error.handleGlobally && error.handleGlobally());

    public static get = (id: number): Promise<AxiosResponse<TSemesterResponse>> => axiosInstance.get(
        `${API_DOMAIN}/${ApiEndpoint.SEMESTER}/${id}`,
        {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            maxRedirects: 0,
        },
    ).catch(error => error.handleGlobally && error.handleGlobally());

    public static update = (id: number, request: TSemesterUpdateRequest): Promise<AxiosResponse<TSemesterResponse>> =>
        axiosInstance.patch(
            `${API_DOMAIN}/${ApiEndpoint.SEMESTER}/${id}`,
            request,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                maxRedirects: 0,
            },
        ).catch(error => error.handleGlobally && error.handleGlobally());

    public static create = (request: TSemesterCreateRequest): Promise<AxiosResponse<TSemesterResponse>> =>
        axiosInstance.post(
            `${API_DOMAIN}/${ApiEndpoint.SEMESTER}`,
            request,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                maxRedirects: 0,
            },
        ).catch(error => error.handleGlobally && error.handleGlobally());
}

export default SemesterApi;
