/* eslint-disable new-cap */
import { ApiEndpoint } from '@api/constants';
import { apiGet } from '@api/services/services';
import { ErrorNoti } from '@common/components/Notification/Notification';

export enum GiftConstant {
  GET_LIST_GIFT_REQUEST = 'GET_LIST_GIFT_REQUEST',
  GET_LIST_GIFT_SUCCESS = 'GET_LIST_GIFT_SUCCESS',
  GET_LIST_CHANGE_GIFT_REQUEST = 'GET_LIST_CHANGE_GIFT_REQUEST',
  GET_LIST_CHANGE_GIFT_SUCCESS = 'GET_LIST_CHANGE_GIFT_SUCCESS'
}

export const getGiftRequest = (params: any) => async (dispatch: any) => {
  try {
    dispatch({ type: GiftConstant.GET_LIST_GIFT_REQUEST });

    const { data } = await apiGet({
      path: ApiEndpoint.GIFT,
      body: params
    });
    dispatch(getGiftSuccess(data));
  } catch (error) {
    ErrorNoti(error?.response?.data?.message);
  }
};

export const getGiftSuccess = (payload: any) => ({
  type: GiftConstant.GET_LIST_GIFT_SUCCESS,
  payload
});

export const getChangeGiftRequest = (params: any) => async (dispatch: any) => {
  try {
    dispatch({ type: GiftConstant.GET_LIST_CHANGE_GIFT_REQUEST });

    const { data } = await apiGet({
      path: ApiEndpoint.CHANGE_GIFT,
      body: params
    });
    dispatch(getChangeGiftSuccess(data));
  } catch (error) {
    ErrorNoti(error?.response?.data?.message);
  }
};

export const getChangeGiftSuccess = (payload: any) => ({
  type: GiftConstant.GET_LIST_CHANGE_GIFT_SUCCESS,
  payload
});
