import { NotificationConstant } from '@reducers/actions/NotificationThunk';
import { LoyaltyConstant } from './actions/LoyaltyThunk';
import { AdminConstant } from './actions/AdminReducerThunk';
import { notification } from 'antd';
import { LearningFileConstant } from './actions/LearningFileThunk';
import { IAccessToken } from '@api/interfaces';
import { produce } from 'immer';
import Environment from '@common/constants/Environment';
import { ExamQuestionThunk, SidebarReducerActions, UserReducerActions } from '@reducers/actions';
import { IReducerState } from '@reducers/interfaces';
import IReducerActions from './interfaces/IReducerActions';
import IUserReducerState from './interfaces/IUserReducerState';
import { QuestionType } from '@pages/exam-question/types';
import { PracticeThunk } from './actions/PracticeThunk';
import { KnowledgeThunk } from './actions/KnowledgeThunk';
import { QuestionThunk } from './actions/QuestionThunk';
import { CommonOptionConstant } from './actions/CommonOptionThunk';
import { AncestorConstant } from './actions/AncestorThunk';
import { setStorage } from '@api/services/services';
import { StudentConstant } from './actions/StudentThunk';
import { VideoConstant } from './actions/VideoThunk';
import { OrderConstant } from './actions/OrderThunk';
import { EventPeriodicConstant } from './actions/EventPeriodicThunk';
import { GiftConstant } from './actions/GiftReducerThunk';
import { StudyPlanConstant } from './actions/StudyPlanReducerThunk';
import { LearningGoalConstant } from './actions/LearningGoalThunk';
import { ReportReducerConstant } from './actions/ReportReducerThunk';
import { SchoolReferralConstant } from './actions/SchoolReferralReducerThunk';

const defaultState: IReducerState = {
  sidebarShow: true,
  sidebarUnfoldable: false,
  examQuestionPage: {
    examQuestionList: {
      data: [],
      loading: false
    }
  },
  practicePage: {
    practiceList: {
      data: [],
      loading: false
    }
  },
  knowledgePage: {
    knowledgeList: {
      data: [],
      loading: false
    }
  },
  questionPage: {
    questionList: {
      data: [],
      loading: false
    }
  },
  commonOption: {
    unit: [],
    thematic: [],
    semester: [],
    subject: [],
    loading: false
  },
  commonSelect: {},
  ancestor: {
    data: [],
    loading: false
  },
  student: {
    data: [],
    loading: false
  },
  video: {
    data: [],
    loading: false
  },
  learningPack: {
    data: [],
    loading: false
  },
  orders: {
    data: [],
    loading: false
  },
  eventPeriodic: {
    data: [],
    loading: false
  },
  notification: {
    data: [],
    loading: false
  },
  manualNotification: {
    data: [],
    loading: false
  },
  admin: {
    data: [],
    loading: false
  },
  gift: {
    data: [],
    loading: false
  },
  studyPlan: {
    data: [],
    loading: false
  },
  schoolReferral: {
    data: [],
    loading: false
  },
  loyalty: {
    data: [],
    loading: false
  },
  changeGift: {
    data: [],
    loading: false
  },
  learningGoal: {
    data: [],
    loading: false
  },
  reportRanking: {
    data: [],
    loading: false
  }
};

const initialState = {
  ...defaultState,
  ...JSON.parse(localStorage.getItem(Environment.StorageKeyName) || '{}')
};

export const Reducer = (state: IReducerState, action: IReducerActions): IReducerState =>
  produce(state, (draft) => {
    switch (action.type) {
      case SidebarReducerActions.SET:
        const newState = { ...state, ...action.payload };
        return newState;

      case UserReducerActions.TOKEN_SAVE:
        return saveToken(state, action.payload as IAccessToken);

      case UserReducerActions.SAVE:
        return saveUser(state, action.payload as IUserReducerState);

      case ExamQuestionThunk.GET_LIST_EXAM_QUESTION_REQUEST:
        draft.examQuestionPage.examQuestionList.loading = true;
        break;

      case ExamQuestionThunk.ADD_EXAM_QUESTION_SUCCESS:
        draft.examQuestionPage.examQuestionList.data.data.push(action.payload);
        draft.examQuestionPage.examQuestionList.loading = false;
        break;

      case ExamQuestionThunk.GET_LIST_EXAM_QUESTION_SUCCESS:
        draft.examQuestionPage.examQuestionList.data = action.payload;
        draft.examQuestionPage.examQuestionList.loading = false;
        break;

      case ExamQuestionThunk.UPDATE_QUESTION:
        {
          const { question: selectedQuestion, examId } = action.payload;
          const examQuestion = draft.examQuestionPage.examQuestionList.data.find(
            (exam) => exam.examId === examId
          );

          const idx = examQuestion.questions.findIndex(
            (question: QuestionType) => question.id === selectedQuestion.id
          );
          examQuestion.questions.splice(idx, 1, selectedQuestion);
        }
        break;

      case PracticeThunk.GET_LIST_PRACTICE_REQUEST:
        draft.practicePage.practiceList.loading = true;
        break;

      case PracticeThunk.GET_LIST_PRACTICE_SUCCESS:
        draft.practicePage.practiceList.data = action.payload;
        draft.practicePage.practiceList.loading = false;
        break;

      case KnowledgeThunk.GET_LIST_KNOWLEDGE_REQUEST:
        draft.knowledgePage.knowledgeList.loading = true;
        break;

      case KnowledgeThunk.GET_LIST_KNOWLEDGE_SUCCESS:
        draft.knowledgePage.knowledgeList.data = action.payload;
        draft.knowledgePage.knowledgeList.loading = false;
        break;

      case KnowledgeThunk.ADD_KNOWLEDGE:
        draft.knowledgePage.knowledgeList.data.push(action.payload);
        break;

      case QuestionThunk.GET_LIST_QUESTIONS_REQUEST:
        draft.questionPage.questionList.loading = true;
        break;

      case QuestionThunk.GET_LIST_QUESTIONS_SUCCESS:
        draft.questionPage.questionList.data = action.payload;
        draft.questionPage.questionList.loading = false;
        break;

      case CommonOptionConstant.GET_COMMON_OPTION_REQUEST:
        draft.commonOption.loading = true;
        break;

      case CommonOptionConstant.GET_UNIT_SUCCESS:
        draft.commonOption.unit = action.payload;
        draft.commonOption.loading = false;
        break;

      case CommonOptionConstant.GET_THEMATIC_SUCCESS:
        draft.commonOption.thematic = action.payload;
        draft.commonOption.loading = false;
        break;

      case CommonOptionConstant.GET_SUBJECT_SUCCESS:
        draft.commonOption.subject = action.payload;
        draft.commonOption.loading = false;
        break;

      case CommonOptionConstant.GET_SEMESTER_SUCCESS:
        draft.commonOption.semester = action.payload;
        draft.commonOption.loading = false;
        break;

      case CommonOptionConstant.GET_COMMON_SELECT_SUCCESS:
        draft.commonSelect = action.payload;
        break;

      case AncestorConstant.GET_LIST_ANCESTOR_REQUEST:
        draft.ancestor.loading = true;
        break;

      case AncestorConstant.GET_LIST_ANCESTOR_SUCCESS:
        draft.ancestor.data = action.payload;
        draft.ancestor.loading = false;
        break;

      case StudentConstant.GET_LIST_STUDENT_REQUEST:
        draft.student.loading = true;
        break;

      case StudentConstant.GET_LIST_STUDENT_SUCCESS:
        draft.student.data = action.payload;
        draft.student.loading = false;
        break;

      case VideoConstant.GET_VIDEO_REQUEST:
        draft.video.loading = true;
        break;

      case VideoConstant.GET_VIDEO_SUCCESS:
        draft.video.data = action.payload;
        draft.video.loading = false;
        break;

      case LearningFileConstant.GET_LIST_LEARNING_FILE_REQUEST:
        draft.learningPack.loading = true;
        break;

      case LearningFileConstant.GET_LIST_LEARNING_FILE_SUCCESS:
        draft.learningPack.data = action.payload;
        draft.learningPack.loading = false;
        break;

      case OrderConstant.GET_LIST_ORDER_REQUEST:
        draft.orders.loading = true;
        break;

      case OrderConstant.GET_LIST_ORDER_SUCCESS:
        draft.orders.data = action.payload;
        draft.orders.loading = false;
        break;

      case EventPeriodicConstant.GET_EVENT_PERIODIC_REQUEST:
        draft.eventPeriodic.loading = true;
        break;

      case EventPeriodicConstant.GET_EVENT_PERIODIC_SUCCESS:
        draft.eventPeriodic.data = action.payload;
        draft.eventPeriodic.loading = false;
        break;

      case NotificationConstant.GET_LIST_NOTIFICATION_REQUEST:
        draft.notification.loading = true;
        break;

      case NotificationConstant.GET_LIST_NOTIFICATION_SUCCESS:
        draft.notification.data = action.payload;
        draft.notification.loading = false;
        break;

      case AdminConstant.GET_LIST_ADMIN_REQUEST:
        draft.admin.loading = true;
        break;

      case AdminConstant.GET_LIST_ADMIN_SUCCESS:
        draft.admin.data = action.payload;
        draft.admin.loading = false;
        break;

      case GiftConstant.GET_LIST_GIFT_REQUEST:
        draft.gift.loading = true;
        break;

      case GiftConstant.GET_LIST_GIFT_SUCCESS:
        draft.gift.data = action.payload;
        draft.gift.loading = false;
        break;

      case StudyPlanConstant.GET_LIST_STUDY_PLAN_REQUEST:
        draft.studyPlan.loading = true;
        break;

      case StudyPlanConstant.GET_LIST_STUDY_PLAN_SUCCESS:
        draft.studyPlan.data = action.payload;
        draft.studyPlan.loading = false;
        break;

      case SchoolReferralConstant.GET_LIST_SCHOOL_REFERRAL_REQUEST:
        draft.schoolReferral.loading = true;
        break;

      case SchoolReferralConstant.GET_LIST_SCHOOL_REFERRAL_SUCCESS:
        draft.schoolReferral.data = action.payload;
        draft.schoolReferral.loading = false;
        break;

      case LoyaltyConstant.GET_LIST_LOYALTY_REQUEST:
        draft.loyalty.loading = true;
        break;
      case LoyaltyConstant.GET_LIST_LOYALTY_SUCCESS:
        draft.loyalty.data = action.payload;
        draft.loyalty.loading = false;
        break;

      case GiftConstant.GET_LIST_CHANGE_GIFT_REQUEST:
        draft.changeGift.loading = true;
        break;
      case GiftConstant.GET_LIST_CHANGE_GIFT_SUCCESS:
        draft.changeGift.data = action.payload;
        draft.changeGift.loading = false;
        break;

      case NotificationConstant.GET_LIST_MANUAL_NOTIFICATION_REQUEST:
        draft.manualNotification.loading = true;
        break;
      case NotificationConstant.GET_LIST_MANUAL_NOTIFICATION_SUCCESS:
        draft.manualNotification.data = action.payload;
        draft.manualNotification.loading = false;
        break;

      case LearningGoalConstant.GET_LIST_LEARNING_GOAL_REQUEST:
        draft.learningGoal.loading = true;
        break;
      case LearningGoalConstant.GET_LIST_LEARNING_GOAL_SUCCESS:
        draft.learningGoal.data = action.payload;
        draft.learningGoal.loading = false;
        break;

      case ReportReducerConstant.GET_LIST_REPORT_RANKING_REQUEST:
        draft.reportRanking.loading = true;
        break;
      case ReportReducerConstant.GET_LIST_REPORT_RANKING_SUCCESS:
        draft.reportRanking.data = action.payload;
        draft.reportRanking.loading = false;
        break;
      case UserReducerActions.RESET:
      default:
        return initialState;
    }
  });

const saveUser = (state: IReducerState, payload: IUserReducerState): IReducerState => {
  if (!payload.id) {
    return state;
  }

  const newState = { ...state, user: payload };

  setStorage({ key: 'user', val: JSON.stringify(payload) });

  return newState;
};

const saveToken = (state: IReducerState, payload: IAccessToken): IReducerState => {
  const newState: IReducerState = { ...state, accessToken: payload };
  setStorage({ key: 'accessToken', val: JSON.stringify(payload) });

  return newState;
};
