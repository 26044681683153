import { API_DOMAIN, AppRoutes } from '@common/constants';
import axios from 'axios';
import history from '@common/utils/history';
import { removeStorage } from './services';

const axiosInstance = axios.create({
  baseURL: API_DOMAIN,
  withCredentials: true
});

export default axiosInstance;
