import { ApiEndpoint } from '@api/constants';
import { apiDel, apiGet, apiPost, apiPut } from '@api/services/services';
import { ErrorNoti, SuccessNoti } from '@common/components/Notification/Notification';
import { buildFormData } from '@common/utils';
import { ExamQuestionType, QuestionType } from '@pages/exam-question/types';
import { PracticeType } from '@pages/practices/types';
import { isObject } from 'lodash';

export interface DeleteQuestionPayload {
  examId: string;
  questionId: string;
}

export enum QuestionThunk {
  GET_LIST_QUESTIONS_REQUEST = 'GET_LIST_QUESTIONS_REQUEST',
  GET_LIST_QUESTIONS_SUCCESS = 'GET_LIST_QUESTIONS_SUCCESS',
  ADD_QUESTIONS_REQUEST = 'ADD_QUESTIONS_REQUEST',
  ADD_QUESTIONS_SUCCESS = 'ADD_QUESTIONS_SUCCESS',
  DELETE_QUESTIONS_REQUEST = 'DELETE_QUESTIONS_REQUEST',
  DELETE_QUESTIONS_SUCCESS = 'DELETE_QUESTIONS_SUCCESS',
  UPDATE_QUESTIONS_REQUEST = 'UPDATE_QUESTIONS_REQUEST'
}

export const getQuestionsRequest = (params) => async (dispatch) => {
  try {
    dispatch({ type: QuestionThunk.GET_LIST_QUESTIONS_REQUEST });

    const { data } = await apiGet({
      path: ApiEndpoint.QUESTION,
      body: params
    });

    const mapData = data.data.map((item) => ({
      ...item,
      thematic: { ...item.thematic, label: item.thematic.name, value: item.thematic.id }
    }));
    dispatch(getQuestionsSuccess({ ...data, data: mapData }));
  } catch (error) {
    ErrorNoti(error?.response?.data?.message);
  }
};

export const getQuestionsSuccess = (payload: ExamQuestionType) => ({
  type: QuestionThunk.GET_LIST_QUESTIONS_SUCCESS,
  payload
});

export const updateQuestionRequest = (payload, callback) => async (dispatch) => {
  const { type, thematic, image, description, suggestion, id, answers, question_image, hint_image } = payload;
  const mapPayload = {
    type,
    thematic_id: isObject(thematic) ? thematic.id : thematic,
    description,
    suggestion,
    id,
    image,
    answers,
    question_image,
    hint_image,
  };
  try {
    dispatch({ type: QuestionThunk.UPDATE_QUESTIONS_REQUEST });

    const {
      data: { data }
    } = await apiPut({
      path: `${ApiEndpoint.QUESTION}/${payload.id}`,
      body: mapPayload
    });
    if (data) {
      SuccessNoti('Cập nhật bài thực hành');
      callback();
    }
  } catch (error) {
    callback();
    ErrorNoti(error?.response?.data?.message);
  }
};

export const addQuestionRequest = (payload, callback) => async (dispatch) => {
  const { type, thematic, image, description, suggestion, answers, questionImage, hintImage } = payload;

  const mapPayload = {
    type,
    thematic_id: thematic,
    description,
    suggestion,
    image,
    answers,
    questionImage,
    hintImage,
  };

  try {
    dispatch({ type: QuestionThunk.ADD_QUESTIONS_REQUEST });

    const {
      data: { data }
    } = await apiPost({
      path: ApiEndpoint.QUESTION,
      body: mapPayload
    });
    if (data) {
      SuccessNoti('Thêm Câu Hỏi');
      callback();
    }
  } catch (error) {
    callback();
    ErrorNoti(error?.response?.data?.message);
  }
};

export const deletePracticeRequest = (payload, callback) => async (dispatch) => {
  try {
    dispatch({ type: PracticeReducerActions.DELETE_PRACTICE_REQUEST });
    await apiDel({
      path: `${ApiEndpoint.PRACTICE}/${payload.id}`
    });

    SuccessNoti('Xóa bài thực hành');

    callback();
  } catch (error) {
    callback();
    ErrorNoti(error?.response?.data?.message);
  }
};

export const setPracticeSuccess = (payload: PracticeType) => ({
  type: PracticeReducerActions.ADD_PRACTICE_SUCCESS,
  payload
});

export const deleteQuestion = (payload: DeleteQuestionPayload) => ({
  type: PracticeReducerActions.DELETE_PRACTICE,
  payload
});

export const updateQuestion = (payload: QuestionType) => ({
  type: PracticeReducerActions.UPDATE_PRACTICE,
  payload
});
