import { ApiEndpoint } from '@api/constants';
import { apiGet } from '@api/services/services';
import Footer from '@common/components/Footer/Footer';
import Select from '@common/components/Select/Select';
import UIField from '@common/components/UIField/UIField';
import { AppRoutes } from '@common/constants';
import { PROMO_TYPE } from '@common/constants/constantType';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDeepCompareEffect } from '@layout/hooks';
import { addCommonRequest, updateCommonRequest } from '@reducers/actions/CommonOptionThunk';
import { Col, DatePicker, Form, Input, InputNumber, Row } from 'antd';
import { get } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import locale from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import { dateFormat, dateSecondFormat, formatDate } from '@common/utils';

const apiParamInfos = { entity: 'coupons', message: 'Mã Khuyến Mãi' };
const disabledStartDate = (current) => current && current < moment().endOf('day');

const schema = yup
  .object({
    name: yup.string().required('Bắt buộc nhập tiêu đề khuyến mãi'),
    code: yup.string().required('Bắt buộc nhập mã khuyến mãi'),
    type: yup.string().required('Bắt buộc chọn loại khuyến mãi'),
    start_date: yup.string().nullable().required('Bắt buộc chọn ngày bắt đầu hiệu lực'),
    end_date: yup.string().nullable().required('Bắt buộc chọn ngày kết thúc hiệu lực'),
    qty: yup.mixed().required('Bắt buộc chọn số lượng áp dụng'),
    discount_percent: yup
      .number()
      .max(100, 'Tỉ lệ chiết khấu tối đa là 100%')
      .nullable()
      .when(['type'], {
        is: (val: string) => val === 'COUPON',
        then: (schemaObj: any) => schemaObj.required('Bắt buộc tỷ lệ chiết khấu')
      }),
    max_discount_amount: yup
      .number()
      .nullable()
      .when(['type'], {
        is: (val: string) => val === 'COUPON',
        then: (schemaObj: any) => schemaObj.required('Bắt buộc nhập số tiền chiết khấu tối đa')
      }),
    min_total: yup
      .number()
      .nullable()
      .when(['type'], {
        is: (val: string) => val === 'VOUCHER',
        then: (schemaObj: any) => schemaObj.required('Bắt buộc nhập giá trị đơn hàng tối thiểu')
      }),
    discount_amount: yup
      .number()
      .nullable()
      .when(['type'], {
        is: (val: string) => val === 'VOUCHER',
        then: (schemaObj: any) => schemaObj.required('Bắt buộc nhập số tiền chiết khấu cụ thể')
      })
  })
  .required();

const CreateUpdateLoyaltyScreen = memo(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [item, setItem] = useState();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const formProps = useForm({
    resolver: yupResolver(schema)
  });

  const { watch, trigger, getValues, reset } = formProps;

  const [watchType, watchStartTime] = watch(['type', 'start_date']);

  const disabledEndDate = (current) => current && current < moment(watchStartTime).endOf('day');

  const handleCancel = () => navigate(AppRoutes.LOYALTY_LIST);

  const getItem = async () => {
    if (id) {
      const {
        data: { data }
      } = await apiGet({
        path: `${ApiEndpoint.LOYALTY}/${id}`
      });
      setItem(data);
    }
  };

  useEffect(() => {
    getItem();
  }, []);

  const onSubmit = async () => {
    const isValid = await trigger();

    if (isValid) {
      setDisableSubmit(true);
      const formValues = getValues();
      const payload = {
        ...formValues,
        ...apiParamInfos,
        start_date: formatDate(formValues.start_date, dateFormat),
        end_date: formatDate(formValues.end_date, dateFormat)
      };
      if (item) {
        updateCommonRequest({ ...payload, id }, null).then(() => handleCancel());
      } else {
        addCommonRequest(payload, null).then(() => handleCancel());
      }
    }
  };

  useDeepCompareEffect(() => {
    if (id) {
      reset({
        name: get(item, 'name'),
        code: get(item, 'code'),
        type: get(item, 'type'),
        start_date: moment(get(item, 'start_date'), dateFormat),
        end_date: moment(get(item, 'end_date'), dateFormat),
        qty: get(item, 'qty'),
        discount_percent: get(item, 'discount_percent'),
        max_discount_amount: get(item, 'max_discount_amount'),
        min_total: get(item, 'min_total'),
        discount_amount: get(item, 'discount_amount')
      });
    }
  }, [item]);

  return (
    <div style={{ backgroundColor: '#fff', padding: 16 }}>
      <Form layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={24} style={{ fontSize: 26, fontWeight: 'bold' }}>
            {id ? 'CHỈNH SỬA MÃ KHUYẾN MÃI' : 'THÊM MÃ KHUYẾN MÃI'}
          </Col>

          <Col span={8}>
            <UIField
              {...formProps}
              label="Tiêu đề khuyến mãi"
              name="name"
              placeholder="Nhập nội dung"
              fieldComponent={Input}
            />
          </Col>
          <Col span={8}>
            <UIField
              {...formProps}
              label="Mã khuyến mãi"
              name="code"
              placeholder="Nhập nội dung"
              fieldComponent={Input}
              disabled={!!id}
            />
          </Col>
          <Col span={8}>
            <UIField
              {...formProps}
              label="Loại khuyến mãi"
              name="type"
              options={PROMO_TYPE}
              placeholder="Chọn loại khuyến mãi"
              fieldComponent={Select}
              disabled={!!id}
            />
          </Col>
          <Col span={8}>
            <UIField
              {...formProps}
              label="Ngày bắt đầu hiệu lực"
              name="start_date"
              placeholder="dd/mm/yyyy hh:mm:ss"
              fieldComponent={DatePicker}
              fieldProps={{
                width: '100%',
                style: { width: '100%' },
                format: 'DD/MM/YYYY',
                disabledDate: disabledStartDate,
                locale
              }}
            />
          </Col>
          <Col span={8}>
            <UIField
              {...formProps}
              label="Ngày kết thúc hiệu lực"
              name="end_date"
              placeholder="dd/mm/yyyy hh:mm:ss"
              fieldComponent={DatePicker}
              fieldProps={{
                width: '100%',
                style: { width: '100%' },
                format: 'DD/MM/YYYY',
                disabledDate: disabledEndDate,
                disabled: !watchStartTime,
                locale
              }}
            />
          </Col>

          <Col span={8}>
            <UIField
              {...formProps}
              label="Số lượng áp dụng"
              name="qty"
              placeholder="Nhập nội dung"
              fieldComponent={InputNumber}
              fieldProps={{ min: 1, style: { width: '100%' } }}
            />
          </Col>

          {watchType &&
            (watchType === 'COUPON' ? (
              <>
                <Col span={8}>
                  <UIField
                    {...formProps}
                    label="Tỷ lệ chiết khấu"
                    name="discount_percent"
                    placeholder="Nhập nội dung"
                    fieldComponent={InputNumber}
                    fieldProps={{ min: 1, style: { width: '100%' }, addonAfter: '%' }}
                  />
                </Col>
                <Col span={8}>
                  <UIField
                    {...formProps}
                    label="Số tiền chiết khấu tối đa"
                    name="max_discount_amount"
                    placeholder="Nhập nội dung"
                    fieldComponent={InputNumber}
                    fieldProps={{
                      min: 1,
                      style: { width: '100%' },
                      formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }}
                  />
                </Col>
              </>
            ) : (
              <>
                <Col span={8}>
                  <UIField
                    {...formProps}
                    label="Giá trị đơn hàng tối thiểu"
                    name="min_total"
                    placeholder="Nhập nội dung"
                    fieldComponent={InputNumber}
                    fieldProps={{ min: 1, style: { width: '100%' } }}
                  />
                </Col>
                <Col span={8}>
                  <UIField
                    {...formProps}
                    label="Số tiền chiết khấu cụ thể"
                    name="discount_amount"
                    placeholder="Nhập nội dung"
                    fieldComponent={InputNumber}
                    fieldProps={{ min: 1, style: { width: '100%' } }}
                  />
                </Col>
              </>
            ))}
        </Row>
      </Form>
      <Footer onCancel={handleCancel} onSubmit={onSubmit} disableSubmit={disableSubmit} />
    </div>
  );
});

CreateUpdateLoyaltyScreen.propTypes = {};

export default CreateUpdateLoyaltyScreen;
