import { ApiEndpoint } from '@api/constants';
import { ApiLoginRequest, TAuthenticateResponse, TUserResponse } from '@api/interfaces';
import { API_DOMAIN } from '@common/constants';
import { AxiosResponse } from 'axios';
import axiosInstance from './axios';

class AuthenticateApi {
  public static hasCsrf = false;

  public static csrf() {
    return axiosInstance
      .get(`${API_DOMAIN}/${ApiEndpoint.CSRF_TOKEN}`)
      .then(() => (this.hasCsrf = true));
  }

  public static signIn(request: ApiLoginRequest): Promise<AxiosResponse<TAuthenticateResponse>> {
    return axiosInstance
      .post<TAuthenticateResponse>(`${API_DOMAIN}/${ApiEndpoint.LOGIN}`, request, {
        maxRedirects: 0
      })
      .catch((error) => error.handleGlobally && error.handleGlobally());
  }

  public static me(): Promise<AxiosResponse<TUserResponse>> {
    if (this.hasCsrf === false) {
      this.csrf();
    }

    return axiosInstance
      .get(`${API_DOMAIN}/${ApiEndpoint.USER_ME}`, {
        maxRedirects: 0
      })
      .catch((error) => error.handleGlobally && error.handleGlobally());
  }

  public static signOut() {
    if (this.hasCsrf === false) {
      this.csrf();
    }

    return axiosInstance
      .post(`${API_DOMAIN}/${ApiEndpoint.LOGOUT}`, {})
      .catch((error) => error.handleGlobally && error.handleGlobally());
  }

  public static healthCheck() {
    if (this.hasCsrf === false) {
      this.csrf();
    }

    return axiosInstance
      .head(`${API_DOMAIN}/${ApiEndpoint.USER_ME}`, {
        maxRedirects: 0,
        responseType: 'document'
      })
      .catch((error) => error.handleGlobally && error.handleGlobally());
  }
}

export default AuthenticateApi;
