import { removeStorage } from '@api/services/services';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '..';

const useAxiosInterceptor = (axios) => {
  const navigate = useNavigate();
  const [error, setError] = useState({});

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (req) => req,
      (err) => Promise.reject(err)
    );

    const responseInterceptor = axios.interceptors.response.use(
      (res) => res,
      (error) => {
        const { status } = error?.response;

        switch (status) {
          case 404:
            navigate(AppRoutes.ERROR_404);
            break;
          case 401:
            removeStorage('accessToken');
            removeStorage('user');
            navigate(AppRoutes.LOGIN);
            break;
          default:
            break;
        }

        return Promise.reject(error);
      }
    );
    return () => {
      // Remove handlers, so Garbage Collector will get rid of if WrappedComponent will be removed
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [axios]);
  return { error, onSetError: setError };
};

export default useAxiosInterceptor;
