import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import Modal from '../Modal/Modal';

const ImageWithPreview = memo(({ src, title, alt, width = 300 }) => {
  const [previewOpen, setPreviewOpen] = useState(false);

  return (
    <div>
      <img
        alt={alt}
        style={{ width, cursor: 'pointer' }}
        src={src}
        onClick={() => setPreviewOpen(true)}
      />
      <Modal
        open={previewOpen}
        title={title}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
        width={1080}
      >
        <img alt={alt} style={{ width: 999 }} src={src} />
      </Modal>
    </div>
  );
});

ImageWithPreview.propTypes = {};

export default ImageWithPreview;
