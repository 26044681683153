import { ApiEndpoint } from '@api/constants';
import { apiGet } from '@api/services/services';
import { ErrorNoti } from '@common/components/Notification/Notification';
import { createSelector } from 'reselect';

export enum LearningGoalConstant {
  GET_LIST_LEARNING_GOAL_REQUEST = 'GET_LIST_LEARNING_GOAL_REQUEST',
  GET_LIST_LEARNING_GOAL_SUCCESS = 'GET_LIST_LEARNING_GOAL_SUCCESS'
}

export const getLearningGoalRequest = (params) => async (dispatch) => {
  const { id, ...restParams } = params;
  try {
    dispatch({ type: LearningGoalConstant.GET_LIST_LEARNING_GOAL_REQUEST });

    const { data } = await apiGet({
      path: ApiEndpoint.LEARNING_GOAL.replace(':id', id),
      body: restParams
    });

    dispatch(getLearningGoalSuccess(data));
  } catch (error) {
    ErrorNoti(error.response.data.message);
  }
};

export const getLearningGoalSuccess = (payload) => ({
  type: LearningGoalConstant.GET_LIST_LEARNING_GOAL_SUCCESS,
  payload
});

export const selectLearningGoalPage = (state: any) => state.learningGoal;

export const selectLearningGoalList = () =>
  createSelector(selectLearningGoalPage, (subState) => subState);
