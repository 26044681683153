import { ApiEndpoint } from '@api/constants';
import { apiGet } from '@api/services/services';
import { ErrorNoti } from '@common/components/Notification/Notification';

export enum LoyaltyConstant {
  GET_LIST_LOYALTY_REQUEST = 'GET_LIST_LOYALTY_REQUEST',
  GET_LIST_LOYALTY_SUCCESS = 'GET_LIST_LOYALTY_SUCCESS'
}

export const getLoyaltyRequest = (params) => async (dispatch) => {
  try {
    dispatch({ type: LoyaltyConstant.GET_LIST_LOYALTY_REQUEST });

    const { data } = await apiGet({
      path: ApiEndpoint.LOYALTY,
      body: params
    });
    dispatch(getLoyaltySuccess(data));
  } catch (error) {
    ErrorNoti(error?.response?.data?.message);
  }
};

export const getLoyaltySuccess = (payload) => ({
  type: LoyaltyConstant.GET_LIST_LOYALTY_SUCCESS,
  payload
});
