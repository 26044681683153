import { ApiEndpoint } from '@api/constants';
import { apiGet } from '@api/services/services';
import { ErrorNoti } from '@common/components/Notification/Notification';

export enum StudentConstant {
  ADD_STUDENT_REQUEST = 'ADD_STUDENT_REQUEST',
  ADD_STUDENT_SUCCESS = 'ADD_STUDENT_SUCCESS',
  GET_LIST_STUDENT_REQUEST = 'GET_LIST_STUDENT_REQUEST',
  GET_LIST_STUDENT_SUCCESS = 'GET_LIST_STUDENT_SUCCESS'
}

export const getStudentRequest = (params) => async (dispatch) => {
  try {
    dispatch({ type: StudentConstant.GET_LIST_STUDENT_REQUEST });

    const { data } = await apiGet({
      path: ApiEndpoint.STUDENT,
      body: params
    });
    dispatch(getStudentSuccess(data));
  } catch (error) {
    ErrorNoti(error?.response?.data?.message);
  }
};

export const getStudentSuccess = (payload) => ({
  type: StudentConstant.GET_LIST_STUDENT_SUCCESS,
  payload
});
